.link-navigation {
	&__inner {
		//background-color: $color-light-blue;
	}
	&__content {
		padding: $spacing-default $spacing-default * 0.75;
		min-height: 300px;
		@include bp(lg) {
			padding: $spacing-default * 3;
		}
	}
	&__tag {
		font-weight: $font-weight-bold;
		display: block;
		margin-bottom: $spacing-small;
		text-transform: uppercase;
	}

	&__img {
		height: $header-height * 2 !important;
		picture {
			height: $header-height * 2 !important;
		}
		@include bp(sm) {
			height: 100% !important;

			picture {
				height: 100% !important;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
		}
	}

	&__links--content {
		@include bp(sm) {
			display: flex;
			align-items: center;
			margin: $spacing-medium 0;
		}
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__dropdown {
		position: relative;
		display: inline-block;
		margin-bottom: $spacing-default;
		@include bp(lg) {
			margin-bottom: 0;
		}

		&-trigger {
			cursor: pointer;
			padding: $spacing-default * 0.75 $spacing-default * 1.25;
			border-radius: $border-radius-primary * 7;
			font-weight: $font-weight-bold;
			color: $color-black;
			min-width: $spacing-large * 5;
			position: relative;
			margin-top: $spacing-default;
			min-height: $spacing-default * 2.5;
			margin-bottom: 0;
			background-color: $color-white;
			box-shadow: $box-shadow-base;
			font-family: $font-family-default;
			box-sizing: border-box;
			text-align: left;

			@include bp(lg) {
				padding: $spacing-default $spacing-default * 1.5;
				padding-right: $spacing-default * 2.5;
				min-width: $spacing-large * 6;
				margin-top: 0;
				min-height: $spacing-default * 3;
			}

			&::after {
				width: $icon-space;
				height: $icon-space;
				border-style: solid;
				border-width: 1px 1px 0 0;
				content: "";
				display: inline-block;
				transform: rotate(135deg);
				vertical-align: top;
				color: $color-black;
				margin-left: $spacing-small;
				margin-top: $spacing-small/2;
				position: absolute;
				right: $spacing-default * 1.25;
			}

			&.active {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 0;

				&::after {
					transform: rotate(315deg);
				}
			}
		}

		&-list {
			position: absolute;
			background-color: $color-white;
			top: 100%;
			left: 0;
			right: 0;
			z-index: 99;
			padding: $spacing-small 0 $spacing-small * 2;
			margin: 0;
			color: $color-black;
			font-family: $font-family-default;
			border-bottom-left-radius: $border-radius-primary * 7;
			border-bottom-right-radius: $border-radius-primary * 7;
			box-shadow: $box-shadow-base;
			list-style-type: none;
			display: none;

			&.active {
				display: block;
			}

			a {
				cursor: pointer;
				display: block;
				padding: $spacing-small $spacing-default * 1.5;

				&:hover,
				&:focus-visible {
					background-color: $color-gray-light;
				}
			}
		}
	}
}
