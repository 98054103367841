﻿.no-border {
	border: none !important;
}

.border {
	&-none {
		border: none !important;
	}
}

.round {
	border-radius: 50%;
}
