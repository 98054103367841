.page-hero {
	&__bg {
		background-color: $color-white;
		@include left-background-overflow();

		.favorite-toggle-module.hide--sm {
			position: absolute;
			top: 28px;
			right: 28px;
		}
	}

	&__col {
		@include bp(lg) {
			@include col-with-parent-size(8, 10);
		}
	}

	&__text {
		padding: $spacing-medium;

		@include bp(lg) {
			padding: $spacing-medium $grid-gutter-width / 2 $spacing-small * 5;
		}
	}

	&__info {
		.info-box {
			margin-left: -$grid-gutter-width / 2;
			margin-right: -$grid-gutter-width / 2;

			@include bp(lg) {
				margin-left: -$grid-gutter-width - $spacing-default;
				margin-right: -$grid-gutter-width - $spacing-default;
			}
		}
	}

	&--infobox {
		@include bp(lg) {
			.page-hero__bg {
				margin-bottom: $spacing-medium * 2;
			}
			.info-box {
				margin-bottom: -$spacing-medium * 2;
			}
		}
	}
}
