﻿$block: "central-points";

.#{$block} {
	padding-left: 0;
	padding-right: 0;

	&__inner {
		//background-color: $color-gray-light;
		@include bp(lg) {
			padding: $component-padding-lg;
		}
		@include bp(xl) {
			padding: $component-padding-xl;
		}
		@include bp(xxl) {
			padding: $component-padding-xxl;
		}
	}

	&__columns {
		justify-content: space-between;
		width: 100%;
	}

	&__headline {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		padding: $spacing-medium;
		padding-top: $spacing-default * 1.25;
		padding-bottom: 0;
		@include bp(lg) {
			padding: 0;
			padding-bottom: $spacing-default * 2.5;
		}
		> h2 {
			margin-bottom: 0;
		}
	}

	&__items {
		padding: $spacing-default * 1.5;
		padding-bottom: $spacing-default;
		flex-wrap: wrap;
		overflow-x: unset;
		margin: 0;

		@include bp(lg) {
			padding: 0;
			justify-content: space-between;
		}
	}
	&__item {
		padding: 0;
		margin: 0;
		margin-bottom: $spacing-default * 1.7;

		&--fake {
			// ugly hack to make proper column flow (left aligned) work with the space-between rule down below
			opacity: 0;
			pointer-events: none;
			height: 0;
			min-height: 0;
			margin: 0 !important;
			border: 0 !important;
			padding: 0;
		}

		@include bp(lg) {
			max-width: 31%; //override bootstrap col width
			margin-bottom: $spacing-default/2;

			&:nth-child(n + 4) {
				margin-top: $spacing-default * 1.7;
			}
		}
	}
	&__item-headline {
		font-weight: $font-weight-bold;
		line-height: $line-height-default;
		font-size: 1.33rem;
	}

	&__item-inner {
		width: auto;
	}

	&__item-content {
		display: block;
		width: 100%;
		position: relative;
	}

	&__item-link {
		position: absolute;
		inset: 0;

		&:hover,
		&:focus {
			~ .central-points__item-headline {
				text-decoration: underline;
			}
			~ .central-points__item-media--image {
				img {
					transform: scale(1.03);
				}
			}
		}
	}

	&__item-media {
		margin-bottom: $spacing-default;
		height: auto;
		box-shadow: $box-shadow-base;

		&--image {
			pointer-events: none;
		}

		.umbraco-image-module {
			overflow: hidden;
		}

		img {
			transition: transform 250ms $animation-easing-default;
		}
	}

	&__item-teaser {
		color: inherit;
		display: block;
		height: auto;
	}
}
