﻿.font-size {
	&-small {
		font-size: $font-size-xs;
	}

	&-larger {
		font-size: $font-size-h3;
	}

	&-regular {
		font-size: 1em !important;
	}
}

.font-weight {
	&-regular {
		font-weight: 400 !important;
	}
	&-medium {
		font-weight: 500 !important;
	}
	&-bold {
		font-weight: 700 !important;
	}
}
