$block: "richtext";

@mixin richtext-theme($bg-color, $color) {
	.#{$block} {
		&__background {
			background-color: $bg-color;
			color: $color;
			@include content-space-padding();

			&:before {
				background-color: $bg-color;
			}
		}
	}
}
@mixin richtext-infobox-theme($bg-color, $color) {
	background-color: $bg-color;
	color: $color;
}

.#{$block} {
	&__background {
		@include left-background-overflow();
	}

	&__col {
		@include bp(lg) {
			@include col-with-parent-size(8, 10);
		}
	}

	&--themeBlueLight {
		@include richtext-theme($color-light-blue, $color-black);
	}
	&--themeBlueDark {
		@include richtext-theme($color-dark-blue, $color-white);
	}
	&--themeGrayLight {
		@include richtext-theme($color-gray-light, $color-black);
	}
	&--themeWhite {
		@include richtext-theme($color-white, $color-black);
	}
	&--themeYellow {
		@include richtext-theme($color-yellow, $color-black);
	}
	&--themeOrange {
		@include richtext-theme($color-orange, $color-black);
	}
	&--themeBlue {
		@include richtext-theme($color-blue, $color-white);
	}
	&--themePink {
		@include richtext-theme($color-pink, $color-black);
	}
	&--themeRed {
		@include richtext-theme($color-red, $color-black);
	}
	&--themeTurquoise {
		@include richtext-theme($color-turquoise, $color-black);
	}
	&--themeTurquoiseLight {
		@include richtext-theme($color-turquoise-light, $color-black);
	}

	p,
	ul,
	ol {
		margin-top: 0;
		margin-bottom: $spacing-small * 2;
		line-height: $line-height-paragraph;
	}

	a {
		text-decoration: underline;

		&:hover,
		&:focus {
			text-decoration: underline;
			font-weight: bold;
		}
	}

	.manchet {
		line-height: $line-height-paragraph;
	}

	h3 {
		font-size: 1.4rem;

		@include bp(md) {
			font-size: 1.67rem;
		}
	}
	h2 {
		@include bp-max(md) {
			font-size: 1.7rem;
		}

		margin-bottom: $spacing-small;
	}
	h3,
	h4 {
		margin-bottom: $spacing-small - 2px;
	}
	p + h2,
	p + h3,
	p + h4 {
		margin-top: $spacing-medium;
	}

	//for CTA style
	.btn-primary-arrow {
		display: inline-block;
		border-radius: 2em;
		padding: 1.17em 1.29em 1.16em 1.5em;
		padding-right: calc(4rem + 1.29em);
		font-weight: $font-weight-bold;
		font-size: $font-size-h5;
		box-shadow: $box-shadow-btn;
		text-decoration: none;
		cursor: pointer;
		border: none;
		line-height: $line-height-default;
		vertical-align: middle;
		min-width: 100px;
		background-color: $color-black;
		color: $color-white;
		border-color: $color-black;
		position: relative;

		&:active {
			transform: translateY(4px);
			transition: transform $animation-duration-default
				$animation-easing-default;
			box-shadow: $box-shadow-active;
		}

		&:before {
			position: absolute;
			top: 50%;
			right: 0.5rem;
			transform: translate3d(0, -50%, 0);
			font-family: $font-family-default;
			content: "→";
			font-weight: $font-weight-regular;
			padding-left: 1rem;
			padding-right: 1rem;
			transition: right $animation-duration-default * 3
				$animation-easing-default;
			font-size: $font-size-h3;
		}

		&:after {
			content: "";
		}

		&:focus-visible {
			@include focus(-2px);
		}

		&:hover,
		&:focus {
			color: $color-white;
			text-decoration: none;
			&:before {
				right: 0;
			}
		}

		//if link has been inserted before CTA format
		a {
			text-decoration: none;

			&:hover,
			&:focus {
				color: inherit;
			}
		}
	}

	//RTE format
	blockquote {
		margin: 0 0 $spacing-default * 2;
		padding: $spacing-default/2 $spacing-default;
		font-size: $font-size-h4--small;
		font-style: italic;
		font-weight: lighter;
		// border-left: 5px solid $color-dark-blue; //Adds this border if needed
		padding: $spacing-default * 1.5 $spacing-default * 3;
		padding-right: $spacing-default;
		min-height: 100px;
		line-height: $line-height-paragraph;
		border-left: none;
		position: relative;

		&:before,
		&:after {
			content: "“";
			position: absolute;
			height: 100%;
			left: 0;
			margin-top: -($spacing-small * 3.5);
			margin-right: $spacing-default;
			margin-bottom: $spacing-default;
			display: inline-block;
			font-size: 40px;
		}
		&:after {
			margin-top: $spacing-small * 5.5;
		}

		@include bp(lg) {
			padding: $spacing-default * 1.5 $spacing-default * 5;
			font-size: $font-size-h2--small;

			&:before,
			&:after {
				left: $spacing-default;
			}
		}
	}

	//RTE format
	.header-medium {
		font-size: $font-size-h2--small;
		line-height: $line-height-default;
		@include bp(lg) {
			font-size: $font-size-h2;
		}
	}

	.richtext__title {
		line-height: 1;
	}

	.rte {
		&.rte--fullwidth {
			> *:not(.macro__cta):not(.section):not(.cta--small) {
				max-width: none;
			}
		}

		.section {
			.section__intro,
			.section__inner {
				padding-left: 0;
				padding-right: 0;
			}

			padding-bottom: $spacing-medium/2 !important;
		}
	}

	> *:last-child,
	.richtext__content > *:last-child {
		margin-bottom: 0;
	}

	.richtext__content {
		padding-left: $spacing-medium/2;
		padding-right: $spacing-medium/2;
		@include bp(lg) {
			padding-left: 0;
			padding-right: 0;
		}

		&--infobox {
			padding-top: $spacing-default;
			padding-bottom: $spacing-default;

			margin-left: -$grid-gutter-width / 2;
			margin-right: -$grid-gutter-width / 2;
			padding-left: $grid-gutter-width;
			padding-right: $grid-gutter-width;

			@include bp(lg) {
				margin-left: -$grid-gutter-width - $spacing-default;
				margin-right: -$grid-gutter-width - $spacing-default;
				padding-left: $grid-gutter-width + $spacing-default;
				padding-right: $grid-gutter-width + $spacing-default;
			}

			&--themeBlueLight {
				@include richtext-infobox-theme(
					$color-light-blue,
					$color-black
				);
			}
			&--themeBlueDark {
				@include richtext-infobox-theme($color-dark-blue, $color-white);
			}
			&--themeGrayLight {
				@include richtext-infobox-theme(
					$color-gray-light,
					$color-black
				);
			}
			&--themeWhite {
				@include richtext-infobox-theme($color-white, $color-black);
			}
			&--themeYellow {
				@include richtext-infobox-theme($color-yellow, $color-black);
			}
			&--themeOrange {
				@include richtext-infobox-theme($color-orange, $color-black);
			}
			&--themeBlue {
				@include richtext-infobox-theme($color-blue, $color-white);
			}
			&--themePink {
				@include richtext-infobox-theme($color-pink, $color-black);
			}
			&--themeRed {
				@include richtext-infobox-theme($color-red, $color-black);
			}
			&--themeTurquoise {
				@include richtext-infobox-theme($color-turquoise, $color-black);
			}
			&--themeTurquoiseLight {
				@include richtext-infobox-theme(
					$color-turquoise-light,
					$color-black
				);
			}
		}
	}
}
