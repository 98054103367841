﻿$block: "table-of-content";

@mixin table-of-content-theme($bg-color, $color, $scrollbar-bg) {
	color: $color !important;

	.#{$block} {
		color: $color;

		&__heading {
			color: $color;
		}

		&__manchet {
			color: $color;
		}

		&__button {
			&:before {
				color: $color;
			}
		}

		&__swiper-scrollbar {
			background-color: $scrollbar-bg;
		}
	}

	.swiper-scrollbar-drag {
		background-color: $bg-color;
	}

	.#{$block}__cell {
		background-color: $bg-color !important;

		&--empty {
			background-color: transparent !important;
		}
	}
}

.#{$block} {
	@include table-of-content-theme(
		$color-background,
		$color-black,
		$color-scrollbar-track-dark
	);

	&__container {
		overflow: hidden;

		@include bp(md) {
			overflow: visible;
		}
	}

	&__inner {
		width: 800px;

		@include bp(md) {
			width: auto;
		}
	}

	&__swiper-scrollbar {
		height: 10px;
		border-radius: 50px;

		@include bp(md) {
			display: none;
		}
	}

	&__row {
		margin-bottom: $grid-gutter-width * 0.5;
		padding-right: $grid-gutter-width * 0.5;
		&:last-child {
			margin-bottom: $grid-gutter-width;
		}
		@include bp(md) {
			margin-bottom: $grid-gutter-width;
			padding-right: 0;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__column {
		padding-right: 0;

		@include bp(md) {
			padding-right: $grid-gutter-width * 0.5;
		}
	}

	&__cell {
		background-color: $color-white;
		height: 100%;
		position: relative;
		display: flex;

		&--empty {
			background-color: transparent;

			&:before {
				content: none;
			}
		}
	}

	&__button {
		display: flex;
		flex-direction: column;
		text-align: left;
		padding: $spacing-small * 3 $spacing-small * 5;
		width: 100%;

		&:before {
			position: absolute;
			top: $spacing-small * 3.2;
			left: $spacing-small * 1.7;
			font-family: $font-family-default;
			content: "→";
			font-weight: $font-weight-regular;
			transition: left $animation-duration-default * 3
				$animation-easing-default;
			font-size: $font-size-h3;
		}

		&:hover,
		&:focus,
		&:active {
			&:before {
				left: $spacing-small * 2.4;
			}
		}

		&:focus-visible {
			@include focus;
		}
	}

	&__heading {
		margin-top: 0;
		margin-bottom: $spacing-small * 0.5;
		font-size: $font-size-small;
	}

	&__manchet {
		margin: 0;
	}

	&__html {
		margin-top: $spacing-small * 2;

		> p {
			margin-top: 0;
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&--themeBlueLight {
		@include table-of-content-theme(
			$color-light-blue,
			$color-black,
			$color-scrollbar-track-light
		);
	}
	&--themeBlueDark {
		@include table-of-content-theme(
			$color-dark-blue,
			$color-white,
			$color-scrollbar-track-dark
		);
	}
	&--themeGrayLight {
		@include table-of-content-theme(
			$color-gray-light,
			$color-black,
			$color-scrollbar-track-dark
		);
	}
	&--themeWhite {
		@include table-of-content-theme(
			$color-white,
			$color-black,
			$color-scrollbar-track-dark
		);
	}
	&--themeYellow {
		@include table-of-content-theme(
			$color-yellow,
			$color-black,
			$color-scrollbar-track-light
		);
	}
	&--themeOrange {
		@include table-of-content-theme(
			$color-orange,
			$color-black,
			$color-scrollbar-track-light
		);
	}
	&--themeBlue {
		@include table-of-content-theme(
			$color-blue,
			$color-white,
			$color-scrollbar-track-light
		);
	}
	&--themePink {
		@include table-of-content-theme(
			$color-pink,
			$color-black,
			$color-scrollbar-track-light
		);
	}
	&--themeRed {
		@include table-of-content-theme(
			$color-red,
			$color-black,
			$color-scrollbar-track-light
		);
	}
	&--themeTurquoise {
		@include table-of-content-theme(
			$color-turquoise,
			$color-black,
			$color-scrollbar-track-light
		);
	}
	&--themeTurquoiseLight {
		@include table-of-content-theme(
			$color-turquoise-light,
			$color-black,
			$color-scrollbar-track-light
		);
	}
	&--themeDefault {
		@include table-of-content-theme(
			$color-background,
			$color-black,
			$color-scrollbar-track-dark
		);
	}
}
