.author {
	$portrait-size: 115px;
	$portrait-size-large: 150px;
	background-color: $body-background;

	&__inner {
		display: flex;
		align-items: flex-start;
		@include bp(lg) {
			flex-direction: row;
			align-items: center;
		}
	}
	&__img {
		display: flex;
		align-items: center;
		margin-right: $spacing-small * 1.5;
		width: $portrait-size;
		height: $portrait-size;
		min-width: $portrait-size;

		@include bp(lg) {
			display: block;
			margin-right: $spacing-small * 3;
			width: $portrait-size-large;
			min-width: $portrait-size-large;
			height: $portrait-size-large;
		}

		img {
			border-radius: 50%;
			border: $border-medium solid $color-white;
			@include bp(lg) {
				border-width: $border-large;
			}
		}
	}

	&__name-wrap {
		margin-bottom: $spacing-small * 2;
	}

	&__name {
		font-weight: $font-weight-bold;
		display: block;
		@include bp(lg) {
			display: inline-block;
		}
	}

	&__rte {
		margin: 0;
	}
}
