.hero-search-module {
	.search-field {
		padding: 0;
	}

	.search-input {
		svg {
			color: $color-black;
		}
	}
}
