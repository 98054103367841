﻿$block: "tag";

@mixin tag-theme($color, $font-color) {
	background-color: $color;
	color: $font-color;
}

.#{$block} {
	display: inline-block;
	font-size: $font-size-xs;
	text-transform: uppercase;
	background-color: $color-light-blue;
	color: $color-black;
	padding: 0.5em;
	cursor: pointer;
	line-height: calc(0.25rem + 1em);

	&--themeBlack {
		@include tag-theme($color-black, $color-white);
	}
	&--themeBlueLight {
		@include tag-theme($color-light-blue, $color-black);
	}
	&--themeBlueDark {
		@include tag-theme($color-dark-blue, $color-white);
	}
	&--themeGrayLight {
		@include tag-theme($color-gray-light, $color-black);
	}
	&--themeWhite {
		@include tag-theme($color-white, $color-black);
	}
	&--themeYellow {
		@include tag-theme($color-yellow, $color-black);
	}
	&--themeOrange {
		@include tag-theme($color-orange, $color-black);
	}
	&--themeBlue {
		@include tag-theme($color-blue, $color-white);
	}
	&--themePink {
		@include tag-theme($color-pink, $color-black);
	}
	&--themeRed {
		@include tag-theme($color-red, $color-black);
	}
	&--themeTurquoise {
		@include tag-theme($color-turquoise, $color-black);
	}
	&--themeTurquoiseLight {
		@include tag-theme($color-turquoise-light, $color-black);
	}
	&--themeDefault {
		@include tag-theme($color-background, $color-black);
	}
}
