.image-component {
	&__inner {
		position: relative;
		width: 100%;
		height: $height-large/2.8;
		@include bp(lg) {
			height: $height-large/1.4;
		}
		@include bp(xl) {
			height: $height-large;
		}
	}

	img {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-position: center center;
		background-size: cover;
	}
}
