﻿$block: "menu";

.#{$block} {
	padding: $spacing-small * 3 $spacing-small * 3 0;
	&--dropdown {
		padding: 0;
		margin: 0 (-$spacing-small * 1.5);
		@include bp(lg) {
			margin: 0;
		}
	}
	&--nav {
		display: none;
		@include bp(lg) {
			display: block;
		}
	}

	&--hide-desktop-view {
		@include bp(lg) {
			display: none;
		}
	}

	&--sticky {
		position: sticky;
		top: 0;
		z-index: 99;
	}

	&__collection {
		display: flex;
		padding: 0;
		margin-top: 0;
		margin-bottom: 0;
		margin-left: -$spacing-small * 1.5;
		margin-right: -$spacing-small * 1.5;
		list-style: none;

		&--dropdown {
			flex-direction: column;
			background: $color-gray-light;
			padding: $spacing-small * 2 $spacing-small * 2.5 $spacing-small * 4;
			margin: 0;
			@include bp(lg) {
				padding: $spacing-small * 2 $spacing-small * 4.5;
			}
			&.hidden {
				display: none !important;
			}
		}
	}

	&--scrollable {
		padding: 0;
		.#{$block}__collection {
			padding: $spacing-small * 3 $spacing-small * 3 0;
			margin: 0;
		}
		.#{$block}__item {
			white-space: pre;
		}
	}

	&__item {
		padding: 0 $spacing-small * 1.5;
		flex-shrink: 0;
		width: auto;
	}

	&__link {
		display: inline-block;
		position: relative;
		font-size: 16px;
		padding-bottom: $spacing-small * 3;

		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 4px;
			opacity: 0;
		}

		&:hover,
		&:focus {
			&:before {
				opacity: 1;
				transition: opacity 250ms ease;
			}
		}

		&--bold {
			color: $color-menu-highlight;

			&:before {
				background-color: $color-menu-highlight;
			}

			&:hover,
			&:focus {
				color: $color-menu-highlight;
			}
		}

		&--active {
			font-weight: 700;

			&:before {
				opacity: 1;
			}
		}
	}

	.icon {
		height: auto !important;
	}

	//override swiper navigation style
	&__swiper-button-prev,
	&__swiper-button-next {
		background: $color-white;
		color: $color-black !important;
		border-radius: 50%;
		width: 34px;
		min-width: 34px;
		height: 34px;
		position: absolute;
		transition: all 0.3s ease;
		top: 50%;
		transform: translateY(-50%);
		margin-top: 0;
		box-shadow: 0 0 9px 6px $color-white, inset 0 0 0 1px $color-black;

		&::after {
			font-size: 11px !important;
			line-height: 0 !important;
			font-weight: bold;
		}

		&.swiper-button-disabled {
			opacity: 0;
		}

		&:hover,
		&:focus-visible {
			background: $color-black;
			color: $color-white !important;
		}

		&::before {
			content: "";
			position: absolute;
			width: 50px;
			top: 0;
			height: 100%;
			z-index: 0;
		}
	}

	&__swiper-button-prev {
		left: 45px; // 45px = to align with current grid

		&::before {
			left: 100%;
			background: linear-gradient(
				to left,
				rgba($color-white, 0),
				rgba($color-white, 0.4),
				rgba($color-white, 0.6),
				rgba($color-white, 1) 100%
			);
		}
	}

	&__swiper-button-next {
		right: 45px; // 45px = to align with current grid

		&::before {
			right: 100%;
			background: linear-gradient(
				to left,
				rgba($color-white, 1),
				rgba($color-white, 0.6),
				rgba($color-white, 0.4),
				rgba($color-white, 0) 100%
			);
		}
	}

	//themes style START
	&.themeWhite {
		.menu__link {
			&:before {
				background-color: $color-black;
			}

			&--bold:before {
				background-color: $color-menu-highlight;
			}
		}
		button svg {
			fill: $color-black;
		}
	}
	//themes style END

	//dropdown START
	&__dropdown-title {
		cursor: pointer;
		font-weight: bold;
		display: flex;
		padding: $spacing-small * 2.5;
		align-items: center;

		@include bp(lg) {
			padding: $spacing-small * 4 $spacing-small * 4;
		}
	}
	&__dropdown-close,
	&__dropdown-burger {
		width: $spacing-small * 2.5;
	}
	&__dropdown-burger {
		svg {
			width: $spacing-small * 2;
			height: $spacing-small * 2;
		}
	}
	&__dropdown-arrow {
		transform: rotate(90deg);
		margin-left: $spacing-small * 2;
		svg {
			width: $spacing-small * 1.5;
			height: $spacing-small * 1.5;
		}
	}
	&__dropdown-text {
		display: inline-block;
		margin-left: $spacing-small;
	}
	&__dropdown-item {
		padding: $spacing-default * 0.75 0;
		color: $color-black;
	}
	&__dropdown-link {
		display: flex;

		span {
			display: inline-block;
			width: auto;
			margin-right: $spacing-small/2;

			svg {
				width: $spacing-small;
				height: $spacing-small;
				fill: $color-black;
			}
		}
		.icon {
			margin-top: -1px;

			.safari & {
				line-height: 0;
				margin-top: 2px;
			}
		}
		&:hover {
			color: $color-primary;
			.icon {
				svg {
					fill: $color-primary;
				}
				@include animateRight();
			}
		}
		&--active {
			font-weight: $font-weight-bold;
			color: $color-dark-blue;

			&:hover,
			&:focus {
				color: $color-dark-blue;
			}
			.icon {
				svg {
					fill: $color-dark-blue;
				}
			}
		}

		&.menu__link--bold {
			&.menu__dropdown-link--active {
				color: $color-menu-highlight;
			}

			span {
				svg {
					fill: $color-menu-highlight;
				}
			}
		}
	}
}

#at-menu {
	// make scrolling to the anchor be slightly offset
	height: $spacing-medium;
	margin-top: -$spacing-medium;

	@include bp-max(lg) {
		height: 80px;
		margin-top: -80px;
	}
}

.sticky-top--menu {
	background-color: $color-white;
	@include bp(lg) {
		background-color: transparent;
	}
}
