﻿.text--right {
	text-align: right;
}
.text--left {
	text-align: left;
}
.text--center {
	text-align: center;
}

.no-word-break,
.no-word-break p {
	-ms-word-break: normal !important;
	word-break: normal !important;
}

.text {
	&-large {
		font-size: $font-size-h4;
	}

	@include bp(md) {
		&-md {
			&-large {
				font-size: $font-size-h4 !important;
			}
		}
	}

	@include bp(lg) {
		&-lg {
			&-large {
				font-size: $font-size-h4 !important;
			}
		}
	}
}

// change text alignment/styles
.text {
	&-center {
		text-align: center;
	}
	&-left {
		text-align: left;
	}
	&-right {
		text-align: right;
	}

	&-uppercase {
		text-transform: uppercase;
	}
}

.trumpet {
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	margin: 0 0 $spacing-small;
}
