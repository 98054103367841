﻿$block: "card";

@mixin card-theme($color, $useDarkText) {
	--card-theme-color: #{$color};
	@if ($useDarkText) {
		--card-color: #{$color-black};
		--card-image-filter: brightness(0);
	} @else {
		--card-color: #{$color-white};
		--card-image-filter: brightness(0) invert(1);
	}
}

.#{$block} {
	position: relative;
	height: 100%;
	width: 100%;

	&__favorite-wrap {
		position: absolute;
		top: $icon-space * 2;
		right: $icon-space * 2;
	}

	&__block-link {
		position: absolute;
		inset: 0;

		&:hover,
		&:focus-visible {
			~ .#{$block}__media-wrap img {
				transform: scale(1.03);
			}
			~ .#{$block}__content .#{$block}__heading {
				text-decoration: underline;
			}
		}
	}

	&__main-link {
		&:hover,
		&:focus-visible {
			.#{$block}__media-wrap img {
				transform: scale(1.03);
			}
			.#{$block}__content .#{$block}__heading {
				text-decoration: underline;
			}
		}
	}

	&__content {
		padding-top: $spacing-small * 1.5;

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			display: block;
			&:focus-visible {
				@include focus;
			}
		}
	}

	&__media-wrap {
		display: block;
		pointer-events: none;
		line-height: 0;
	}

	&__media {
		display: inline-block;
		width: 100%;
		overflow: hidden;

		img {
			transition: transform 250ms $animation-easing-default;
		}
	}

	&__heading {
		transition: color 250ms $animation-easing-default;
		font-size: $font-size-small;
		margin-bottom: $spacing-small * 2;

		h3,
		h4 {
			font-size: $font-size-small;
		}
	}

	&__tags {
		display: flex;
		flex-wrap: wrap;
		margin-left: -$spacing-small * 0.5;
		margin-right: -$spacing-small * 0.5;
	}

	&__tag {
		text-transform: uppercase;
		font-size: $font-size-xs;
		padding: 0 $spacing-small * 0.5;
		margin-bottom: $spacing-small * 0.3;

		&--highlighted {
			font-weight: $font-weight-bold;
		}
	}

	&__info-row {
		display: flex;
		margin-bottom: 10px;
		font-size: $font-size-xs;
	}

	&__info {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	&__icon-wrap {
		width: 24px;
		min-width: 24px;
		height: 24px;
		margin-right: 10px;
	}

	&__circle {
		background-color: $color-gray-light;
		border-radius: 20px;
		padding: 4px 13px;
		font-weight: $font-weight-bold;
	}

	&__cta {
		display: flex;
		align-items: flex-start;
		word-break: break-word;

		&:hover {
			color: $color-primary;
			.icon {
				svg {
					fill: $color-primary;
				}
				@include animateRight();
			}
		}
		.icon-wrapper {
			display: block;
			line-height: 0;
			transform: translateY(
				3px
			); // magic number for visual alignment of the icon
			@include bp(sm) {
				transform: translateY(
					5px
				); // magic number for visual alignment of the icon
			}

			.safari & {
				transform: translateY(3px);
			}
		}
		.icon {
			display: inline-block;
			width: auto;
			margin-right: $spacing-default/4;

			svg {
				$svg-size: 10px;
				width: $svg-size;
				height: $svg-size;
			}
		}

		&:not(:last-child) {
			margin-bottom: $spacing-small;
		}

		&-links {
			padding: 0 $spacing-small * 2.5 $spacing-small * 2.5 $spacing-small *
				2.5;
		}
	}

	&--content {
		background-color: $color-white;

		.#{$block} {
			&__content {
				padding: $spacing-small * 1.5 $spacing-small * 2.5
					$spacing-small * 2.5 $spacing-small * 2.5;
			}
		}
	}

	&--pure {
		background-color: $color-gray-light;

		.#{$block} {
			&__heading {
				margin-bottom: $spacing-small * 0.5;
			}
			&__date {
				margin-top: 0;
				margin-bottom: 0;
			}
			&__content-text {
				margin-bottom: $spacing-default;
			}

			&__content {
				padding: $spacing-small * 3 $spacing-small * 2.5 $spacing-small *
					2.5 $spacing-small * 2.5;
				display: flex;
				flex-direction: column;
				height: 100%;
			}

			&__icon-list {
				margin-top: auto;

				&-item {
					display: flex;
					align-items: flex-start;

					&:not(:last-child) {
						margin-bottom: $spacing-small * 1.25;
					}
				}

				&-label {
					padding-top: 5px;
				}

				&-icon {
					width: $spacing-default * 1.5;
					min-width: $spacing-default * 1.5;
					margin-right: $spacing-small;
				}
			}
		}
	}

	&--e-resource {
		background-color: $color-white;

		.#{$block} {
			&__content {
				padding: $spacing-small * 1.5 $spacing-small * 2.5
					$spacing-small * 2.5 $spacing-small * 2.5;
			}

			&__heading {
				margin-bottom: $spacing-small * 1.5;
			}

			&__manchet {
				margin: 0;
			}
		}
	}

	&--favorite {
		box-shadow: $box-shadow-base;
		background-color: $color-white;

		.#{$block} {
			&__content {
				padding: $spacing-small * 3 $spacing-small * 2.5 $spacing-small *
					2.5 $spacing-small * 2.5;
			}

			&__tags {
				margin-bottom: $spacing-small * 1.5;
			}
		}
	}

	&--module {
		background-color: $color-white;

		.#{$block} {
			&__content {
				padding: $spacing-small * 1.5 $spacing-small * 2.5
					$spacing-small * 2.5 $spacing-small * 2.5;
			}

			&__tags {
				margin: 0;
				margin-bottom: $spacing-small;
			}
			&__tag {
				border-bottom: 4px solid var(--card-theme-color, $color-primary);
				padding: 0;
				padding-bottom: $spacing-small;
			}

			&__heading {
				margin-bottom: $spacing-small * 1.5;
			}
			&__subheading {
				font-weight: $font-weight-bold;
				font-size: $font-size-xs;
				border-top: $border-small solid $color-border-grey;
				padding: $spacing-small / 2 0;
			}

			&__info-row {
				border-top: $border-small solid $color-border-grey;
				padding-top: $spacing-small;

				+ .#{$block}__info-row {
					border: none;
					padding-top: 0;
				}
			}
		}
	}

	&--themeBlueLight {
		@include card-theme($color-light-blue, true);
	}
	&--themeBlueDark {
		@include card-theme($color-dark-blue, false);
	}
	&--themeGrayLight {
		@include card-theme($color-gray-light, true);
	}
	&--themeWhite {
		@include card-theme($color-white, true);
	}
	&--themeYellow {
		@include card-theme($color-yellow, true);
	}
	&--themeOrange {
		@include card-theme($color-orange, true);
	}
	&--themeBlue {
		@include card-theme($color-blue, false);
	}
	&--themePink {
		@include card-theme($color-pink, true);
	}
	&--themeRed {
		@include card-theme($color-red, true);
	}
	&--themeTurquoise {
		@include card-theme($color-turquoise, true);
	}
	&--themeTurquoiseLight {
		@include card-theme($color-turquoise-light, true);
	}
	&--themeDefault {
		@include card-theme($color-background, true);
	}
}
