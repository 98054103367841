.portrait {
	$portrait-size: 120px;
	$portrait-size-lg: 130px;
	background-color: $body-background;
	&__info {
		display: flex;
		align-items: center;
		margin: $spacing-default * 0.75 0 $spacing-default;
		@include bp(lg) {
			margin: $spacing-default 0 $spacing-medium;
		}
	}
	&__info--inner {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
	}
	&__img {
		display: flex;
		align-items: center;
		margin-right: $spacing-small;
		width: $portrait-size;
		height: $portrait-size;
		min-width: $portrait-size;

		@include bp(lg) {
			width: $portrait-size-lg;
			height: $portrait-size-lg;
			display: block;
			margin-right: $spacing-default;
		}
		img {
			border-radius: 50%;
			border: $border-medium solid $color-gray-light;
		}
	}
	&__name {
		font-size: 1.3rem;

		@include bp(lg) {
			font-size: 1.5rem;
		}
	}
	&__position {
		font-weight: $font-weight-regular;
		display: block;
		margin-top: $spacing-small * 0.5;
		font-size: $font-size-h4--small;

		@include bp(lg) {
			font-size: 1.2rem;
		}
	}
	&__content,
	&__content p {
		margin: 0;
		@include bp(lg) {
			font-size: 1.2rem;
			line-height: $line-height-medium;
		}
	}
	&__headline {
		&.h3 {
			font-size: 1.4rem;
			margin-bottom: $spacing-default;
			@include bp(md) {
				font-size: 1.67rem;
			}
		}
	}
}
