$block: "video-testimonial";

.#{$block} {
	padding-left: 0;
	padding-right: 0;

	&__inner {
		@include bp(lg) {
			padding: $component-padding-lg;
		}
		@include bp(xl) {
			padding: $component-padding-xl;
		}
		@include bp(xxl) {
			padding: $component-padding-xxl;
		}
	}

	&__columns {
		width: 100%;
		display: none;
		@include bp(lg) {
			display: block;
		}
	}

	&__headline {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		padding: $spacing-medium;
		padding-top: $spacing-default * 1.25;
		padding-bottom: 0;
		@include bp(lg) {
			padding: 0;
			padding-bottom: $spacing-default * 2.5;
		}
		> h2 {
			margin-bottom: 0;
		}
	}

	&__items {
		padding: $spacing-default * 1.5;
		padding-bottom: $spacing-default;
		flex-wrap: wrap;
		overflow-x: unset;
		margin: 0;

		@include bp(lg) {
			padding: 0;
			justify-content: space-between;
		}
	}
	&__item {
		padding: 0;
		margin: 0;

		@include bp(lg) {
			max-width: 31%; //override bootstrap col width
		}
	}
	&__item-inner {
		width: 100%;
	}
	&__item-content {
		display: block;
		width: 100%;
		position: relative;
	}

	//START ovrride video testimonial play button
	.video-thumbs__play {
		padding: $spacing-default * 0.9;

		&:after {
			width: $spacing-default * 3;
			height: $spacing-default * 3;
			background-color: $color-white;
		}
	}

	.video-thumbs__container-triangle {
		background-image: $videoplayerIconBlack;
		filter: var(--overlay-image-filter);
	}
	//END ovrride video testimonial play button

	.preview {
		.video-thumbs__play {
			transform: translateX(-50%) translateY(-50%) scale(1.2);
		}

		.#{$block}__overlay {
			opacity: 1;
		}
		.#{$block}__item-cover-img {
			opacity: 0;
		}
	}

	.playing {
		.#{$block}__overlay,
		.#{$block}__item-cover-img,
		.#{$block}__item-hover-video {
			opacity: 0;

			@include bp(lg) {
				z-index: -1;
			}
		}

		.#{$block}__item-btn-close {
			display: block;

			@include bp(lg) {
				display: none;
			}
		}
	}

	&__item-cover-img {
		position: absolute;
		inset: 0;
		transition: opacity 0.25s ease-in-out;
	}

	&__item-hover-video {
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
		background-color: $color-black;
		transition: opacity 0.25s ease-in-out;
	}

	&__item-btn-close {
		position: absolute;
		top: 0;
		left: calc(50% - 20px);
		width: 40px;
		height: 40px;
		padding: 0;
		border: none;
		margin: 0;
		background-color: $color-white;
		display: none;
	}

	&__overlay {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: $spacing-default;
		z-index: 9;
		text-align: center;
		background: linear-gradient(
			to top,
			rgba($color-black, 0.87) 0%,
			rgba($color-black, 0) 100%
		);
		pointer-events: none;
		color: $color-white;
		transition: opacity 0.25s ease-in-out;
		opacity: 0;

		.video-thumbs__play {
			position: relative;
		}
	}

	&__item-headline {
		font-weight: $font-weight-bold;
		line-height: $line-height-default;
		font-size: 1.33rem;
	}
	&__item-teaser {
		color: inherit;
		display: block;
		height: auto;
	}

	&__swiper {
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: $spacing-default * 2 $spacing-medium 80px !important;
	}

	&__swiper-pagination {
		top: auto !important;
		bottom: 30px !important;
	}
	&__swiper-scrollbar {
		top: auto !important;
		bottom: 35px !important;
	}
}
