.form-group {
	margin-bottom: $spacing-default * 1.5;

	label {
		display: inline-block;
		max-width: 100%;
		font-weight: $font-weight-bold;
	}

	&--filter {
		display: flex;
		flex-direction: row;
		margin-bottom: 0;
		button {
			cursor: pointer;
		}
		label {
			font-weight: $font-weight-regular;
		}
	}
}
.help-block {
	display: block;
	margin-bottom: $spacing-default/4;
}
.form-control {
	// type text, textarea, select, date, file
	width: 100%;
	max-width: 100% !important;
	background-color: $color-white;
	outline: 0;
	transition: box-shadow 0.15s ease-out;

	display: inline-block;
	border-radius: $border-radius-base;
	padding: $spacing-form * 1.17 $spacing-form * 1.29 $spacing-form * 1.16
		$spacing-form * 1.5;
	font-weight: $font-weight-bold;
	font-size: $font-size-h5;
	box-shadow: 0 0px 3px 0 rgba($color-black, 0.2),
		0 0px 3px 0 rgba($color-black, 0.19);

	text-decoration: none;
	cursor: pointer;
	border: none;
	line-height: $line-height-default;
	vertical-align: middle;
	min-width: $spacing-large * 2;
	-webkit-appearance: none;

	@include bp(xs) {
		font-size: $font-ios-base; // prevent zoom-in on ios
	}

	&:focus {
		box-shadow: 0 0 0 2px rgba($color-dark-blue, 0.25);
		outline: 3px solid $color-dark-blue;
	}
}
.umbraco-forms-form input.text:focus,
.umbraco-forms-form input.title:focus,
.umbraco-forms-form textarea:focus,
.umbraco-forms-form select:focus {
	outline: 1px solid $color-black;
}

.checkboxlist input[type="checkbox"],
.radiobuttonlist input[type="radio"],
.checkbox input[type="checkbox"],
input[type="checkbox"],
input[type="radio"] {
	// hide input because it is stylized differently in different browsers
	opacity: 0;
	display: block !important;

	:not(.coi__checkbox) {
		// if checkbox is not on the cookie information banner
		width: 0 !important;
		height: 0 !important;
	}

	~ label {
		position: relative;
		cursor: pointer;
		display: inline-flex;
		float: left !important;

		&:before {
			content: "";
			height: $spacing-default * 1.5 !important;
			width: $spacing-default * 1.5 !important;
			min-height: $spacing-default * 1.5 !important;
			min-width: $spacing-default * 1.5 !important;
			outline: none;
			transition-duration: 0.3s;
			background-color: $color-white;
			display: inline-block;
			position: relative;
			transform: translateY(6px);
			margin-right: $spacing-default/2;
			padding: $spacing-default/2;
			bottom: $spacing-default * 0.6;
			border-radius: $border-radius-primary;
		}
	}

	&:focus + label::before,
	&:active + label::before,
	&:focus + input[type="hidden"] + label::before,
	&:active + input[type="hidden"] + label::before {
		//  '+ input[type="hidden"]' is needed for Data Consent
		box-shadow: 0 0 0 2px rgba($color-dark-blue, 0.25);
		outline: 3px solid $color-dark-blue;
	}
}

.radiobuttonlist input[type="radio"],
input[type="radio"] {
	border-radius: $border-radius-circle;

	&:checked + label {
		&:after {
			content: "";
			top: 1px;
			left: 7px;
			position: absolute;
			width: $spacing-default * 0.8;
			height: $spacing-default * 0.8;
			background-color: $color-dark-blue;
			border-radius: $border-radius-circle;
		}
	}

	+ label {
		&:before {
			border-radius: $border-radius-circle;
		}
	}
}

.checkboxlist {
	padding: 0 !important;

	label {
		float: none !important;
		cursor: pointer;
	}
}

.checkboxlist,
.radiobuttonlist {
	width: 100%;
	display: flex;
	flex-direction: column;

	input[type="radio"] {
		margin-top: $spacing-default/2;
	}
}

input[type="text"],
input[type="password"],
input[type="email"] {
	height: $spacing-default * 3;
}

input[type="text"],
input[type="email"],
input[type="password"] {
	&::-ms-clear,
	&::-ms-reveal {
		// removes 'x' and eye icons from inputs in IE11
		display: none;
		width: 0;
		height: 0;
	}

	&::-webkit-textfield-decoration-container {
		// hides auto-fill icon in Safari
		visibility: hidden;
	}
}

.umbraco-forms-form {
	color: $color-black;
	margin-top: $spacing-default * 2;
	margin-bottom: $spacing-large;
	max-width: $content-text-max-width;
	background-color: $color-light-blue;
	padding: $spacing-default !important;
	@include bp(lg) {
		padding: $spacing-default * 2 !important;
	}

	.checkboxlist input[type="checkbox"],
	.radiobuttonlist input[type="radio"],
	.checkbox input[type="checkbox"],
	input[type="checkbox"],
	input[type="radio"] {
		width: 0 !important;
		height: 0 !important;
		display: block !important;
	}

	&.umbraco-forms- {
		@include bp(xs) {
			padding-left: $spacing-default/2;
			padding-right: $spacing-default/2;
		}
	}

	.row-fluid {
		display: flex;
		flex-wrap: wrap;
		margin-right: -$spacing-default/2;
		margin-left: -$spacing-default/2;

		@include bp(xs) {
			margin-left: -$spacing-default/4;
			margin-right: -$spacing-default/4;
		}
	}

	.umbraco-forms-fieldset {
		// each form group
		padding: 0;
		padding-top: $spacing-form;

		legend {
			font-size: $font-size-h2 !important;
		}

		+ .umbraco-forms-fieldset {
			margin-top: $spacing-default * 3;
		}
	}
	.co-checkmark {
		position: absolute;
		height: $spacing-default * 1.5 !important;
		width: $spacing-default * 1.5 !important;
		outline: none;
		border-radius: $border-radius-primary;
		background-color: $color-white;
	}
	input[type="checkbox"]:focus + .co-checkmark {
		box-shadow: 0 0 0 2px rgba($color-dark-blue, 0.25);
		outline: 3px solid $color-dark-blue;
	}

	input[type="checkbox"]:checked + .co-checkmark {
		&:after {
			position: absolute;
			content: "\2714";
			color: $color-dark-blue;
			padding: 7px;
			top: -2px;
			font-size: $font-size-small;
			text-align: center;
			transform: rotate(12deg);
		}
	}
	.checkbox-single {
		position: relative;

		input[type="checkbox"] {
			height: $spacing-default * 1.5 !important;
			width: $spacing-default * 1.5 !important;
			cursor: pointer;
		}
		.co-checkmark {
			top: 0;
			left: 0;
			pointer-events: none;
		}
	}
	.checkboxlist {
		label {
			position: relative;
			margin-left: $spacing-default * 2.1;
			margin-bottom: $spacing-default;
			margin-top: $spacing-default/2;
			font-weight: $font-weight-regular;

			&:first-of-type {
				margin-top: $spacing-default;
			}

			.co-checkmark {
				top: -($spacing-default/4);
				left: -($spacing-default * 2.1);
			}
		}
	}
	.radiobuttonlist {
		label {
			font-weight: $font-weight-regular;
		}
	}

	.umbraco-forms-label-container {
		position: relative;
	}
	.umbraco-forms-field {
		&.shortanswer,
		&.singlechoice,
		&.multiplechoice {
			margin-bottom: $spacing-default * 0.75 !important;
		}

		// wrapper for each field
		@extend .form-group;

		select {
			// hide default dropdown arrow
			-webkit-appearance: none;
			-moz-appearance: none;
			-o-appearance: none;
			appearance: none;

			&::-ms-expand {
				// hide default dropdown arrow in IE11
				display: none;
			}

			&::-ms-value {
				// remove default blue background on dropdown value in IE11
				background: none;
				color: $color-black;
			}

			background-image: url("/src/Assets/images/chevron.svg"); // use the same dropdown icon for all browsers
			background-position: 96% center;
			background-repeat: no-repeat;
			outline: none;
		}
	}

	input[type="text"]::placeholder,
	input[type="password"]::placeholder,
	input[type="email"]::placeholder,
	input[type="number"]::placeholder,
	textarea::placeholder {
		color: transparent;
	}
	.umbraco-forms-field {
		&.shortanswer,
		&.date,
		&.longanswer {
			text-transform: unset;
			font-size: inherit;
			display: flex;
			flex-direction: column;
			.umbraco-forms-label {
				position: absolute;
				top: $spacing-form * 1.9;

				color: $color-text-grey;
				left: $spacing-default * 1.25;
				z-index: 10;
				font-weight: normal !important;
				pointer-events: none;
				background: $color-white !important;
				width: calc(100% - ($spacing-default * 2.5));
				height: $spacing-default * 1.25;

				&--active {
					display: block !important;
					top: $spacing-form * 1.3;
					font-size: $font-size-h2--small/2;
					background: unset !important;
					width: auto;
					height: auto;
				}
			}
			.help-block {
				order: -1;
			}
		}
	}

	.umbraco-forms-label {
		&--header {
			margin-bottom: $spacing-small * 1.5;
		}
	}

	textarea {
		// textarea
		min-height: $spacing-large * 2;
		font-weight: normal !important;
		border: $spacing-form solid $color-white !important;
		padding: $spacing-form * 0.6 $spacing-form * 0.6 $spacing-form/2
			$spacing-form * 0.6 !important;
	}

	input[type="file"] {
	}

	.co-validmark {
		position: relative;
		&--valid {
			&:after {
				position: absolute;
				content: "\2714";
				background: $color-dark-blue;
				padding: $spacing-default/4;
				border-radius: $border-radius-circle;
				color: $color-white;
				width: $spacing-default * 1.5;
				height: $spacing-default * 1.5;
				right: $spacing-default;
				top: -3px;
				font-size: $font-size-small;
				text-align: center;
				transform: rotate(12deg);

				.safari & {
					display: flex;
					align-items: center;
					justify-content: center;
					line-height: 1;
					top: -8px;
				}

				@include bp-max(md) {
					display: flex;
					align-items: center;
					justify-content: center;
					line-height: 1;
					top: -8px;
				}
			}
		}

		&--not-valid {
			&:after {
				position: absolute;
				content: "×";
				background: $color-error;
				padding: $spacing-default/4;
				border-radius: $border-radius-circle;
				color: $color-white;
				width: $spacing-default * 1.5;
				height: $spacing-default * 1.5;
				right: $spacing-default;
				top: -3px;
				font-size: $font-size-medium;
				text-align: center;

				.safari & {
					display: flex;
					align-items: center;
					justify-content: center;
					line-height: 1;
					top: -8px;
				}

				@include bp-max(md) {
					display: flex;
					align-items: center;
					justify-content: center;
					line-height: 1;
					top: -8px;
				}
			}
		}
	}

	.umbraco-forms-field {
		.text,
		textarea,
		select,
		.datepickerfield,
		input[type="file"] {
			@extend .form-control;
			margin: 0.5em 0;
		}

		.datepickerfield {
			margin: $spacing-form * 0.5 0;
		}

		span.contourError,
		span.field-validation-error {
			padding: $spacing-default/2 0;
			color: $color-error !important;
			font-weight: bold;
			display: block;

			&::before {
				clear: both;
				font-weight: normal;
			}
		}
	}

	input[type="submit"] {
		width: 100%;
		border-radius: $border-radius-base;
		padding: $spacing-form * 1.17 $spacing-form * 1.29 $spacing-form * 1.16
			$spacing-form * 1.5;
		border: none;
		background: inherit;
		color: $color-white;
		text-align: inherit;
		cursor: pointer;

		&:hover,
		&:focus,
		&:active {
			box-shadow: 0 0 0 2px rgba($color-dark-blue, 0.25);
		}
	}
	// make sure all of submit button is clickable
	.submit-forms-form-button {
		&::after {
			margin-left: 0;
		}
		&::before {
			pointer-events: none;
		}
		input[type="submit"] {
			padding-right: calc($spacing-form * 1.29 + 4rem);
		}
	}

	.btn.prev {
		margin-bottom: $spacing-default;
	}

	.checkbox label {
		padding-left: 0;
	}

	.umbraco-forms-container.col-md-12 {
		width: 100%;
	}

	.umbraco-forms-container.col-md-6 {
		width: 50%;

		@include bp(sm) {
			width: 100%;
		}
	}

	.umbraco-forms-navigation {
		.col-md-12 {
			width: 100%;
		}
	}
}

.pika-single {
	.is-today .pika-button {
		color: $color-dark-blue;
	}

	.is-selected .pika-button,
	.has-event .pika-button {
		background-color: $color-dark-blue;
		color: $color-white;
	}

	.pika-button:hover,
	.pika-row.pick-whole-week:hover .pika-button {
		background-color: $color-light-blue;
		color: $color-white;
	}
}

.umbraco-forms-submitmessage {
	width: 100%;
	display: flex;
	align-items: center;
	margin-top: $spacing-default;
	margin-bottom: $spacing-default * 2;
	background-color: $color-light-blue;
	padding: $spacing-default !important;
	@include bp(lg) {
		padding: $spacing-default * 2 !important;
	}
}

.input-simple {
	&.input-validation-error {
		outline: 3px solid $color-orange !important;
		background-color: lighten(
			$color: $color-orange,
			$amount: 20
		) !important;
		box-shadow: 0 0px 3px 0 rgba($color-orange, 0.2),
			0 0px 3px 0 rgba($color-orange, 0.19) !important;
	}

	&--has-label {
		padding: $spacing-form * 1.5 $spacing-form * 1.29 $spacing-form * 0.8
			$spacing-form * 1.5 !important;

		&.textarea-simple {
			padding: $spacing-form * 1.5 $spacing-form * 0.6 $spacing-form * 0.5
				$spacing-form * 0.6 !important;
		}
	}
}

.umbraco-forms-navigation {
	&__btn-wrap {
		@include bp(lg) {
			position: relative;
			top: auto;
			right: auto;
			transform: translate3d(95px, 0, 0);
		}
		.btn {
			float: right;
			display: flex;
		}
	}
}
