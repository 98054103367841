﻿$block: "related-content";

.#{$block} {
	background-color: $color-white;
	@include bp(lg) {
		background-color: transparent;
	}

	&__bg {
		background-color: $color-white;
		padding-top: $spacing-small * 5;
		padding-bottom: $spacing-small * 2;
	}

	&__col {
		margin-bottom: $spacing-small * 5;
	}

	&__inner {
		@include bp(lg) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
