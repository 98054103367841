body {
	opacity: 1 !important;
}

.section {
	padding: $spacing-large $spacing-default;
	@include bp(smx) {
		padding: 65px $spacing-medium;
	}
	.section {
		padding: 0 0 15px 0;
		&:last-of-type {
			padding: 0 0 0 0;
		}
	}
	&.section--compact {
		padding: $spacing-medium $spacing-medium;
	}
	&.section--fullwidth {
		padding-left: 0;
		padding-right: 0;

		@include bp(sm) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&.section--pullup {
		padding-top: 0;
		margin-top: -74px;
		@include bp(smx) {
			margin-top: -130px;
		}
	}
	.section--pullup {
		margin-top: -235px;
		// @include bp(sm) {
		// 	margin-top: -180px;
		// }
	}
	.section__inner {
		box-sizing: border-box;
		max-width: $row-max-width;
		margin: 0 auto;
	}

	.section__intro {
		width: 100%;
		box-sizing: border-box;
		max-width: $row-max-width;
		margin: 0 auto;
		display: flex;
		margin-bottom: $spacing-medium;
		box-sizing: border-box;

		@include bp(sm) {
			margin-bottom: $spacing-large;
			&.section__intro--center-large {
				text-align: center;
			}
		}
	}
	.section__intro--center {
		justify-content: center;
		text-align: center;
	}

	.section__intro--spacebetween {
		justify-content: space-between;
		align-items: center;
	}

	.section__intro--border {
		margin-bottom: 60px;
		border-bottom: $color-border-grey 1px solid;
		padding-bottom: 10px;
		flex-direction: column;
		align-items: flex-start;
		@include bp(xs) {
			flex-direction: row;
			align-items: baseline;
		}
		@include bp(sm) {
			padding-bottom: 20px;
		}
	}

	.section__title {
		@extend .h3;
		margin-bottom: 10px;
	}
	@include bp(xs) {
		.section__title {
			margin-bottom: 0;
		}
	}
	.section__link {
		display: flex;
		align-items: center;
		color: $color-primary;
		font-weight: bold;
		@extend .body-text;
		.section__link-icon {
			width: 18px;
			height: 18px;
			background-color: $color-primary;
			margin-left: 10px;
			border-radius: 2px;
			padding: 3px;
		}
		.icon svg {
			height: 8px;
			fill: $color-white;
		}
		&:hover,
		&.focus-visible {
			text-decoration: underline;
			.icon svg {
				@include animateRight();
			}
			.section__link-icon {
				background-color: darken($color-primary, 10);
			}
			color: darken($color-primary, 10);
		}
	}
}

.section--footer {
	hr {
		width: 100%;
		max-width: calc(100% - 30px);

		margin-top: 30px;
		margin-bottom: 40px;
		border-top: 1px solid $color-border-grey;
		border-bottom: 0;
	}
	h2 {
		font-size: 32px !important;
		font-weight: 800;
	}
	@include bp(smx) {
		hr {
			margin-top: 50px;
			margin-bottom: 60px;
			max-width: calc(#{$row-max-width} - 20px);
		}
		h2 {
			font-size: 32px !important;
		}
	}
}

.bypass-block-link {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
	margin: -1px;
	pointer-events: none;
	&:focus {
		pointer-events: all;
		top: 0;
		left: 0;
		clip: auto;
		height: auto;
		overflow: visible;
		white-space: normal;
		width: auto;
		margin: auto;
		text-decoration: none;
		background: $body-color;
		box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.4);
		outline: 0 dashed transparent;
		color: #fff;
		padding: 10px;
		z-index: 100;
	}
}

.inline-link {
	color: $color-primary !important;
	text-decoration: underline !important;
	font-weight: bold;
	&:hover {
		color: darken($color-primary, 10) !important;
	}
}

.inview-watch:not(.inview-custom-transition),
.inview-watch-delayed:not(.inview-custom-transition) {
	transition: opacity 500ms linear 50ms,
		transform 500ms $animation-easing-default;
}
.inview-watch-delayed:not(.inview-custom-transition) {
	transition-delay: 200ms;
}
.inview-watch:not(.inview-custom-transition),
.inview-watch-delayed:not(.inview-custom-transition) {
	opacity: 0;
	transform: translate3d(0, -20px, 0);
	&.inview--was-visible,
	&.inview-delayed--was-visible {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
.no-js {
	.inview-watch,
	.inview-watch-delayed,
	.inview-watch:not(.inview-custom-transition),
	.inview-watch-delayed:not(.inview-custom-transition) {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.visibility--hidden {
	visibility: hidden;
}
.content__header {
	margin-top: $spacing-medium;
	p {
		font-weight: bold;
	}
}

.container-large {
	max-width: $row-large-max-width;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 0 $row-default-padding;
}
.container-medium {
	max-width: $row-medium-max-width;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding: 0 0.5em;
}
.custom_column-xsmall {
	@include bp(lg) {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}
}
.custom_column-small {
	@include bp(lg) {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
		padding: 0;
		padding-left: 12.5%;
	}
}
.custom_column-large {
	@include bp(lg) {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}
}

.content-space {
	margin-top: $spacing-small * 3;
	margin-bottom: $spacing-small * 3;

	@include bp(lg) {
		margin-top: $spacing-small * 4;
		margin-bottom: $spacing-small * 4;
	}

	&--80 {
		@include bp(lg) {
			margin-top: $spacing-small * 8;
			margin-bottom: $spacing-small * 8;
		}
	}
}
.space-below {
	margin-bottom: $spacing-small * 3;

	&--0 {
		margin-bottom: 0;
	}
	// NOTE: Sizes are slightly smaller for mobile. classnames are based on the px sizes for desktop.
	&--20 {
		margin-bottom: $spacing-small * 1.5;
	}
	&--40 {
		margin-bottom: $spacing-small * 3;
	}
	&--80 {
		margin-bottom: $spacing-small * 6;
	}

	@include bp(lg) {
		margin-bottom: $spacing-default * 2;

		&--20 {
			margin-bottom: $spacing-default;
		}
		&--40 {
			margin-bottom: $spacing-default * 2;
		}
		&--80 {
			margin-bottom: $spacing-default * 4;
		}
	}
}

.small-screen-view {
	display: block;
	@include bp(sm) {
		display: none;
	}
}

.big-screen-view {
	display: none;
	@include bp(sm) {
		display: block;
	}
}

.sticker {
	right: -50px;
	bottom: -10px;
	transform: rotate(-25deg);

	display: block;
	background: inherit;
	position: absolute;

	height: 100px;
	width: 100px;
	z-index: 1;
	z-index: 1;

	&__overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.2);
	}
	@include bp(lg) {
		height: 200px;
		width: 550px;
		right: -70px;
		bottom: 90px;
		width: 600px;
	}
}

.dount-sticker {
	position: relative;
	overflow: hidden;

	&::before {
		@include bp(lg) {
			position: absolute;
			box-sizing: border-box;
			content: "";
			width: $dount-space * 2;
			height: $dount-space;
			border: $spacing-large * 3 solid $color-primary;
			border-bottom: none;
			right: -$spacing-default * 3.5;
			border-top-left-radius: $dount-space;
			border-top-right-radius: $dount-space;
			transform: rotate(150deg);
		}
	}
	&--top {
		&::before {
			top: $spacing-medium * 4;
		}
	}
	&--bottom {
		&::before {
			bottom: $spacing-default;
		}
	}
}

.show-lg {
	display: none;
	@include bp(lg) {
		display: block;
	}
}

.hidden-lg {
	display: block;
	@include bp(lg) {
		display: none;
	}
}
.description-lg {
	@include bp(lg) {
		font-size: 24px;
		line-height: 1.5;
	}
}

.bg-gray-light {
	background-color: $color-gray-light;
}

img {
	object-fit: cover;
}

.custom-swiper {
	&__scrollbar {
		left: 20% !important;
		height: $spacing-default * 0.4 !important;
		width: 80% !important;
		top: 92% !important;
		.swiper-scrollbar-drag {
			&::before,
			&:after {
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				left: 0;
				content: "";
			}
			&:after {
				width: 100vw;
				z-index: -1;
			}
			&:before {
				z-index: 1;
			}
		}
	}
	&__pagination {
		width: 15% !important;
		top: 90.5% !important;
		left: $spacing-default !important;
		font-size: $font-size-xs !important;
		bottom: $spacing-default * 2 !important;

		//override swiper style
		.swiper-pagination-current {
			font-weight: bold !important;
		}
	}
}

.hide {
	&--lg {
		display: block !important;
		@include bp(lg) {
			display: none !important;
		}
	}
	&--lg-flex {
		display: flex !important;
		@include bp(lg) {
			display: none !important;
		}
	}

	&--sm {
		display: none !important;
		@include bp(lg) {
			display: block !important;
		}
	}
	&--sm-flex {
		display: none !important;
		@include bp(lg) {
			display: flex !important;
		}
	}
}
.rte {
	br {
		line-height: calc(0.6rem + 1em + 25px);
	}
}
