html.mobile-nav-open,
html.mobile-nav-open body {
	overflow: hidden;

	@include bp(lg) {
		overflow: visible;
	}
}

.header {
	&--small {
		display: block;
		@include bp(lg) {
			display: none;
		}
		.header {
			&__inner {
				padding: 0;
				margin: 0 auto;
				background-color: transparent;
				box-sizing: border-box;
				display: block;
				border-radius: $border-radius-primary;
			}
			&__logo {
				display: flex;
				align-items: center;
				padding-bottom: 0;
				@include bp(md) {
					height: 58px;
					align-items: center;
					display: flex;
				}
				img {
					min-width: 58px;
					max-width: 58px;
					min-height: 58px;
					max-height: 58px;
					object-fit: unset;
					filter: var(--overlay-image-filter);
				}
			}
			&__page-bottom-border {
				border-bottom: 1px solid $body-background;
			}
			&__small-menu {
				display: flex;
				justify-content: space-between;
				box-shadow: 0px 0px $spacing-default/2 $spacing-default/4
					rgb(0 0 0 / 5%);
				padding: $spacing-default/2 $spacing-default * 0.75;
				&.gray-bg {
					background-color: $body-background;
				}
				.header {
					&__search {
						width: 55px; // will be change with search
					}

					&__search-icon-magnifier {
						padding: 16px; // will be change with search
						padding-left: 8px;
					}
					&__close {
						display: none;
					}
					&__panel-trigger {
						display: flex;
						align-items: center;
						position: relative;
					}
					&__burger,
					&__close {
						box-sizing: content-box;
						width: $spacing-default * 1.25;
						padding: $spacing-default/2;
						cursor: pointer;
						transition: fill $animation-duration-default
							$animation-easing-default;
						&:hover,
						&.focus-visible {
							fill: $color-primary;
						}
						svg {
							fill: currentColor;
						}
					}
					&__close {
						svg {
							fill: $color-black !important;
						}
					}
				}
			}
			&__small-panel {
				max-height: 0;
				visibility: hidden;
			}
			&__service-links {
				padding: $spacing-small 0;
				.icon {
					display: inline;
					svg {
						width: $spacing-default/2;
						height: $spacing-default/2;
					}
				}
				.header__service-link {
					padding: $spacing-default * 0.75 $spacing-default * 1.5;
				}
			}
			&__page--background {
				background-color: $color-gray-light !important;
				color: currentColor !important;
			}
			&__page-nav--background {
				background-color: inherit !important;
				color: currentColor !important;
			}
			&__page-link {
				padding: $spacing-default $spacing-default * 1.5;
				&-item {
					display: flex;
					flex-direction: column;
				}
			}
			&__link {
				padding: $spacing-default $spacing-medium;
			}
			&__service-link:hover,
			&__page-link:hover,
			&__page-link--active {
				text-decoration: underline;
			}
			&__page-link,
			&__service-link {
				display: block;
				width: 100%;
			}
			&__page--sub-nav {
				visibility: hidden;
				display: none;
				flex-direction: column;
			}

			&__page-nav--expanded,
			&__page-nav--collapsed {
				align-self: center;
				svg {
					fill: currentColor;
				}
			}
		}

		&:before {
			content: "";
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100vh;
			pointer-events: none;
			background-color: $overlayColor;
			transition: background-color $animation-duration-default * 2 ease;
		}

		&.header--show-panel {
			color: $color-black !important;
			height: 100%;
			.header__search {
				svg {
					fill: $color-black !important;
				}
			}
			.header__logo {
				&--link {
					background-color: transparent !important;
				}
				img {
					filter: brightness(0);
				}
			}
			&:before {
				content: "";
				background-color: $body-background;
			}
			.header {
				&__burger {
					display: none;
				}
				&__close {
					display: block;
				}
				&__small-menu {
					border-bottom: 1px solid $header-border-color;
					background-color: $body-background !important;
				}
				&__small-panel {
					transition: max-height $animation-duration-default * 3
							$animation-easing-default,
						height $animation-duration-default * 3
							$animation-easing-default;
					opacity: 1;
					max-height: calc(100vh - 60px) !important;
					overflow: auto;
					overscroll-behavior: contain;
				}
				&__page-nav {
					border-left: 1px solid $body-background;
					width: $spacing-default * 3;
					min-width: $spacing-default * 3;
					display: flex;
					justify-content: center;
					color: inherit;
					&.expanded {
						border-left: none;
					}

					span {
						width: 100%;
					}

					.icon {
						&::before {
							font-weight: $font-weight-normal;
							font-size: 1.4rem;
						}
					}
				}
				&__page-links {
					.header__page--main-link {
						background-color: $color-gray-light;
						.header__page-bottom-border {
							display: flex;
							justify-content: space-between;
						}
					}
				}
			}
		}
	}
}
