﻿.contacts {
	&__inner {
		background: $color-white;
		display: block;

		@include bp(lg) {
			padding: $component-padding-lg;
		}
		@include bp(xl) {
			padding: $component-padding-xl;
		}
		@include bp(xxl) {
			padding: $component-padding-xxl;
		}
	}

	&__headline {
		padding: $spacing-medium;
		padding-top: $spacing-default * 1.25;
		padding-bottom: 0;
		margin-bottom: 0;
		@include bp(lg) {
			padding: 0;
			padding-bottom: $spacing-default * 2.5;
		}
	}

	&__columns {
		padding: $spacing-medium;
		max-width: 100%;
		@include bp(lg) {
			padding: 0;
		}
	}

	&__item {
		&:not(:first-child) {
			margin-top: $spacing-default;

			@include bp(lg) {
				margin-top: 0;

				&:nth-child(n + 3) {
					margin-top: $spacing-default;
				}
			}
		}
	}

	.employee-card {
		&__content {
			margin-top: 0;
			margin-bottom: 0;
			padding-top: $spacing-small;
			padding-left: 0;
			padding-right: 0;

			@include bp(sm) {
				padding: 0 $spacing-default;
			}
		}
		&--no-image {
			.employee-card__content {
				padding-left: 0;
			}
		}
	}
}
