// @function calculateRem($size) {
// 	$remSize: $size / $font-size-base;
// 	@return #{$remSize}rem;
// }

@mixin focus($offset: 3px) {
	outline: 2px dashed currentColor !important;
	outline-offset: $offset;
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}
@mixin fontSize($size) {
	font-size: $size; //Fallback in px
	// font-size: calculateRem($size);
}

@mixin delay($rule, $number, $value) {
	@for $i from 1 to ($number + 1) {
		&:nth-child(#{$i}) {
			#{$rule}-delay: (#{$i * $value});
		}
	}
}
@mixin bp($breakpoint) {
	$query: map-get($grid-breakpoints, $breakpoint);
	@if $query != null {
		@media ($gl-mq-width: #{nth($query, 1)}) {
			@content;
		}
	}
}
@mixin bp-max($breakpoint) {
	$query: map-get($grid-breakpoints, $breakpoint);
	@if $query != null {
		@media (max-width: #{nth($query, 1) - 1}) {
			@content;
		}
	}
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	} @else {
		@content;
	}
}

@mixin ie() {
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		@content;
	}
}

@mixin custom-spaces($marginLeft, $marginTop: unset, $width) {
	margin-left: $marginLeft;
	margin-top: $marginTop;
	width: $width;
}

@mixin ellipsis-text($height, $lineClamp) {
	display: -webkit-box; //needed for 'ellipsis'
	max-width: 100%;
	height: $height;
	-webkit-line-clamp: $lineClamp; //needed for 'ellipsis'
	-webkit-box-orient: vertical; //needed for 'ellipsis'
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin expand-collapse($color) {
	position: relative;
	&:before,
	&:after {
		background: $color;
		content: "";
		height: 2px;
		left: -4px;
		position: absolute;
		top: -1px;
		width: 10px;
		transition: transform 500ms ease;
	}
	&:after {
		transform-origin: center;
		transform: rotate(90deg);
	}
	&:before {
		transform: rotate(180deg);
	}
}

@mixin content-space($desktop: 40px, $mobile: 30px) {
	margin-top: $mobile;
	margin-bottom: $mobile;

	@include bp(lg) {
		margin-top: $desktop;
		margin-bottom: $desktop;
	}
}

@mixin content-space-padding($desktop: 20px, $mobile: 20px) {
	padding-top: $mobile;
	padding-bottom: $mobile;

	@include bp(lg) {
		padding-top: $desktop;
		padding-bottom: $desktop;
	}
}

@mixin col-with-parent-size($columns, $parent-columns) {
	flex: 0 0 calc(#{$columns} / #{$parent-columns} * 100%);
	max-width: calc(#{$columns} / #{$parent-columns} * 100%);
}

@mixin left-background-overflow($bg-color: inherit) {
	position: relative;

	&:before {
		content: "";
		width: 10000px;
		height: 100%;
		background-color: $bg-color;
		top: 0;
		left: -10000px;
		position: absolute;
		display: block;
	}
}
