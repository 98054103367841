$box-shadow-btn: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
	0 6px 20px 0 rgba(0, 0, 0, 0.19);

$box-shadow-active: 0 4px 4px 0 rgba(0, 0, 0, 0.2),
	0 6px 16px 0 rgba(0, 0, 0, 0.19);

.btn {
	position: relative;
	display: inline-block;
	border-radius: 2em;
	padding: 1.17em 1.29em 1.16em 1.5em;
	font-weight: $font-weight-bold;
	font-size: $font-size-h5;
	box-shadow: $box-shadow-btn;
	text-decoration: none;
	cursor: pointer;
	border: none;
	line-height: $line-height-default;
	vertical-align: middle;
	min-width: 100px;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&.focus-visible,
	&:focus-visible {
		@include focus(-2px);
	}

	&:active {
		transform: translateY(4px);
		transition: transform $animation-duration-default
			$animation-easing-default;
		box-shadow: $box-shadow-active;
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&--filter {
		position: relative;
		text-align: left;
		padding-left: 1.5em;
		padding-right: 3.5em;
		font-size: $font-size-base;

		&:before {
			position: absolute;
			top: 50%;
			right: 0;
			transform: translate3d(0, -50%, 0);
			font-family: $font-family-default;
			content: "";
			font-weight: $font-weight-regular;
			padding-left: 1em;
			padding-right: 1.5em;
			height: 24px;
			width: 24px;
			background-repeat: no-repeat;
			background-image: url("data:image/svg+xml,	%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' %3E%3Cpath fill='currentColor' d='M504 384H192v-40c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h88v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h312c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-344 64h-32v-96h32v96zM504 96H256V56c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h152v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h248c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-280 64h-32V64h32v96zm280 80h-88v-40c0-13.3-10.7-24-24-24h-48c-13.3 0-24 10.7-24 24v40H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h312v40c0 13.3 10.7 24 24 24h48c13.3 0 24-10.7 24-24v-40h88c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm-120 64h-32v-96h32v96z' /%3E%3C/svg%3E");
		}
	}

	&--link {
		min-width: auto;
		background-color: transparent;
		border: none;
		box-shadow: none;
		text-decoration: underline;
		padding: 0;
		border-radius: 0;
		&:hover {
			color: $color-dark-blue;
		}
	}

	&--circle {
		background-color: $color-white;
		min-width: auto;
		border-radius: $border-radius-circle;
		font-weight: $font-weight-bold;
		display: flex;
		align-items: center;
		justify-content: center;
		width: $spacing-default * 2;
		height: $spacing-default * 2;
		text-decoration: none;
		box-shadow: none;
		padding: 0;

		&:before {
			right: 0;
			padding: 0 0.8rem;
		}

		@include bp(lg) {
			width: $spacing-default * 2.25;
			height: $spacing-default * 2.25;
		}

		i {
			display: unset;
			transform: translate3d(1px, 0, 0);
		}
		svg {
			fill: currentColor;
		}
	}

	&--small {
		width: 35px;
		height: 35px;

		svg {
			width: 9px;
		}
	}
	&--medium {
		width: $spacing-default * 2;
		height: $spacing-default * 2;
		i {
			font-size: $font-size-icon;
			&:before {
				font-weight: normal;
			}
		}
	}

	&--primary {
		background-color: $color-black;
		color: $color-white;
		border-color: $color-black;

		&:hover,
		&:focus {
			color: $color-white;
		}
	}

	&--secondary {
		background-color: $color-white;
		color: $color-black;
		border-color: $color-white;

		&:hover,
		&:focus {
			color: $color-black;
		}
	}

	&--tertiary {
		background-color: $color-dark-blue;
		color: $color-white;
		border-color: $color-dark-blue;

		&:hover,
		&:focus {
			color: $color-white;
		}
	}

	&--light-blue {
		background-color: $color-light-blue;
		color: $color-black;
		border-color: $color-light-blue;

		&:hover,
		&:focus {
			color: $color-black;
		}
	}

	&--border {
		border-width: 2px;
		border-style: solid;
		background-color: $color-white;
		color: $color-black;

		&:hover,
		&:focus {
			color: $color-black;
		}
	}

	&--void {
		background: transparent;
	}

	&--arrow {
		position: relative;
		padding-right: calc(4rem + 1.29em);

		&:before {
			position: absolute;
			top: 50%;
			right: 0.5rem;
			transform: translate3d(0, -50%, 0);
			font-family: $font-family-default;
			content: "→";
			font-weight: $font-weight-regular;
			padding-left: 1rem;
			padding-right: 1rem;
			transition: right $animation-duration-default * 3
				$animation-easing-default;
			font-size: $font-size-h3;
		}

		&:after {
			content: "";
		}

		&:hover,
		&:focus {
			&:before {
				right: 0;
			}
		}
	}

	&--small-font {
		font-weight: $font-weight-light;
		font-size: $font-size-base;
	}

	&--bold {
		font-weight: $font-weight-bold;
	}

	&--plus,
	&--minus {
		min-width: 75px;
		padding-right: 2.9rem;
		text-align: left;

		&:before {
			position: absolute;
			content: "+";
			top: 50%;
			right: 0;
			transform: translate3d(0, -50%, 0);
			padding-left: 1rem;
			padding-right: 1rem;
			font-size: 1.4rem;
		}
	}
	&--minus {
		&:before {
			content: "-";
		}
	}

	&--xs-fluid {
		box-sizing: border-box;
		width: 100%;

		@include bp(sm) {
			width: auto;
		}
	}
	&--xs-hide {
		display: inline-block;

		@include bp(sm) {
			display: none;
		}
	}
	&--xs-show {
		display: none;

		@include bp(sm) {
			display: inline-block;
		}
	}

	&__value {
		position: absolute;
		top: -1.1em;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 13px;
		border-radius: 50%;
		background-color: $color-light-blue;
		width: 2.2em;
		height: 2.2em;
	}
}

.btn-reset {
	border: 0;
	padding: 0;
	background: transparent;
	box-sizing: content-box;
}

.icon-link {
	display: flex;
	align-items: flex-start;
	margin-right: $spacing-default * 2.25;
	word-break: break-word;

	.icon {
		display: inline-block;
		width: auto;
		margin-right: $spacing-default * 0.75;
		transition: transform 0.2s $animation-easing-default;

		.safari & {
			line-height: 0;
		}

		svg {
			$svg-size: 16px;
			width: $svg-size;
			height: $svg-size;
			fill: currentColor;
			transform: translateY(-1px);
		}
	}

	&:hover {
		.icon {
			transform: translateX(8px);
		}
	}
}

@keyframes arrowBounce {
	0% {
		right: 0.5rem;
	}
	50% {
		right: 0;
	}
	100% {
		right: 0.5rem;
	}
}

@media (prefers-reduced-motion: no-preference) {
	.btn--arrow.btn--primary.inview-btn,
	.richtext .btn-primary-arrow {
		&.inview--visible {
			&::before {
				animation: arrowBounce $animation-duration-default * 6
					$animation-easing-default;
			}
		}
	}
}
