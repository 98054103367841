.image-gallery {
	&-image {
		cursor: pointer;
		margin-bottom: $grid-gutter-width;

		.umbraco-image-module {
			padding-bottom: 100%;
			height: 0;
			overflow: hidden;
		}

		img {
			width: 100%;
			height: auto;
			transition: transform 250ms $animation-easing-default;
		}

		&:hover {
			img {
				transform: scale(1.03);
			}
		}

		&__link {
			display: block;
			&:focus-visible {
				@include focus;
			}
		}
	}

	&__inner {
		background: $color-white;
		@include bp(lg) {
			padding: $component-padding-lg;
		}
		@include bp(xl) {
			padding: $component-padding-xl;
		}
		@include bp(xxl) {
			padding: $component-padding-xxl;
		}
	}

	&__headline {
		padding: $spacing-medium;
		padding-top: $spacing-default * 1.25;
		padding-bottom: 0;
		margin-bottom: 0;
		@include bp(lg) {
			padding: 0;
			padding-bottom: $spacing-default * 2.5;
		}
	}

	&__columns {
		padding: $spacing-medium;
		max-width: 100%;
		width: 100%;
		margin-bottom: -$grid-gutter-width;
		@include bp(lg) {
			padding: 0;
		}
	}

	.modal-box {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: $z-index-dialog + 1;

		.overlay {
			position: absolute;
			height: 100%;
			width: 100%;
			top: 0;
			left: 0;
			background-color: $color-light-blue;
			z-index: $z-index-overlay;
		}

		&--image {
			&-button {
				position: absolute;
				top: 50%;
				box-shadow: $box-shadow-btn;
				padding: 0;

				&::before {
					right: 50%;
					transform: translateX(50%) translateY(-50%);
				}

				&:hover,
				&:focus {
					opacity: 0.8;
					&::before {
						right: 50%;
					}
				}
			}
			&-close {
				right: 0;
				top: 0;
				margin: 0;
				position: absolute;
			}
			.left {
				left: -$spacing-default;
				transform: translate3d(100%, -50%, 0);
				&::before {
					content: "←";
				}
			}

			.right {
				right: -$spacing-default;
				transform: translate3d(-100%, -50%, 0);
			}

			background: $color-white;
			position: absolute;
			width: 700px;
			max-height: 90%;
			max-width: 90%;
			z-index: $z-index-dialog;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			box-shadow: $box-shadow-base;
			padding: $spacing-default/2;

			@include bp(lg) {
				padding: $spacing-medium/2;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				max-height: calc(90vh - $spacing-default * 4);
			}
		}

		opacity: 0;
		transform: translateY(-120%);
		transition: none;

		&.loaded {
			transition: opacity 0.33s ease-out;
		}

		&.visible {
			opacity: 1;
			transform: translateY(0);
		}
		&:not(.visible) {
			display: none;
		}
	}
}
