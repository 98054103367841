﻿.no-padding {
	padding: 0;
}

.no-padding-sm {
	padding-left: 0;
	padding-right: 0;
	@include bp(lg) {
		padding-left: $spacing-default * 0.75;
		padding-right: $spacing-default * 0.75;
	}
}
.no-padding-xxs {
	padding: 0;
	@include bp(lg) {
		padding-left: $spacing-default * 0.75;
		padding-right: $spacing-default * 0.75;
	}
}
.no-padding-lg {
	padding-left: $spacing-default * 0.75;
	padding-right: $spacing-default * 0.75;
	@include bp(lg) {
		padding-left: 0;
		padding-right: 0;
	}
}
.no-padding-xl {
	padding-left: $spacing-default * 0.75;
	padding-right: $spacing-default * 0.75;
	@include bp(xl) {
		padding-left: 0;
		padding-right: 0;
	}
}

.padding-sm {
	padding-left: $spacing-default * 0.75;
	padding-right: $spacing-default * 0.75;
	@include bp(lg) {
		padding-left: 0;
		padding-right: 0;
	}
}
