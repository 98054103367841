﻿$block: "employee-card";

.#{$block} {
	background: $color-white;
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include bp(sm) {
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
	}

	&__content {
		display: inline-block;
		padding: $spacing-small * 1.5 $spacing-small * 2.5 $spacing-small * 2.5
			$spacing-small * 2.5;
		flex: 1;

		@include bp(sm) {
			margin-top: $spacing-small * 2.5;
			margin-bottom: $spacing-small * 3;
			padding: 0 $spacing-default;
			padding-right: $spacing-default - 3px; // -3px because otherwise the last letter of "Servicecentermedarbejder" in a employee-card__position wraps down on a new line
			width: 100%;
		}
	}

	&__image {
		min-width: 125px;

		@include bp(sm) {
			min-width: 160px;
		}

		@include bp(md) {
			min-width: 195px;
		}
	}

	&__image-trigger {
		overflow: hidden;
		img {
			transition: transform 250ms $animation-easing-default;
		}

		&:hover,
		&:focus,
		&:active {
			img {
				transform: scale(1.03);
			}
		}
	}

	&__label {
		width: 65px;
		flex: 0 0 auto;
	}

	&__name {
		font-weight: $font-weight-bold;
		margin: 0 !important;
		font-size: $font-size-base * 1.5;
	}

	&__name-trigger {
		text-align: left;
		&:hover,
		&:focus,
		&:active {
			color: $color-dark-blue;
		}

		&:focus-visible {
			@include focus;
		}
	}

	&__position {
		font-weight: $font-weight-bold;
		font-size: $font-size-base * 1.1;
		margin: 0 !important;
		word-break: break-word;
	}
	&__department {
		margin: 0 !important;
		word-break: break-word;
		display: none; // default hide it, as it's only shown on the employeeSearch module
	}

	&__open-hours-title {
		font-weight: $font-weight-bold;
		margin: 0 !important;
	}

	&__open-hours {
		display: none;
		@include bp(sm) {
			display: block;
		}
	}

	&__text {
		color: $color-dark-blue;
		font-weight: $font-weight-bold;
		width: 100%;
		@include bp(sm) {
			margin-left: $spacing-default;
		}
	}

	&__contact {
		margin: $spacing-default 0;
	}

	&__contact-item {
		display: flex;
		flex-direction: row;
	}

	&--large {
		flex-direction: column;
		justify-content: center;

		@include bp(md) {
			flex-direction: row;
		}
		.#{$block} {
			&__image {
				min-width: 175px;
				@include bp(xl) {
					min-width: 275px;
				}
			}

			&__content {
				margin-top: $spacing-small;
				margin-bottom: $spacing-small;
				padding-left: $spacing-small;

				@include bp(md) {
					padding-left: $spacing-default * 3;
				}
			}
		}
	}

	&__more-trigger {
		color: $color-dark-blue;
		font-weight: $font-weight-bold;
		margin-top: $spacing-default;

		&:hover,
		&:focus-visible {
			color: inherit;
		}
	}
	.richtext {
		margin-top: $spacing-default;
	}
}

.employee-search-module {
	.#{$block} {
		&__position {
			font-weight: inherit;
			font-size: inherit;
		}
		&__department {
			display: block;
		}
	}
}
