.ul--nostyling {
	list-style-type: none;
	padding-left: 0;
	// margin: 0;
	margin-top: 0;
	> li:before {
		content: none !important;
	}
}
.ul--inline {
	display: inline-flex;
	li {
		margin-right: 10px;
		&:last-child {
			margin-right: 0;
		}
	}
}

.hidden {
	display: none;
}
[class*="grid-"][class*="-center--large"] {
	justify-content: initial;
}

.horizontal-divider {
	display: inline-block;
	width: 1px;
	height: 1em;
	background-color: currentColor;
	position: relative;
	margin: 0 8px;
}

.dont-break-out {
	// /* These are technically the same, but use both */
	overflow-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	// -ms-hyphens: auto;
	// -moz-hyphens: auto;
	// -webkit-hyphens: auto;
	// hyphens: auto;
}

.d {
	&-block {
		display: block !important;
	}
	&-inline-block {
		display: inline-block !important;
	}
	&-flex {
		display: flex !important;
	}
	&-inline-flex {
		display: inline-flex !important;
	}
	&-inline {
		display: inline !important;
	}
}

@media print {
	.d-print-none {
		display: none !important;
	}
}

.no-background {
	background: none !important;
}

.d {
	&-block {
		display: block !important;
	}
	&-inline-block {
		display: inline-block !important;
	}
	&-flex {
		display: flex !important;
	}
	&-inline-flex {
		display: inline-flex !important;
	}
	&-inline {
		display: inline !important;
	}
}

.vw {
	&-100 {
		min-width: 100vw !important;
	}
}
.no-gutters {
	margin-right: 0;
	margin-left: 0;

	> .col,
	> [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}

.opacity {
	&-70 {
		opacity: 0.7;
	}
}

.screen-reader-text,
.sr-only,
.sr-text {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important;
}

.clearfix {
	&::after {
		display: block;
		clear: both;
		content: "";
	}
}

.divider {
	margin: 0 2px;

	&:after {
		content: "|";
	}
}

.text {
	&-bold {
		font-weight: $font-weight-bold;
	}
	&-uc {
		text-transform: uppercase;
	}
}
