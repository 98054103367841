﻿$block: "page-header";

@mixin page-header-theme($bg-color, $color) {
	margin-top: 0;
	margin-bottom: 0;

	.#{$block} {
		&__background {
			background-color: $bg-color;
			color: $color;

			&:before {
				background-color: $bg-color;
			}
		}
	}
}

.#{$block} {
	p {
		margin: 0;
	}

	&__background {
		@include left-background-overflow();
		padding-top: $spacing-medium;
		padding-bottom: $spacing-default;
		padding-left: $spacing-medium;
		padding-right: $spacing-medium;

		@include bp(lg) {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}
	}

	&__col {
		@include bp(lg) {
			@include col-with-parent-size(8, 10);
		}
	}

	&__teaser {
		font-size: $font-size-manchet;
	}

	&--themeBlueLight {
		@include page-header-theme($color-light-blue, $color-black);
	}
	&--themeBlueDark {
		@include page-header-theme($color-dark-blue, $color-white);
	}
	&--themeGrayLight {
		@include page-header-theme($color-gray-light, $color-black);
	}
	&--themeWhite {
		@include page-header-theme($color-white, $color-black);
	}
	&--themeYellow {
		@include page-header-theme($color-yellow, $color-black);
	}
	&--themeOrange {
		@include page-header-theme($color-orange, $color-black);
	}
	&--themeBlue {
		@include page-header-theme($color-blue, $color-white);
	}
	&--themePink {
		@include page-header-theme($color-pink, $color-black);
	}
	&--themeRed {
		@include page-header-theme($color-red, $color-black);
	}
	&--themeTurquoise {
		@include page-header-theme($color-turquoise, $color-black);
	}
	&--themeTurquoiseLight {
		@include page-header-theme($color-turquoise-light, $color-black);
	}
	&--themeDefault {
		@include page-header-theme($color-background, $color-black);
	}
}
