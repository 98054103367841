﻿.to-top {
	padding: $spacing-default;
	height: $spacing-large * 2;
	display: flex;
	justify-content: flex-end;

	@include bp(lg) {
		padding: $spacing-medium;
		height: $spacing-large * 4;
	}
	&__icon {
		.icon {
			justify-content: flex-end;
			svg {
				$svg-size: 25px;
				width: $svg-size;
				height: $svg-size;
				transform: rotate(-90deg);
				transition: fill 0.2s ease;
				fill: $color-dark-blue;

				@include bp(lg) {
					$svg-size: 35px;
					width: $svg-size;
					height: $svg-size;
				}
			}
		}

		&:hover {
			svg {
				fill: $color-black;
			}
		}
		&:focus-visible {
			@include focus;
		}
	}
}
