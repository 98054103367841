.video-playlist {
	&__inner {
		@include bp(lg) {
			padding: $spacing-default * 3.5 $spacing-default * 3.25
				$spacing-default * 1.5;
		}
		@include bp(xl) {
			padding: $spacing-default * 3.5 $spacing-default * 4
				$spacing-default * 1.5;
		}
		@include bp(xxl) {
			padding: $spacing-default * 3.5 $spacing-default * 4.75
				$spacing-default * 1.5;
		}
	}

	&__headline {
		padding-left: $spacing-medium;
		padding-right: $spacing-medium;
		padding-top: $spacing-default * 1.25;
		padding-bottom: $spacing-default * 1.25;
		margin-bottom: 0;
		width: 100%;

		@include bp(lg) {
			padding: 0;
			padding-bottom: $spacing-default * 2.5;
			margin-top: -$spacing-default;
		}

		+ .video-playlist__gallery-top {
			@include bp-max(lg) {
				margin-top: -$spacing-default * 2.25;
			}
		}

		+ .video-playlist__single {
			@include bp-max(lg) {
				margin-top: -$spacing-default * 2;
			}
		}
	}

	&__item-inner {
		position: relative;
		background-size: cover;
		background-position: center;
	}
	&__item-content {
		padding: $spacing-default * 0.75 $spacing-default $spacing-default;
	}
	&__item-headline {
		display: block;
		@include bp(md) {
			font-size: $font-size-small;
			min-height: 2.35em; // 2 lines of text + line-height
		}
	}
	&__tags {
		display: flex;
		font-size: 0.75rem;
		margin-bottom: $spacing-default * 0.5;
		white-space: nowrap;
		@include bp(md) {
			font-size: $font-size-xs;
		}
	}
	&__divider {
		position: relative;
		display: inline-block;
		padding-left: $spacing-default * 0.75;
		text-overflow: ellipsis;
		overflow: hidden;
		&::before {
			content: "";
			position: absolute;
			width: 1px;
			height: $spacing-default/2;
			bottom: $spacing-default/4;
			left: $spacing-default * 0.35;
			background-color: $color-black;
		}
	}

	//START ovrride video playlist play button
	&__play {
		&:after {
			background-color: $color-play-red;
		}
	}
	//END ovrride video playlist play button

	// override swipper style START
	&__swiper-button-prev,
	&__swiper-button-next {
		top: 98% !important;
		color: $color-black !important;
		z-index: 15 !important;
		&::after {
			display: inline-block;
			margin-top: $spacing-small * 0.7;
			font-size: 0.8em !important;
			font-weight: bold;
		}
	}
	&__swiper-button-next {
		right: 45% !important;
	}
	&__swiper-button-prev {
		left: 45% !important;
	}
	&__swiper-pagination {
		top: 97% !important;

		//override swiper style
		.swiper-pagination-current {
			left: 0 !important;
		}

		@include bp(lg) {
			bottom: 0 !important;
			width: 12% !important;
			left: 43% !important;
			top: 94% !important;
			background: $color-white;
			border-radius: $border-radius-large;
			padding: $spacing-default * 0.8;
			display: flex;
			align-items: center;
			justify-content: center;
			color: $color-black;

			.swiper-pagination-current {
				font-weight: normal !important;
			}
		}
	}
	.swiper-slide {
		height: 100% !important;
	}
	&__swiper-scrollbar {
		position: relative !important;
	}

	//shared video file
	.videoplayer__wrapper {
		iframe {
			height: $spacing-default * 9;
		}
	}

	&__gallery-top {
		height: 80%;
		width: 100%;
		margin-bottom: $spacing-default * 1.5;
		display: flex; //ios
		flex-direction: column;
		@include bp(lg) {
			padding-bottom: $spacing-default * 4 !important;
			margin-bottom: 0;
			display: block;
		}

		//shared video file
		.videoplayer {
			&__wrapper,
			&__wrapper--local {
				height: 0;
				padding-top: $ratio-16x9 !important;
				position: relative;
				background-color: $color-black;
				iframe,
				video {
					position: absolute;
					top: 0;
					height: 100%;
					left: 0;
					width: 100%;
					//object-fit: cover;
					box-shadow: $box-shadow-slider;
				}
			}
		}
		.video-playlist {
			&__cover-img {
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
				left: 0;
				top: 0;
				z-index: 9;

				picture {
					will-change: transform; // Sub-pixel rendering issue
					overflow: hidden;
				}

				img {
					width: 100%;
					height: 100%;
					transform: scale(1.01);
				}

				.umbraco-image {
					> div:not(.umbraco-image__bg) {
						height: 100%;
					}
				}

				.umbraco-image-module {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	&__gallery-top-inner {
		margin-bottom: $spacing-default/2;
		padding: $spacing-default * 2.5 0 $spacing-default/2;
		@include bp(lg) {
			margin-bottom: $spacing-small * 2.5;
			padding: 0;

			.video-playlist__item-inner {
				position: relative;
				height: 0;
				padding-top: $ratio-16x9;
			}
			.video-thumbs,
			.videoplayer {
				position: absolute;
				inset: 0;
			}
		}
		.video-thumbs__container {
			@include bp-max(lg) {
				position: absolute;
				inset: 0;
			}
		}
		.video-playlist {
			&__item-content {
				cursor: pointer;
				padding: 0 $spacing-medium;
				@include bp(lg) {
					padding: 0;
				}
			}
			&__cover-img {
				padding: 0 $spacing-medium;
				@include bp(lg) {
					padding: 0;
				}
			}
		}
	}
	&__gallery-thumbs {
		box-sizing: border-box;
		padding: $spacing-default/4 $spacing-medium $spacing-medium !important;
		width: 100%;

		@include bp(lg) {
			padding: 0 0 $spacing-default/2 !important;
			height: 20%;
		}

		//shared video file
		.video-tag,
		iframe {
			pointer-events: none;
		}

		//shared video file
		video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.video-playlist__cover-img + .videoplayer {
			.videoplayer__wrapper,
			.videoplayer__wrapper--local {
				box-shadow: $box-shadow-slider;
				video,
				iframe {
					opacity: 0;
				}
			}
		}

		.video-playlist {
			&__cover-img {
				height: 0;
				padding-top: $ratio-16x9;

				.umbraco-image {
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
				}
				img {
					width: 100%;
					height: 100%;
				}
			}
			&__item-inner {
				box-shadow: $box-shadow-slider;
				&.swiper-slide-thumb-active {
					background-color: unset !important;
					box-shadow: unset;
				}
			}
			&__item-inner {
				cursor: pointer;
				opacity: 0.4;
				width: 75%;
				&.swiper-slide-thumb-active {
					opacity: 1;
				}
				@include bp(lg) {
					width: auto;
				}

				&:hover {
					.video-playlist__item-headline {
						text-decoration: underline;
					}
				}
			}
		}
	}
	// override swipper style END

	&__single {
		width: 100%;
		padding: 0;
		padding-bottom: $spacing-default * 2;

		@include bp-max(lg) {
			padding-top: $spacing-default * 2;
		}

		.video-playlist__item-content {
			padding: 0 $spacing-medium;
			@include bp(lg) {
				padding: 0;
			}
		}

		//shared video file
		.videoplayer {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			&__wrapper,
			&__wrapper--local {
				height: 0;
				padding-top: $ratio-16x9;
				position: relative;
				background-color: $color-black;
				iframe,
				video {
					position: absolute;
					top: 0;
					height: 100%;
					left: 0;
					width: 100%;
					//object-fit: cover;
					box-shadow: $box-shadow-slider;
				}
			}
		}

		picture {
			will-change: transform; // Sub-pixel rendering issue
		}

		.video-thumbs {
			height: 0;
			padding-top: $ratio-16x9;
			position: relative;
		}
	}
}
