$block: "accordion";

@mixin accordion-theme($color, $font-color, $btn-bg, $btn-color) {
	.#{$block}__item {
		// background theme color should only apply to large accordions, and unexpanded normal accordions.
		// on expanded normal accordions, the background should be white
		&:not(.#{$block}__item--expanded),
		&.#{$block}__item--large {
			background-color: $color;
			color: $font-color;
		}
	}

	.#{$block}__trigger {
		color: $font-color;
	}

	.#{$block}__icon--large {
		background-color: $btn-bg !important;

		.#{$block}__icon-collapsed {
			&::before,
			&::after {
				background-color: $btn-color !important;
			}
		}
	}
	.#{$block}__icon:not(.#{$block}__icon--large) {
		.#{$block}__icon-collapsed {
			&::before,
			&::after {
				background-color: $font-color !important;
			}
		}
	}
}

.#{$block} {
	padding-left: $spacing-default * 0.75;
	padding-right: $spacing-default * 0.75;
	@include bp(lg) {
		padding-left: 0;
		padding-right: 0;
	}
	&__title {
		margin-bottom: $spacing-medium;

		&.h3 {
			// same as RTE h3
			font-size: 1.4rem;
			@include bp(md) {
				font-size: 1.67rem;
			}

			margin-bottom: $spacing-medium;
		}

		&.h2 {
			// same as RTE h2
			@include bp-max(md) {
				font-size: 1.7rem;
			}
		}
	}

	&__icon-collapsed {
		transition: transform $animation-duration-default
				$animation-duration-default,
			max-height $animation-duration-default $animation-duration-default,
			background-color $animation-duration-default
				$animation-duration-default;
		transform-origin: 50% 50%;
		@include expand-collapse($color-white);
		@include bp(lg) {
			&::before,
			&::after {
				height: 3px;
				width: 11px;
				left: -5px;
			}
		}
	}

	&__icon:not(.#{$block}__icon--large) {
		background-color: transparent;

		.#{$block}__icon-collapsed {
			&::before,
			&::after {
				height: 3px;
				width: 14px;
				left: -7px;
				background: currentColor;
			}
			@include bp(lg) {
				&::before,
				&::after {
					width: 15px;
					left: -7.5px;
				}
			}
		}
	}
	@mixin accordionExpanded() {
		.accordion__header {
			color: $color-black;
		}

		.accordion__icon-collapsed {
			&::before,
			&::after {
				background: $color-black;
				transform: rotate(0deg);
			}
		}
		.accordion__content {
			opacity: 1;
			pointer-events: all;
			user-select: auto;
		}
	}

	&__item {
		&.accordion__item--expanded {
			@include accordionExpanded();
		}
	}

	&__trigger {
		width: 100%;
	}

	&__trigger-inner {
		width: 100%;
		margin: 0;
		display: flex;
		text-align: left;
		align-items: center;
		justify-content: space-between;
	}

	&__header {
		padding-bottom: 0;
		padding-left: 0;
		font-size: $font-size-h5--small;
		line-height: $line-height-default;
		transition: color 0.2s ease;
		@include bp(lg) {
			font-size: $font-size-h5;
		}

		&--large {
			font-size: 1.23rem;
			@include bp(lg) {
				font-size: $font-size-medium;
			}
		}
	}

	&__content {
		height: 0;
		opacity: 0;
		overflow: hidden;
		padding-inline: $spacing-default;
		margin-inline: -$spacing-default;
		transition: all $animation-duration-default * 3 ease-in-out;

		&--overflow-visible {
			overflow: visible;
			pointer-events: none;
			user-select: none;
		}
	}

	&__content-inner {
		padding-top: $spacing-small * 3;
		padding-right: $spacing-small * 2;

		&--large {
			@include bp(lg) {
				padding-top: $spacing-small * 5;
			}
		}
	}

	&__icon {
		transition: background-color $animation-duration-default ease;
		font-size: 19px;
	}

	&__media {
		+ .rte {
			margin-top: $spacing-default;
		}
	}

	img {
		max-width: 100%;
		height: auto;
		width: 100%;
	}

	figure {
		margin: 0;
	}

	&__item {
		background: $color-white;
		transition: background-color 0.2s ease;
		border-radius: $border-radius-large;
		margin: 0 0 $spacing-small * 2;
		padding: $spacing-small $spacing-small $spacing-small + 4 $spacing-small *
			3;
		@include bp(lg) {
			margin: 0 0 $spacing-small * 2;
		}

		&--large {
			margin: 0 0 $spacing-small * 2;
			@include bp(lg) {
				margin: 0 0 $spacing-small * 3;
				padding: $spacing-small * 3 $spacing-small * 3 $spacing-small *
					3 $spacing-small * 5;
			}
		}

		&--expanded {
			background-color: $color-white;
			box-shadow: $box-shadow-base;

			.accordion__trigger {
				color: $color-black;

				.cta-btn-circle {
					background-color: $color-dark-blue;

					svg {
						fill: $color-white;
					}
				}
			}

			.accordion__cta {
				opacity: 1;
				transition: transform 325ms ease 200ms, opacity 100ms ease 200ms;
				@include bp(lg) {
					transform: translate3d(100px, 0, 0);
				}

				.btn:disabled {
					opacity: 1;
					background-color: #747677; // emulate disabled primary button opaque color
				}
			}

			.accordion__header {
				font-weight: 700;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}

		h3 {
			line-height: inherit;
		}
	}

	&__trigger {
		color: $color-black;
		cursor: pointer;
	}

	&__toggle {
		@extend .accordion__item;
		border-top: 0;

		.accordion__toggle-header {
			padding: 0 $spacing-medium/2 0 0;
		}

		.accordion__toggle--close {
			display: none;
		}

		&.accordion__toggle--expanded {
			@include accordionExpanded();

			.accordion__toggle--close {
				display: inline;
			}

			.accordion__toggle--open {
				display: none;
			}
		}
	}

	&__tags-wrap {
		@include bp(lg) {
			margin-top: $spacing-small;
		}
	}

	&__cta-wrap {
		display: flex;
		flex-direction: column;
	}

	&__cta {
		display: flex;
		justify-content: flex-end;
		transform: translate3d(0, 0, 0);
		opacity: 0;
		transition: transform 200ms ease 0ms, opacity 100ms ease 0ms;
		margin: $spacing-small * 4 0 $spacing-small 0;
	}

	&--themeBlueLight {
		@include accordion-theme(
			$color-light-blue,
			$color-black,
			$color-black,
			$color-white
		);
	}
	// NOTE: default theme is the same as white for accordions
	&--themeWhite,
	&--themeDefault {
		@include accordion-theme(
			$color-white,
			$color-black,
			$color-dark-blue,
			$color-white
		);
	}
	// NOTE: currently we only allow content editors to create accordions as white or blueLight themes, so no reason to include bundled css for all the other color themes
	// &--themeBlueDark {
	// 	@include accordion-theme($color-dark-blue,$color-white,$color-white,$color-dark-blue);
	// }
	// &--themeGrayLight {
	// 	@include accordion-theme($color-gray-light,$color-black,$color-dark-blue,$color-white);
	// }
	// &--themeYellow {
	// 	@include accordion-theme($color-yellow,$color-black,$color-dark-blue,$color-white);
	// }
	// &--themeOrange {
	// 	@include accordion-theme($color-orange,$color-black,$color-dark-blue,$color-white);
	// }
	// &--themeBlue {
	// 	@include accordion-theme($color-blue,$color-white,$color-white,$color-blue);
	// }
	// &--themePink {
	// 	@include accordion-theme($color-pink,$color-black,$color-dark-blue,$color-white);
	// }
	// &--themeRed {
	// 	@include accordion-theme($color-red,$color-black,$color-dark-blue,$color-white);
	// }
	// &--themeTurquoise {
	// 	@include accordion-theme($color-turquoise,$color-black,$color-dark-blue,$color-white);
	// }
	// &--themeTurquoiseLight {
	// 	@include accordion-theme($color-turquoise-light,$color-black,$color-dark-blue,$color-white);
	// }
}

.registration-accordions-col {
	@include bp(lg) {
		@include col-with-parent-size(8, 10);
	}

	.accordion {
		margin-left: -$grid-gutter-width / 2;
		margin-right: -$grid-gutter-width / 2;

		@include bp(lg) {
			margin-left: -$grid-gutter-width - $spacing-default;
			margin-right: -$grid-gutter-width - $spacing-default;
		}
	}

	.info-box__content {
		margin-left: -$grid-gutter-width / 2;
		margin-right: -$grid-gutter-width / 2;

		@include bp(lg) {
			margin-left: -$grid-gutter-width;
			margin-right: -$grid-gutter-width;
		}
	}
}
