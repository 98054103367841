@mixin gradient-animation-theme($gradient-color) {
	&::before {
		background-image: linear-gradient(
			to left,
			rgba($gradient-color, 1),
			rgba($gradient-color, 0) 100%
		);
	}
}

.inview-gradient {
	position: absolute;
	inset: 0;
	overflow: hidden;
	pointer-events: none;
	display: flex;
	justify-content: flex-end;

	&::before {
		content: "";
		background-image: linear-gradient(
			to left,
			rgba($color-black, 1),
			rgba($color-black, 0) 100%
		);
		display: block;
		width: 25%;
		height: 100%;
		transform: translateX(100%);
	}

	&.inview--visible {
		&::before {
			transform: translateX(0);
		}
	}

	&--on-media {
		z-index: 10;
	}

	&--themeBlueLight {
		@include gradient-animation-theme(#a5dccd);
	}
	&--themeBlueDark {
		@include gradient-animation-theme(#00646e);
	}
	&--themeGrayLight {
		@include gradient-animation-theme(#bed6db);
	}
	&--themeWhite {
		@include gradient-animation-theme(#e7ebed);
	}
	&--themeYellow {
		@include gradient-animation-theme(#ffc84b);
	}
	&--themeOrange {
		@include gradient-animation-theme(#ff6e3c);
	}
	&--themeBlue {
		@include gradient-animation-theme(#004250);
	}
	&--themePink {
		@include gradient-animation-theme(#e15096);
	}
	&--themeRed {
		@include gradient-animation-theme(#fa3746);
	}
	&--themeTurquoise {
		@include gradient-animation-theme(#a5dccd);
	}
	&--themeTurquoiseLight {
		@include gradient-animation-theme(#0096a0);
	}
	&--themeDefault {
		@include gradient-animation-theme(transparent);
	}
}

@media (prefers-reduced-motion: no-preference) {
	.inview-gradient {
		&::before {
			transition: transform 0.45s ease-in-out;
		}
	}
}
