.footer {
	margin-top: $spacing-large;
	@include bp(lg) {
		margin-top: $spacing-large * 2;
	}
	&__info {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: $color-light-blue;
		color: $color-black;
		position: relative;

		padding-bottom: $spacing-default * 2;
		padding-top: $spacing-default;

		@include bp(lg) {
			margin-right: 15px;
			margin-left: -15px;
		}
		@include bp(xl) {
			margin-right: 25px;
			margin-left: -25px;
		}
		@include bp(xxl) {
			margin-right: 35px;
			margin-left: -35px;
		}

		&::before {
			position: absolute;
			content: "";
			height: 100%;
			left: -($spacing-large * 5);
			position: absolute;
			top: 0;
			width: 50vw;
			background: inherit;
			z-index: -1;

			@include bp(lg) {
				left: auto;
				right: -$spacing-default * 5;
			}
		}
	}
	&__logo {
		width: $spacing-default * 4.25;
		height: $spacing-default * 4.25;
		img {
			width: $spacing-default * 4.25;
			height: $spacing-default * 4.25;
			object-fit: unset;
			@include bp(lg) {
				margin-bottom: $spacing-default/2;
			}
		}
	}
	&__locations {
		margin-bottom: $spacing-default;
		text-align: center;
		@include bp(lg) {
			margin-bottom: $spacing-default * 1.5;
		}

		&--link {
			margin: $spacing-small 0;
			line-height: 1.4em;
		}
		a {
			&:hover {
				text-decoration: underline;
			}
		}
	}
	&__contact {
		text-align: center;
		position: relative;
		&::before {
			content: "";
			width: 100%;
			height: 1px;
			background-color: $color-black;
			position: absolute;
			top: -($spacing-default * 0.6);
			left: 0;
		}
		p {
			margin-bottom: $spacing-small;
		}
	}
	&__so-me {
		display: flex;
		justify-content: space-between;
		margin-top: $spacing-medium;
		@include bp(lg) {
			margin-top: $spacing-default * 1.5;
		}
		a {
			display: inline-block;
			&:focus-visible {
				@include focus;
			}
		}
	}
	&__so-me-link {
		padding: 0 $spacing-default/4;

		img {
			width: 30px;
			min-width: 30px;
			height: 30px;
		}
	}
	&__columns {
		background-color: white;
		position: relative;
		margin-left: $spacing-medium / 2;
		padding-bottom: $spacing-default/2;

		&::before {
			background-color: $color-light-blue;
			content: "";
			width: calc(100% - ($spacing-medium * 2));
			height: 100%;
			top: 0;
			position: absolute;
			display: block;
			left: -50%;
			z-index: -1;
		}
		&::after {
			content: "";
			width: $spacing-default * 0.75;
			background-color: $color-white;
			position: absolute;
			top: 0;
			height: 100%;
			left: 100%;
			z-index: -1;
		}

		@include bp(md) {
			height: 100%;
		}
		@include bp(lg) {
			max-width: auto;
			margin-left: 0;
			margin-top: $spacing-default * 3.5;
			padding-bottom: 0;
			padding-right: 69px;
			&::after {
				content: none;
			}
			&::before {
				background-color: $color-light-blue;
				content: "";
				width: calc(100% - (100% / $grid-columns));
				height: 100%;
				top: -($spacing-default * 3.5);
				position: absolute;
				display: block;
				left: -($spacing-default/4);
				z-index: -1;
			}
		}

		@include bp(xl) {
			padding-right: 82px;
		}
		@include bp(xxl) {
			padding-right: 95px;
		}
	}

	&__column {
		margin-right: 0;
		margin-left: 0;
	}
	&__col {
		padding: $spacing-default * 1.5;
		padding-bottom: $spacing-default/2;

		@include bp(xl) {
			padding: $spacing-default * 2.5 $spacing-default * 3;
			padding-right: 0;
			&:last-child {
				padding-right: $spacing-default;
			}
		}
	}
	&__col-headline {
		margin-bottom: $spacing-default * 0.75;
		@include bp(lg) {
			margin-bottom: $spacing-default;
		}
	}
	&__link {
		padding: $spacing-default/4 0;
		&-inner {
			display: flex;
			align-items: flex-start;
			&:hover {
				color: $color-primary;
				.icon {
					svg {
						fill: $color-primary;
					}
					@include animateRight();
				}
			}
		}

		.icon-wrapper {
			display: block;
			line-height: 0;
			transform: translateY(
				3px
			); // magic number for visual alignment of the icon
			@include bp(sm) {
				transform: translateY(
					5px
				); // magic number for visual alignment of the icon
			}

			.safari & {
				transform: translateY(3px);
			}
		}
		.icon {
			display: inline-block;
			width: auto;
			margin-right: $spacing-default/4;

			svg {
				$svg-size: 10px;
				width: $svg-size;
				height: $svg-size;
			}
		}
		&--bottom {
			padding: $spacing-default/4;
			font-size: 0.8rem;
			.icon-wrapper {
				transform: translateY(
					4px
				); // magic number for visual alignment of the icon
				@include bp(sm) {
					transform: translateY(
						5px
					); // magic number for visual alignment of the icon
				}
			}
			.icon {
				svg {
					$svg-size: 8px;
					width: $svg-size;
					height: $svg-size;
					margin-bottom: 3px;
				}
			}
			@include bp(lg) {
				padding: $spacing-default/4 $spacing-default;
			}
		}
	}
	&__bottom {
		background-color: $color-white;
		position: relative;
		max-width: calc(100% - ($spacing-medium / 2));
		margin-left: $spacing-medium / 2;
		margin-top: -1px;
		padding: $spacing-default $spacing-medium - 5px;
		&::before {
			content: "";
			width: calc(100% - ($spacing-medium * 2));
			height: 1px;
			background-color: $color-gray-light;
			position: absolute;
			top: 0;
			left: $spacing-medium;
		}
		&::after {
			content: "";
			width: $spacing-default * 0.75;
			background-color: $color-white;
			position: absolute;
			top: 0;
			height: 100%;
			left: 100%;
		}
		@include bp(md) {
			display: flex;
		}
		@include bp(lg) {
			margin-top: 0;
			padding: $spacing-default 0;
			margin-left: 16.6666666667%;
			&::after {
				content: none;
			}
			&::before {
				content: "";
				width: 80%;
				height: 1px;
				background-color: $color-gray-light;
				position: absolute;
				top: 0;
				left: unset;
			}
		}
	}
	&__bottom-inner {
		@include bp(lg) {
			display: flex;
			justify-content: center;
			width: calc(100% - 16.6666666667%);
		}
	}
}
