$icon-height: 16px;

.tabs__wrapper {
	margin: 0;
	transform: translate3d(0, 50px, 0);
	opacity: 0;
	transition: transform 500ms $animation-easing-default,
		opacity $animation-duration-default ease;
	&.inview--was-visible {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

.tabs {
	background-color: $color-gray-light;
	position: relative;
	z-index: 2;
	@extend .body-text;
	&__item {
		display: flex;
		flex-direction: column;
	}
	&__items {
		margin-bottom: 0;
	}
	&__trigger,
	&__trigger-desktop {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $spacing-default;
		flex: 1;
		font-weight: $font-weight-bold;
		cursor: pointer;
		text-align: left;
		color: $color-black;
		@include bp(lg) {
			padding: 0 $spacing-default;
		}
		@include bp(xl) {
			padding: 0 $spacing-default * 2;
			&:last-of-type {
				flex: 2;
			}
		}
		&--multiple {
			flex: unset;
			&:last-of-type {
				flex: unset;
			}
		}
	}
	&__trigger {
		padding: $spacing-default $spacing-medium/2;
	}
	&__icon-expanded,
	&__icon-collapsed {
		transition: transform $animation-duration-default
				$animation-duration-default,
			max-height $animation-duration-default $animation-duration-default;
		transform-origin: 50% 50%;
		position: absolute;
	}
	&__trigger {
		.icon {
			width: $icon-space;
			height: $icon-height;
			transform: rotate(0deg);
			transition: transform $animation-duration-default
				$animation-easing-default;
			svg {
				width: $icon-space;
				height: $icon-height;
			}
		}
		&--inner {
			display: flex;
			align-items: center;
			padding: $spacing-default * 1.25 0;
			padding-right: $spacing-default/4;

			--animation-time: 200ms;
			position: relative;
			overflow: hidden;

			&::after {
				content: "";
				display: block;
				bottom: 0;
				left: 0;
				width: calc(100% - ($spacing-large * 1.1));
				height: $spacing-default/5;
				position: absolute;
				background-color: var(--underline-color, currentColor);
				transition: transform ease var(--animation-time, 200ms),
					opacity ease 0ms 200ms;
				transform: translateX(100%);
				opacity: 0;
			}
		}
	}
	&__icon {
		transition: background-color $animation-duration-default ease;
		&.cta-btn-circle {
			background-color: $color-black;
			margin-left: $spacing-default * 0.75;
			svg {
				fill: $color-white;
			}
		}
	}
	&__trigger-desktop {
		display: none;
		justify-content: flex-start;

		.tabs__icon-expanded {
			transform: scale3d(0, 0, 0) rotate(360deg);
			max-height: 0;
			> .icon {
				color: $color-black;
			}
		}
		.tabs__icon-collapsed {
			transform: scale3d(1, 1, 1) rotate(0deg);
			> .icon {
				color: $color-white;
			}
		}
	}
	&__trigger-active-indicator {
		display: none;
	}
	&--panel-visible {
		.tabs__trigger {
			.icon {
				transform: rotate(270deg);
				transition: transform $animation-duration-default
					$animation-easing-default;
			}
		}
	}
	&__item {
		+ .tabs__item {
			border-top: 1px solid $color-grey;
		}

		@include bp(lg) {
			&.tabs--panel-visible {
				border-top: 1px solid $color-white;
				.tabs__trigger {
					color: $color-primary;
					font-weight: $font-weight-bold;
					.icon {
						transform: rotate(270deg);
						svg {
							fill: $color-primary;
						}
					}
				}
			}
			&.tabs--panel-visible:only-child {
				border-top: 0;
			}
		}
	}

	&__child {
		padding: $spacing-default;
		@include bp(lg) {
			padding: 0 $spacing-default * 1.25;
			margin-bottom: $spacing-default;

			.tabs__child-link {
				padding: $spacing-default $spacing-default 0 $spacing-default;
				color: $color-black;
				text-decoration: none;
				transition: all $animation-duration-default * 2
					$animation-easing-default;
				&:hover,
				&.focus-visible {
					background-color: $color-grey;
					transform: translate3d(15px, 0, 0);
				}
			}
		}
	}
	&__child-image {
		.umbraco-image-module,
		img {
			width: 100%;
			height: $spacing-large * 4;
		}
	}

	&__child-link {
		box-shadow: $box-shadow-base;
		border-radius: $border-radius-primary;
		display: block;
		padding: $spacing-default/2;
	}
	&__child-title {
		padding: $spacing-default * 0.75 0;
		@include bp(lg) {
			padding: $spacing-default 0;
		}
	}
	&__child-description {
		padding-bottom: $spacing-default/2;
		line-height: $line-height-paragraph;
		@include bp(lg) {
			padding-bottom: $spacing-default * 1.5;
		}
	}
	&__panel {
		overflow: hidden;
		height: 0;
		transition: height $animation-duration-default * 2
			$animation-easing-default;
	}
	&.tabs--hide-triggers {
		.tabs__trigger {
			display: none;
		}
		.tabs__panel {
			height: auto;
		}
	}
	.tabs__children {
		@include bp(lg) {
			padding: $spacing-default * 1.25 $spacing-default * 1.25
				$spacing-default/4 $spacing-default * 1.25;
		}
	}

	@include bp(lg) {
		transition: height $animation-duration-default * 3
			$animation-easing-default;

		.tabs__items,
		.tab__navigation {
			display: flex;
			flex-direction: row;
			overflow: hidden;
			background-color: $color-white;
			margin-bottom: 0;
		}
		.tabs__trigger {
			display: none;
		}
		.tabs__trigger-desktop {
			display: flex;
			align-items: center;
			cursor: pointer;
			box-sizing: border-box;
			background-color: $color-white;
			transition: color $animation-duration-default * 5
					$animation-easing-default,
				background-color $animation-duration-default * 5
					$animation-easing-default;

			svg {
				display: block;
			}
		}
		.tabs__trigger-desktop--active {
			background-color: $color-white;
			.cta-btn-circle {
				background-color: $color-white;
				box-shadow: $box-shadow-base;
				svg {
					fill: $color-black;
				}
			}

			.tabs__trigger--inner {
				&::after {
					transform: translateX(0);
					transition-delay: 0ms, 0ms;
					opacity: 1;
				}
			}
			.tabs__icon-expanded {
				transform: scale3d(1, 1, 1) rotate(0deg);
				max-height: $spacing-large;
			}
			.tabs__icon-collapsed {
				transform: scale3d(0, 0, 0) rotate(360deg);
				max-height: 0;
			}

			.tabs__trigger-desktop {
				.tabs__icon-expanded {
					display: inline-block;
				}
				.tabs__icon-collapsed {
					display: none;
				}
				.cta-btn-circle {
					background-color: $color-dark-blue;
					svg {
						fill: $color-white;
					}
				}
			}
		}
		.tabs__item {
			flex: 1;
			display: block;
		}
		.tabs__panel {
			overflow: hidden;
			height: auto;
			position: absolute;
			left: 0;
			width: 100%;
			opacity: 0;
			pointer-events: none;
		}
		.tabs--panel-visible {
			.tabs__panel {
				height: auto;
				@include GKFadeInLeft;
				animation-delay: 200ms;
				pointer-events: all;
			}
		}
		.tabs--panel-invisible {
			.tabs__panel {
				@include GKFadeOutLeft;
				pointer-events: none;
			}
		}

		.tabs__child-title {
			padding: $spacing-default 0;
		}
		.tabs__child-icon {
			margin-bottom: $spacing-small/5;
			margin-right: $spacing-default/2;
			display: inline-block;
		}
	}
}
