@mixin animateRight() {
	animation-name: animateRight;
	animation-duration: 500ms;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
	animation-direction: forwards;

	@keyframes animateRight {
		0% {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
		39% {
			transform: translate3d(50%, 0, 0);
			opacity: 0.2;
		}

		40% {
			transform: translate3d(-30%, 0, 0);
			opacity: 0;
		}

		100% {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
	}
}
@mixin animateDown() {
	animation-name: animationDown;
	animation-duration: 500ms;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
	animation-direction: forwards;

	@keyframes animationDown {
		0% {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
		39% {
			transform: translate3d(0, 50%, 0);
			opacity: 0.2;
		}

		40% {
			transform: translate3d(0, -30%, 0);
			opacity: 0;
		}

		100% {
			transform: translate3d(0, 0, 0);
			opacity: 1;
		}
	}
}
@mixin animateRightUp() {
	animation-name: animateRightUp;
	animation-duration: 500ms;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
	animation-direction: forwards;

	@keyframes animateRightUp {
		0% {
			transform: translate3d(0, 0, 0) rotate(-45deg);
			opacity: 1;
		}
		39% {
			transform: translate3d(20%, -20%, 0) rotate(-45deg);
			opacity: 0.2;
		}

		40% {
			transform: translate3d(-20%, 20%, 0) rotate(-45deg);
			opacity: 0;
		}

		100% {
			transform: translate3d(0, 0, 0) rotate(-45deg);
			opacity: 1;
		}
	}
}
@mixin pulseOnce() {
	animation-name: pulseOnce;
	animation-duration: 200ms;
	animation-timing-function: ease-in-out;
	animation-iteration-count: 1;
	animation-direction: forwards;
	$scaleUp: 1.05;

	@keyframes pulseOnce {
		0% {
			transform: scale3d(1, 1, 1);
		}
		50% {
			transform: scale3d($scaleUp, $scaleUp, 1);
		}
		100% {
			transform: scale3d(1, 1, 1);
		}
	}
}

@keyframes GKFadeInLeft {
	0% {
		opacity: 0;
		transform: translate3d(50px, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}
@mixin GKFadeInLeft {
	animation: GKFadeInLeft 600ms $animation-easing-default 0ms forwards;
}

@keyframes GKFadeOutLeft {
	0% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}

	to {
		opacity: 0;
		transform: translate3d(-50px, 0, 0);
	}
}

@keyframes GKRepeatingFadeIn {
	0%,
	6% {
		opacity: 1;
	}
	3% {
		opacity: 0.4;
	}
}

@keyframes GKRepeatingTranslateX {
	0% {
		transform: translateX(0px);
	}
	10% {
		transform: translateX(5px);
	}

	90% {
		transform: translateX(5px);
	}
	100% {
		transform: translateX(0px);
	}
}

@mixin GKFadeOutLeft {
	animation: GKFadeOutLeft 400ms $animation-easing-default 0ms forwards;
}

@mixin repeatingFadeIn() {
	animation-name: GKRepeatingFadeIn;
	animation-iteration-count: infinite;
	animation-delay: 3s;
	animation-duration: 3s;
	animation-timing-function: $animation-easing-default;
	animation-direction: forwards;
}

@mixin repeatingTranslateX {
	animation-name: GKRepeatingTranslateX;
	animation-iteration-count: infinite;
	animation-duration: 3s;
	animation-timing-function: $animation-easing-default;
	animation-direction: forwards;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 1.5s;
}
.fade-enter,
.fade-leave-active {
	opacity: 0;
}

.firefox-anchor-fade {
	transition: opacity 0.125s ease-out;
	&--hidden {
		opacity: 0 !important;
	}
}
