﻿@mixin scrollbar-theme($thumb-color, $track-color) {
	// For Chrome & Safari

	&::-webkit-scrollbar-thumb {
		background: $thumb-color;
	}
	&::-webkit-scrollbar-track {
		background: $track-color;
	}

	// Standard version (Firefox only for now)
	scrollbar-color: $thumb-color $track-color;
}

.scrollbar {
	&--slim {
		&::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}

		scrollbar-width: thin;
	}

	&--rounded {
		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			-webkit-border-radius: 10px;
		}
		&::-webkit-scrollbar-track {
			border-radius: 10px;
			-webkit-border-radius: 10px;
		}
	}

	&--themeBlueLight {
		@include scrollbar-theme(
			$color-light-blue,
			$color-scrollbar-track-light
		);
	}
	&--themeBlueDark {
		@include scrollbar-theme($color-dark-blue, $color-scrollbar-track-dark);
	}
	&--themeGrayLight {
		@include scrollbar-theme(
			$color-gray-light,
			$color-scrollbar-track-dark
		);
	}
	&--themeWhite {
		@include scrollbar-theme($color-white, $color-scrollbar-track-dark);
	}
	&--themeYellow {
		@include scrollbar-theme($color-yellow, $color-scrollbar-track-light);
	}
	&--themeOrange {
		@include scrollbar-theme($color-orange, $color-scrollbar-track-light);
	}
	&--themeBlue {
		@include scrollbar-theme($color-blue, $color-scrollbar-track-light);
	}
	&--themePink {
		@include scrollbar-theme($color-pink, $color-scrollbar-track-light);
	}
	&--themeRed {
		@include scrollbar-theme($color-red, $color-scrollbar-track-light);
	}
	&--themeTurquoise {
		@include scrollbar-theme(
			$color-turquoise,
			$color-scrollbar-track-light
		);
	}
	&--themeTurquoiseLight {
		@include scrollbar-theme(
			$color-turquoise-light,
			$color-scrollbar-track-light
		);
	}
	&--themeDefault {
		@include scrollbar-theme($color-white, $color-scrollbar-track-dark);
	}
}
