﻿$block: "table";

@mixin table-theme($color, $heading-color, $scroll-thumb-color) {
	background-color: transparent !important;
	color: $color-black !important;

	.#{$block}__table {
		@include scrollbar-theme(
			$scroll-thumb-color,
			$color-scrollbar-track-dark
		);
	}

	.#{$block}__row {
		&:first-of-type {
			.#{$block}__cell {
				background-color: $color;
				color: $heading-color;
			}
		}

		> .#{$block}__cell {
			&:first-of-type {
				background-color: $color;
				color: $heading-color;
			}
		}
	}

	.#{$block}__cell {
		&--empty {
			background-color: transparent !important;
		}
	}
}

.#{$block} {
	padding: 0;

	@include table-theme($color-background, $color-black, $color-white);

	&__container {
		overflow: hidden;

		@include bp(sm) {
			overflow: visible;
		}
		@include bp(lg) {
			padding-right: 0;
			padding-left: 0;
		}
	}

	&__table {
		text-align: center;
		overflow-x: auto;
	}

	&__row {
		display: flex;

		&:first-of-type {
			font-weight: $font-weight-bold;
			// negate the margin on the table__cell
			margin-top: -5px;

			@include bp(md) {
				margin-top: -15px;
			}

			p {
				font-weight: $font-weight-bold;
			}
		}

		&:last-child {
			margin-bottom: $spacing-small * 3;

			@include bp(lg) {
				margin-bottom: 0;

				.#{$block}__cell {
					margin-bottom: 0;
				}
			}
		}

		> .#{$block}__cell {
			&:first-of-type {
				font-weight: $font-weight-bold;

				> p {
					font-weight: $font-weight-bold;
				}
			}
		}
	}

	&__cell {
		flex: 1 0 0;
		margin: 5px;
		padding: $spacing-default * 0.5 10px;
		background-color: $color-white;
		min-width: 100px;

		@include bp(md) {
			margin: 15px;
		}

		&--empty {
			background-color: transparent;
		}

		// rte content
		a {
			text-decoration: underline;
		}
	}

	&--themeBlueLight {
		@include table-theme($color-light-blue, $color-black, $color-white);
	}
	&--themeBlueDark {
		@include table-theme($color-dark-blue, $color-white, $color-white);
	}
	&--themeGrayLight {
		@include table-theme($color-gray-light, $color-black, $color-white);
	}
	&--themeWhite {
		@include table-theme($color-white, $color-black, $color-black);
	}
	&--themeYellow {
		@include table-theme($color-yellow, $color-black, $color-white);
	}
	&--themeOrange {
		@include table-theme($color-orange, $color-black, $color-white);
	}
	&--themeBlue {
		@include table-theme($color-blue, $color-white, $color-white);
	}
	&--themePink {
		@include table-theme($color-pink, $color-black, $color-white);
	}
	&--themeRed {
		@include table-theme($color-red, $color-black, $color-white);
	}
	&--themeTurquoise {
		@include table-theme($color-turquoise, $color-black, $color-white);
	}
	&--themeTurquoiseLight {
		@include table-theme(
			$color-turquoise-light,
			$color-black,
			$color-white
		);
	}
	&--themeDefault {
		@include table-theme($color-background, $color-black, $color-white);
	}
}
