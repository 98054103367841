﻿.height {
	&-100 {
		height: 100% !important;
	}
	&-50 {
		height: 50% !important;
	}

	@include bp(md) {
		&-md {
			&-100 {
				height: 100% !important;
			}
			&-50 {
				height: 50% !important;
			}
		}
	}
}

.equal-height {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}
