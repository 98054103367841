.video-thumbs {
	&__cover-img {
		height: $height-large * 0.32;
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		left: 0;
		top: 0;
		z-index: 9;
		cursor: pointer;

		picture {
			overflow: hidden;
		}

		img {
			width: 100%;
			height: 100%;
		}
	}

	.umbraco-image-module {
		background-color: $color-black; // a solid background that is shown, until the cover image is loaded, so that the videoplayer doesn't flash appear for a second
	}

	&__play {
		position: absolute;
		z-index: 9;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		box-sizing: content-box;
		display: block;
		width: $spacing-default;
		height: $spacing-default;
		border-radius: 50%;
		cursor: pointer;
		transition: transform $animation-duration-default
			$animation-easing-default;

		&:after {
			content: "";
			position: absolute;
			z-index: 1;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
			display: block;
			width: $spacing-medium * 2.5;
			height: $spacing-medium * 2.5;
			background: inherit;
			border-radius: 50%;
			transition: all 200ms;
			background-color: $color-play-red;
		}
	}

	&:hover {
		.video-thumbs__play {
			transform: translateX(-50%) translateY(-50%) scale(1.2);
		}
	}

	&__container {
		&:focus-visible {
			.video-thumbs__cover-img {
				@include focus;
			}
		}
	}

	&__container-triangle {
		width: 25px;
		height: 28px;
		position: relative;
		z-index: 55;
		top: -4px;
		left: 2px;
		background-image: $videoplayerIconWhite;
		background-repeat: no-repeat;
	}

	&--responsive {
		position: relative;
		height: 0 !important;
		padding-top: $ratio-16x9; // default to 16:9

		.videoplayer__iframe,
		.video-tag {
			position: absolute;
			inset: 0;
			width: 100%;
			height: 100%;
			background-color: $color-black;
		}

		&-16x9 {
			padding-top: $ratio-16x9;
		}
		&-9x16 {
			padding-top: $ratio-9x16;
		}
		&-100x63 {
			padding-top: $ratio-100x63;
		}
	}
}

.videoplayer .video-tag {
	a {
		text-decoration: underline;
	}
	a:hover,
	a:focus {
		text-decoration: none;
		font-weight: $font-weight-bold;
	}
}
