﻿$block: "overlay";

@mixin overlay-theme($bg, $useDarkText) {
	background-color: $bg;
	@if ($useDarkText) {
		--overlay-color: #{$color-black};
		--overlay-image-filter: brightness(0);
	} @else {
		--overlay-color: #{$color-white};
		--overlay-image-filter: brightness(0) invert(1);
	}
}

.#{$block} {
	background-color: rgba(0 0 0 / 0.5);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: grid;
	place-items: center;
	overflow-y: auto;
	opacity: 0;
	z-index: $z-index-overlay;
	animation: fade-in 200ms $animation-easing-default forwards;

	&--themeBlueLight {
		@include overlay-theme($color-light-blue, true);
	}
	&--themeBlueDark {
		@include overlay-theme($color-dark-blue, false);
	}
	&--themeGrayLight {
		@include overlay-theme($color-gray-light, true);
	}
	&--themeWhite {
		@include overlay-theme($color-white, true);
	}
	&--themeYellow {
		@include overlay-theme($color-yellow, true);
	}
	&--themeOrange {
		@include overlay-theme($color-orange, true);
	}
	&--themeBlue {
		@include overlay-theme($color-blue, false);
	}
	&--themePink {
		@include overlay-theme($color-pink, true);
	}
	&--themeRed {
		@include overlay-theme($color-red, true);
	}
	&--themeTurquoise {
		@include overlay-theme($color-turquoise, true);
	}
	&--themeTurquoiseLight {
		@include overlay-theme($color-turquoise-light, true);
	}
	&--themeDefault {
		@include overlay-theme($color-background, true);
	}
}

@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
