$block: "image-and-video";

@mixin page-header-theme($bg-color, $color) {
	.#{$block} {
		&__background {
			background-color: $bg-color;
			color: $color;
			@include content-space-padding();

			&:before {
				background-color: $bg-color;
			}
		}
	}
}

.#{$block} {
	img,
	video {
		object-fit: cover;
	}
	img,
	video,
	iframe {
		width: 100%;
		height: 100%;
	}
	figure {
		height: 0;
		padding-top: $ratio-16x9;
		position: relative;
		margin-bottom: 0;

		.umbraco-image-module,
		.videoplayer {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.videoplayer__wrapper,
		.videoplayer__wrapper--local {
			height: 100%;
		}

		.umbraco-image__bg {
			background-color: transparent; // support transparent .pngs
		}
	}

	.figcaption {
		margin-top: 1rem;
	}

	&__cover-img {
		will-change: transform; // Sub-pixel rendering issue
	}

	&__background {
		@include left-background-overflow();
		padding-left: $spacing-medium;
		padding-right: $spacing-medium;

		@include bp(lg) {
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
		}
	}

	&__col {
		&--8 {
			@include bp(lg) {
				@include col-with-parent-size(8, 10);
			}
		}

		&.large-col {
			@include bp(lg) {
				figure,
				.figcaption,
				.#{$block}__headline {
					margin-left: -($spacing-medium/2);
					margin-right: -($spacing-medium/2);
				}
			}
		}
	}

	//START ovrride video overview image and play button
	&__play {
		&:after {
			background-color: $color-play-red;
		}
	}
	//END ovrride video overview image and play button

	&--themeBlueLight {
		@include page-header-theme($color-light-blue, $color-black);
	}
	&--themeBlueDark {
		@include page-header-theme($color-dark-blue, $color-white);
	}
	&--themeGrayLight {
		@include page-header-theme($color-gray-light, $color-black);
	}
	&--themeWhite {
		@include page-header-theme($color-white, $color-black);
	}
	&--themeYellow {
		@include page-header-theme($color-yellow, $color-black);
	}
	&--themeOrange {
		@include page-header-theme($color-orange, $color-black);
	}
	&--themeBlue {
		@include page-header-theme($color-blue, $color-white);
	}
	&--themePink {
		@include page-header-theme($color-pink, $color-black);
	}
	&--themeRed {
		@include page-header-theme($color-red, $color-black);
	}
	&--themeTurquoise {
		@include page-header-theme($color-turquoise, $color-black);
	}
	&--themeTurquoiseLight {
		@include page-header-theme($color-turquoise-light, $color-black);
	}
	&--themeDefault {
		@include page-header-theme($color-background, $color-black);
	}
}
