.hero {
	position: relative;
	overflow-x: hidden;
	width: 100%;

	@include bp(lg) {
		background-color: inherit;
	}

	&__extention {
		position: relative;
		min-height: $height-large * 0.9;

		&::before,
		&::after {
			content: "";
			width: 50vw;
			height: 100%;
			background-color: inherit;
			top: 0;
			position: absolute;
			display: block;
		}

		&:before {
			right: 100%;
		}

		&:after {
			left: 100%;
		}
		@include bp(md) {
		}
		@include bp(lg) {
			&:after {
				content: none;
			}
		}
	}

	.favorite-toggle-module.hide--sm {
		position: absolute;
		top: $spacing-default * 1.75 - 2px;
		right: $spacing-default * 1.75 - 2px;
	}

	&__breadcrumb {
		padding: $spacing-default * 1.5 0 0 $spacing-default * 1.5;
		@include bp(lg) {
			padding: $spacing-default * 1.75 0 0 0;
			margin-right: 90px;
		}

		ul {
			margin-bottom: $spacing-default * 0.75;
		}

		&--hide {
			opacity: 0;
			visibility: hidden;
		}
	}
	&__type {
		padding: $spacing-default/4 0;
	}
	&__content {
		padding: $spacing-default $spacing-default * 0.75 $spacing-default * 2
			$spacing-default * 0.75;
		@include bp(lg) {
			padding: $spacing-default * 1.75 0 $spacing-default * 4;
		}

		&--container {
			@include bp(lg) {
				padding-right: $spacing-default * 3;
				padding-left: 0;
			}
		}
	}
	&__title {
		word-break: break-word;
	}
	&__image {
		margin-bottom: $spacing-default * 2;
		margin-right: -$spacing-default * 0.75;
		position: relative;

		@include bp(lg) {
			position: absolute;
			top: 50%;
			width: 50%;
			height: $height-large * 0.65;
			right: 0;
			margin-right: 0;
			transform: translate3d(0, -50%, 0);

			height: calc(100% - 160px);
		}
		&--container-img {
			position: relative;
			height: $spacing-large * 6;
			width: calc(100% + ($spacing-medium/2));
			box-shadow: $box-shadow-base;
			@include bp(lg) {
				width: 100%;
				height: 100%;
				background-position: center center;
				background-size: cover;
				margin-left: 0;
			}
			@include bp(xl) {
				max-width: $heroImageMax;
			}
			img {
				object-fit: cover;
				background-size: cover;
				width: 100%;
				height: 100%;
			}
			div {
				height: 100%;
			}
		}
		//From shared video file
		video {
			object-fit: cover;
		}
		video,
		iframe {
			height: $spacing-large * 6;
			width: calc(100% + ($spacing-medium/2));
			box-shadow: $box-shadow-base;
			@include bp(lg) {
				width: 100%;
				height: 100%;
				background-position: center center;
				background-size: cover;
				margin-left: 0;
			}
			@include bp(xl) {
				max-width: $heroImageMax;
			}
		}
		.videoplayer,
		.videoplayer__wrapper,
		.videoplayer__wrapper--local {
			height: 100%;
		}
		.video-thumbs__container:not(.hidden) {
			display: block;
		}
	}

	//START ovrride video overview image and play button
	&__video-thumbs {
		position: relative;
		max-width: $heroImageMax;
		height: 100%;
	}
	&__cover-img {
		width: calc(100% + ($spacing-default * 0.75));
		@include bp(lg) {
			width: 100%;
		}
		@include bp(xl) {
			max-width: $heroImageMax;
		}

		div {
			height: 100%;
		}
	}
	//END ovrride video overview image and play button
	.inview-gradient {
		width: calc(100% + ($spacing-default * 0.75));
		@include bp(lg) {
			width: 100%;
		}
	}
	.page-header .inview-gradient {
		width: 100%;
	}

	&__description {
		margin-bottom: $spacing-small * 3;
		width: 100%;
		line-height: $line-height-paragraph;
		font-size: $font-size-manchet;

		@include bp(lg) {
			margin-bottom: $spacing-small * 3;
			margin-top: $spacing-small * 3;
		}
	}

	&__dropdown {
		width: 100%;
		margin-top: $spacing-default * 2;
	}

	&__btn-wrap {
		margin-bottom: $spacing-small * 1.5;
	}

	&--page-header {
		overflow-x: visible;
	}

	// showHeroProp but no Media added
	&--shown-without-media {
		.hero__btn-wrap {
			display: inline-block;
			margin-right: $spacing-default + 5px;
			&:last-child {
				margin-bottom: $spacing-default * 2;
			}
		}
		.hero__extention {
			min-height: auto;
		}
		.hero__content {
			padding-bottom: 10px;
		}
	}

	// Fact elements
	&__factelements {
		margin-bottom: $spacing-small * 5;

		@include bp(lg) {
			margin-bottom: $spacing-small * 3;
			margin-top: $spacing-small * 3;
		}

		&-list {
			padding-left: 0;
			list-style-type: none;
		}

		&-item {
			display: flex;
			align-items: center;
			padding: 12px 0;

			&:first-of-type {
				padding-top: 6px;
			}

			a {
				text-decoration: underline;

				&:focus,
				&:hover {
					text-decoration: none;
				}
			}

			p {
				margin: 0;
			}
		}

		&-icon {
			flex: 0 0 $spacing-default;
			max-width: $spacing-default;
			margin-right: $spacing-small * 1.5;
		}
	}
}

.frontpage {
	.hero__content {
		@include bp(lg) {
			padding-top: 0;
		}
	}
}
