$block: "no-registrations";

.#{$block} {
	position: relative;
	margin-left: -$grid-gutter-width / 2;
	margin-right: -$grid-gutter-width / 2;

	@include bp(lg) {
		margin-left: -$grid-gutter-width - $spacing-default;
		margin-right: -$grid-gutter-width - $spacing-default;
	}

	&-wrapper {
		@include bp(lg) {
			@include col-with-parent-size(8, 10);
		}
	}

	&__inner {
		background-color: $color-gray-light;
		padding: $spacing-small * 3;
		@include bp(lg) {
			padding: $spacing-small * 5;
		}
	}

	&__cta {
		display: flex;
		justify-content: flex-end;
		transform: translate3d(0, 0, 0);
		transition: transform 200ms ease 0ms, opacity 100ms ease 0ms;
		margin-top: $spacing-small * 4;

		@include bp(lg) {
			transform: translate3d(100px, 0, 0);
		}

		&-wrap {
			display: flex;
			flex-direction: column;
		}
	}

	.umbraco-forms-form {
		padding: 0 !important;
		margin: 0;
		background-color: transparent;

		.umbraco-forms-field.shortanswer .umbraco-forms-label {
			background-color: transparent !important;
		}
	}

	&__form-overlay {
		position: absolute;
		inset: 0;
		background-color: $color-gray-light;
		z-index: 90;
		opacity: 0;
		visibility: hidden;
		transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
		overflow: auto;
		padding: $spacing-small * 3;
		@include bp(lg) {
			padding: $spacing-small * 5;
		}

		&.show {
			opacity: 1;
			visibility: visible;
		}
	}

	.field-validation-error {
		&.hidden {
			display: none !important;
		}
	}
}
