.breadcrumb {
	font-size: $font-size-xs;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	margin-bottom: $spacing-default;

	@include bp(lg) {
		margin-bottom: $spacing-medium;
	}

	&__item {
		color: inherit;
		padding-bottom: $spacing-default/2;
		@include bp(smx) {
			white-space: pre;
		}

		> a {
			color: inherit;
			display: flex;
			align-items: center;
			line-height: 1;
			&:hover,
			&.focus-visible {
				text-decoration: underline;
				svg {
					@include animateRight;
				}
			}
			.icon {
				position: relative;
				width: $spacing-default * 1.25;
				height: $spacing-default/2;
				padding: 0 $spacing-default/2;
				white-space: normal;
				top: 1px;

				svg {
					max-height: 100%;
					width: $spacing-default/2;
					fill: currentColor;
				}
			}
		}

		&:last-of-type {
			font-weight: bold;
			.icon {
				display: none;
			}
		}
	}
}
