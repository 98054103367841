﻿html,
body {
	font-size: $font-base;
	font-family: $font-family-default;
	font-weight: $font-weight-regular;
	line-height: $line-height-default;
}

html {
	color: $body-color;
}

body {
	background-color: $body-background;
	margin: 0 auto;
	*:focus {
		outline: none;
	}

	*.focus-visible {
		outline: 2px dashed currentColor;
		outline-offset: -2px;
	}
}

html > body > main,
html > body > main > .page {
	margin: 0 auto;
}

main {
	padding-top: $spacing-medium * 2.5;
	@include bp(lg) {
		padding-top: 0;
	}
}

a {
	color: inherit;

	&:hover,
	&:focus,
	&:active {
		transition: color $animation-easing-default $animation-easing-default;
		color: inherit;
		text-decoration: none;
	}
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	border: 0;
	padding: 0;
	background: transparent;
	box-sizing: content-box;
	margin: 0;
	text-decoration: none;
}

mark {
	background: none;
	color: inherit;
	line-height: $line-height-default;
}

p {
	line-height: $line-height-paragraph;
	margin: 12px 0;
}

button {
	border: 0;
	padding: 0;
	background: transparent;
	box-sizing: content-box;
	cursor: pointer;
	line-height: $line-height-default;
	color: inherit;
}

figcaption {
	color: $color-black;
	margin-top: $spacing-medium/2;
	line-height: $line-height-default;
}

blockquote {
	font-size: $font-size-medium;
	line-height: $line-height-default;

	&:before,
	&:after {
		font-family: $font-family-default;
	}

	&:before {
		content: "“";
	}

	&:after {
		content: "”";
	}
}
