.supplementary-points {
	overflow: hidden;
	&__two-columns {
		background-color: $color-light-blue; //default background-color
		position: relative;

		.two-columns-col {
			&:nth-child(even) {
				.supplementary-points__item {
					@include bp(md) {
						padding-left: $spacing-default;
					}
					@include bp(lg) {
						padding-right: $spacing-default * 2.5;
					}
					@include bp(xl) {
						padding-right: $spacing-default * 3.25;
					}
					@include bp(xxl) {
						padding-right: $spacing-default * 4;
					}
				}
			}
			&:nth-child(odd) {
				.supplementary-points__item {
					@include bp(md) {
						padding-right: $spacing-default;
					}
					@include bp(lg) {
						padding-left: $spacing-default * 2.5;
					}
					@include bp(xl) {
						padding-left: $spacing-default * 3.25;
					}
					@include bp(xxl) {
						padding-left: $spacing-default * 4;
					}
				}
			}
		}
	}
	&__full-width {
		.supplementary-points {
			&__item {
				position: relative;
				display: flex;
				flex-wrap: wrap;

				&.align-to-right {
					.first-col {
						@include bp(md) {
							order: 2;
							padding-left: 35px;
						}
						@include bp(lg) {
							padding-right: 15px;
							padding-left: 50px;
						}
					}
					.second-col {
						@include bp(md) {
							order: 1;
							padding-right: 35px;
						}
						@include bp(lg) {
							padding-left: 15px;
							padding-right: 50px;
						}
					}
				}
			}
			&__media {
				position: relative;

				@include bp(md) {
					margin-top: 0;
					margin-bottom: 0;
				}
			}
		}
	}

	&__item {
		padding: $spacing-default * 1.5;
		@include bp(md) {
			padding: $spacing-default * 1.5;
		}
		@include bp(lg) {
			padding: $spacing-default * 2.5;
		}
		@include bp(xl) {
			padding-right: $spacing-default * 3.25;
			padding-left: $spacing-default * 3.25;
		}
		@include bp(xxl) {
			padding-right: $spacing-default * 4;
			padding-left: $spacing-default * 4;
		}
	}
	&__tag {
		font-weight: $font-weight-bold;
		display: inline-block;
		margin-bottom: $spacing-default;
	}
	&__heading {
		font-size: $font-size-small;
		margin-bottom: $spacing-default;
	}
	&__point {
		position: relative;

		&.full-width-has-media {
			&.first-col {
				@include bp(md) {
					padding-right: 35px;
				}
				@include bp(lg) {
					padding-left: 15px;
					padding-right: 50px;
				}

				.supplementary-points__media {
					display: block;

					@include bp(md) {
						display: none;
					}
				}
			}
			&.second-col {
				display: none;

				@include bp(md) {
					display: block;
					padding-left: 35px;
				}
				@include bp(lg) {
					padding-right: 15px;
					padding-left: 50px;
				}
			}
		}
	}
	&__media {
		margin-bottom: $spacing-small * 3;
		margin-top: $spacing-small * 3;
		box-shadow: $box-shadow-base;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@include bp(md) {
			margin-bottom: $spacing-small;
			margin-top: $spacing-small;
		}
		@include bp(xl) {
			margin-bottom: $spacing-small * 2.5;
			margin-top: $spacing-small * 2;
		}
	}

	.video-thumbs__cover-img {
		.umbraco-image-module {
			width: 100%;
			height: 100%;
			will-change: transform; // Sub-pixel rendering issue
		}

		img {
			transform: scale(1.01); // Sub-pixel rendering issue
		}
	}

	&__btn-wrap {
		margin-bottom: $spacing-default/2;
		margin-top: $spacing-default * 1.5;

		+ .supplementary-points__btn-wrap {
			margin-top: $spacing-small * 1.5;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
	&__text {
		margin: 0;

		> *:first-child {
			margin-top: 0;
		}

		li {
			line-height: $line-height-paragraph;
		}
	}

	&:last-child {
		// Makes sure supplementary points have the same distance to the footer, as all the other components when shown as the last component in the list
		&.supplementary-points--full-width {
			&--space-below--20 {
				margin-bottom: -$spacing-small * 1.5;
			}
			&--space-below--40 {
				margin-bottom: -$spacing-small * 3;
			}
			&--space-below--80 {
				margin-bottom: -$spacing-small * 6;
			}

			@include bp(lg) {
				&--space-below--20 {
					margin-bottom: -$spacing-default;
				}
				&--space-below--40 {
					margin-bottom: -$spacing-default * 2;
				}
				&--space-below--80 {
					margin-bottom: -$spacing-default * 4;
				}
			}
		}
	}
}
