.header-favorite {
	&__link {
		position: relative;
		display: block;

		&--active {
			.header-favorite__link-dot {
				display: block;
			}
		}

		&-dot {
			display: none;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			pointer-events: none;
			position: absolute;
			top: -4px;
			right: 4px;
		}
	}

	.icon {
		min-width: 35px;
	}
}

.header--simple .header-favorite {
	// same as header__search
	float: right;
	padding-right: $spacing-medium;
	margin-top: $spacing-medium;
}

.header--small .header-favorite {
	margin-right: $spacing-small;
	position: absolute;
	right: 100%;

	&__link-dot {
		right: -4px;
	}

	.icon {
		max-width: 16px;
		min-width: 16px;
		height: 22px;
	}
}

.header--show-panel {
	.header-favorite {
		display: none;
	}
}
