$block: "icon";

.#{$block} {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	&:before {
		content: "";
		font-family: $font-family-default;
		font-weight: 700;
		font-style: normal;
	}

	&--plus {
		&:before {
			content: "+";
		}
	}

	&--minus {
		&:before {
			content: "−";
		}
	}

	&--close {
		&:before {
			content: "×";
		}
	}

	&--calendar {
		&:before {
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			background-size: contain;
			background-image: url("data:image/svg+xml,%3Csvg role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z' /%3E%3C/svg%3E");
		}
	}

	&--pin {
		&:before {
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			background-size: contain;
			background-image: url("data:image/svg+xml,	%3Csvg focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' %3E%3Cpath fill='currentColor' d='M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z' /%3E%3C/svg%3E");
		}
	}

	&--globe {
		&:before {
			transform: translateX(-2px); // visually center icon
			background-repeat: no-repeat;
			width: 100%;
			height: 100%;
			background-size: contain;
			background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xml%3Aspace%3D%22preserve%22%20x%3D%220%22%20y%3D%220%22%20style%3D%22enable-background%3Anew%200%200%201200%201200%22%20version%3D%221.1%22%20viewBox%3D%220%200%201200%201200%22%3E%3Cstyle%3E.st8%7Bfill%3Anone%3Bstroke%3A%23222221%3Bstroke-width%3A78.12%3Bstroke-miterlimit%3A10%7D%3C%2Fstyle%3E%3Ccircle%20cx%3D%22600%22%20cy%3D%22600%22%20r%3D%22558%22%20class%3D%22st8%22%2F%3E%3Cpath%20d%3D%22M600%201158c191-148.71%20306.9-344.06%20306.9-558S791%20190.72%20600%2042M600%2042v1116M1158%20600H42M600%201158c-191-148.71-306.9-344.06-306.9-558S409%20190.72%20600%2042%22%20class%3D%22st8%22%2F%3E%3Cpath%20d%3D%22M969.08%20181.51C870.72%20268.32%20741.51%20321%20600%20321s-270.72-52.68-369.08-139.49M230.93%201018.48C329.29%20931.67%20458.49%20879%20600%20879s270.71%2052.67%20369.07%20139.48%22%20class%3D%22st8%22%2F%3E%3C%2Fsvg%3E");
		}
	}
}

$downloadLinkIconPrimary: inline-svg(
	"<svg version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='#{$color-primary-rgb}'><polygon transform='translate(-20,-20)' points='33 35.59 33 20 31 20 31 35.59 25.71 30.29 24.29 31.71 32 39.41 39.71 31.71 38.29 30.29'/><rect y='22' width='24' height='2' /></svg>"
);
$internalLinkIconPrimary: inline-svg(
	"<svg version='1.1' viewBox='0 0 13.25 23.66' xmlns='http://www.w3.org/2000/svg' fill='#{$color-primary-rgb}'><polygon transform='translate(-27.77,-20.17)' points='27.77 21.58 29.19 20.17 41.02 32 29.19 43.83 27.77 42.42 38.19 32'/></svg>"
);
$externalLinkIconPrimary: inline-svg(
	"<svg version='1.1' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' fill='#{$color-primary-rgb}'><polygon transform='translate(-4,-4)' points='4 11 4 13 16.17 13 10.59 18.59 12 20 20 12 12 4 10.59 5.41 16.17 11'/></svg>"
);
$downloadLinkIconWhite: inline-svg(
	"<svg version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='white'><polygon transform='translate(-20,-20)' points='33 35.59 33 20 31 20 31 35.59 25.71 30.29 24.29 31.71 32 39.41 39.71 31.71 38.29 30.29'/><rect y='22' width='24' height='2' /></svg>"
);
$internalLinkIconWhite: inline-svg(
	"<svg version='1.1' viewBox='0 0 13.25 23.66' xmlns='http://www.w3.org/2000/svg' fill='white'><polygon transform='translate(-27.77,-20.17)' points='27.77 21.58 29.19 20.17 41.02 32 29.19 43.83 27.77 42.42 38.19 32'/></svg>"
);
$externalLinkIconWhite: inline-svg(
	"<svg version='1.1' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' fill='white'><polygon transform='translate(-4,-4)' points='4 11 4 13 16.17 13 10.59 18.59 12 20 20 12 12 4 10.59 5.41 16.17 11'/></svg>"
);
$downloadLinkIconBlack: inline-svg(
	"<svg version='1.1' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' fill='#{$color-black}'><polygon transform='translate(-20,-20)' points='33 35.59 33 20 31 20 31 35.59 25.71 30.29 24.29 31.71 32 39.41 39.71 31.71 38.29 30.29'/><rect y='22' width='24' height='2' /></svg>"
);
$internalLinkIconBlack: inline-svg(
	"<svg version='1.1' viewBox='0 0 13.25 23.66' xmlns='http://www.w3.org/2000/svg' fill='#{$color-black}'><polygon transform='translate(-27.77,-20.17)' points='27.77 21.58 29.19 20.17 41.02 32 29.19 43.83 27.77 42.42 38.19 32'/></svg>"
);
$externalLinkIconBlack: inline-svg(
	"<svg version='1.1' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' fill='#{$color-black}'><polygon transform='translate(-4,-4)' points='4 11 4 13 16.17 13 10.59 18.59 12 20 20 12 12 4 10.59 5.41 16.17 11'/></svg>"
);
$videoplayerIconBlack: inline-svg(
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.44 32.89"><polygon points="1.5 1.5 1.5 31.39 22.94 16.44 1.5 1.5" style="fill:none;stroke:#{$color-black};stroke-linejoin:round;stroke-width:3px"/></svg>'
);
$videoplayerIconWhite: inline-svg(
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.44 32.89"><polygon points="1.5 1.5 1.5 31.39 22.94 16.44 1.5 1.5" style="fill:#{$color-white};stroke:none;stroke-linejoin:round;stroke-width:3px"/></svg>'
);
$chevron-down-black: inline-svg(
	"<svg fill='#000000' version='1.1' viewBox='0 0 23.66 13.25' xmlns='http://www.w3.org/2000/svg'><polygon transform='rotate(90,35.8,8.03)' points='29.19 43.83 27.77 42.42 38.19 32 27.77 21.58 29.19 20.17 41.02 32'/></svg>"
);
$chevron-up-black: inline-svg(
	"<svg fill='#000000' version='1.1' viewBox='0 0 23.66 13.25' xmlns='http://www.w3.org/2000/svg'><polygon transform='rotate(-90,35.8,8.03)' points='29.19 43.83 27.77 42.42 38.19 32 27.77 21.58 29.19 20.17 41.02 32'/></svg>"
);
