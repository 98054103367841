@mixin set-theme-variables($useDarkText) {
	@if ($useDarkText) {
		--overlay-color: #{$color-black};
		--overlay-image-filter: brightness(0);
	} @else {
		--overlay-color: #{$color-white};
		--overlay-image-filter: brightness(0) invert(1);
	}
}
@mixin set-swiper-theme-css($themeColor, $handleColor) {
	/* Override swiper scrollbar-drag background-color */
	.swiper-scrollbar {
		background: $handleColor !important;
	}
	.swiper-scrollbar-drag {
		&:after {
			background-color: darken($color: $themeColor, $amount: 10);
		}
		&:before {
			background-color: $handleColor;
		}
	}
}
@mixin set-theme-colors($themeColor, $useDarkText) {
	background-color: $themeColor !important;
	@include set-theme-variables($useDarkText);

	@if ($useDarkText) {
		color: $color-black !important;
		border-color: $color-black;
		@extend .theme-text-dark;
		@include set-swiper-theme-css($themeColor, $color-black);
	} @else {
		color: $color-white !important;
		border-color: $color-white;
		@extend .theme-text-light;
		@include set-swiper-theme-css($themeColor, $color-white);
	}

	// NOTE: .theme-darker is used on videoPlaylist thumbnail slider cards
	&.theme-darker {
		background-color: darken($color: $themeColor, $amount: 10) !important;
	}

	// NOTE: .border-theme-color is used on manualNewsList
	.border-theme-color {
		border-bottom-color: darken($themeColor, 10%) !important;
	}
}

// NOTE: the .blueLight, .blueDark, .black, .white etc. are used for color dropdowns on the cirkeldiagram component
.themeBlueLight {
	@include set-theme-colors($color-light-blue, true);
}
.themeBlueDark {
	@include set-theme-colors($color-dark-blue, false);
}
.themeGrayLight {
	@include set-theme-colors($color-gray-light, true);
}
.themeWhite {
	@include set-theme-colors($color-white, true);
}
.themeYellow {
	@include set-theme-colors($color-yellow, true);
}
.themeOrange {
	@include set-theme-colors($color-orange, true);
}
.themeBlue {
	@include set-theme-colors($color-blue, false);
}
.themePink {
	@include set-theme-colors($color-pink, true);
}
.themeRed {
	@include set-theme-colors($color-red, true);
}
.themeTurquoise {
	@include set-theme-colors($color-turquoise, true);
}
.themeTurquoiseLight {
	@include set-theme-colors($color-turquoise-light, true);
}
.themeDefault {
	@include set-theme-colors($color-background, true);
}

.theme-text-dark {
	ul li:before {
		color: $color-black !important;
	}

	figcaption {
		color: rgba($color-black, 0.8) !important;
	}

	.rte,
	&.rte {
		> p > a,
		> ul > li > a,
		> a {
			color: inherit !important;

			&[href^="/"],
			&[href*="ucn.dk"],
			&[href*="localhost"],
			&[href*="ucn.local"] {
				&:after {
					background-image: $internalLinkIconBlack !important;
				}
			}

			&[href^="http"] {
				&:after {
					background-image: $externalLinkIconBlack !important;
				}
			}

			&[href*="/media/"] {
				&:after {
					background-image: $downloadLinkIconBlack !important;
				}
			}
		}
	}

	blockquote {
		cite {
			color: inherit !important;
		}
	}

	.button {
		background-color: $color-black !important;
		color: $color-white !important;

		&:hover {
			background-color: darken($color-black, 20) !important;
			color: $color-white !important;
		}
	}
	.header__search-icon-magnifier {
		svg {
			fill: $color-black !important;
		}
	}
	.header__burger,
	.header__close,
	.header__search,
	.header-favorite__link {
		svg {
			fill: $color-black !important;
		}
	}
}
.theme-text-light {
	ul li:before {
		color: $color-white !important;
	}

	figcaption {
		color: rgba($color-white, 0.8) !important;
	}

	.rte,
	&.rte {
		> p > a,
		> ul > li > a,
		> a {
			color: inherit !important;

			&[href^="/"],
			&[href*="ucn.dk"],
			&[href*="localhost"],
			&[href*="ucn.local"] {
				&:after {
					background-image: $internalLinkIconWhite !important;
				}
			}

			&[href^="http"] {
				&:after {
					background-image: $externalLinkIconWhite !important;
				}
			}

			&[href*="/media/"] {
				&:after {
					background-image: $downloadLinkIconWhite !important;
				}
			}
		}
	}

	blockquote {
		cite {
			color: inherit !important;
		}
	}

	.button {
		background-color: $color-white !important;
		color: $color-black !important;

		&:hover {
			background-color: darken($color-white, 20) !important;
			color: $color-black !important;
		}
	}
	.header__search-icon-magnifier {
		svg {
			fill: $color-white !important;
		}
	}
	.header__burger,
	.header__close,
	.header__search,
	.header-favorite__link {
		svg {
			fill: $color-white !important;
		}
	}
}

.disable-monochrome-logo {
	@mixin disable-monochrome-logo() {
		--overlay-image-filter: none;
	}
	.header__logo {
		img {
			@include disable-monochrome-logo();
		}
	}
	.overlay {
		@include disable-monochrome-logo();
	}
	.search-dialog {
		.header__logo {
			@include disable-monochrome-logo();
		}
	}
	.header--small.header--show-panel {
		.header__logo--img {
			filter: var(--overlay-image-filter);
		}
	}
}
