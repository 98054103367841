.hovernavigation {
	padding: 0;
	height: $height-medium - 30px;
	&--count-1 {
		height: $height-medium - 70px;
	}

	@include bp(lg) {
		padding-top: $spacing-default * 2;
		height: auto;
	}
	@include bp(xl) {
		padding-top: 70px;
	}
	@include bp(xxl) {
		padding-top: 80px;
	}
	&__container {
		display: none;
		@include bp(lg) {
			display: flex;
			position: relative;
			padding-bottom: $spacing-default * 1.25;
			min-height: $height-large;
		}
		.row {
			width: 100%;
		}
	}

	&__inner {
		height: 100%;

		@include bp(lg) {
			padding-left: 80px;
		}
		@include bp(xl) {
			padding-left: 95px;
		}
		@include bp(xxl) {
			padding-left: 110px;
		}
	}

	&__item-img {
		margin-left: -$spacing-default * 2;
		margin-top: -$spacing-default * 2;
		height: $height-medium;

		@include bp(lg) {
			height: 0;

			.umbraco-image-module,
			.umbraco-image,
			.umbraco-image div {
				height: 100%;
			}
		}
	}
	&__item {
		opacity: 0;
		height: 0;
		width: 100%;
		transition: opacity 1s;
		overflow: hidden;

		&--active {
			opacity: 1;
			height: auto;
			.hovernavigation__item-img {
				@include bp(lg) {
					height: $height-medium * 1.07;
					position: absolute;

					@include custom-spaces(
						$marginLeft: -65px,
						$marginTop: -$spacing-default * 2,
						$width: calc(100% + 80px)
					);
				}
				@include bp(xl) {
					@include custom-spaces(
						$marginLeft: -80px,
						$marginTop: -70px,
						$width: calc(100% + (85px))
					);
				}
				@include bp(xxl) {
					@include custom-spaces(
						$marginLeft: -95px,
						$marginTop: -80px,
						$width: calc(100% + (95px))
					);
				}
			}
		}
	}

	&__content {
		z-index: 3;
		padding-right: 0;
	}
	&__nav-list {
		position: absolute;
		right: $spacing-default * 2.5;
		top: $spacing-default * 2;

		@include bp(lg) {
			padding-right: 0;
		}
	}
	&__nav-item {
		padding-bottom: $spacing-default/2;

		&--active {
			.hovernavigation__nav-btn {
				z-index: 5 !important;
			}
		}
	}
	&__nav-btn {
		display: flex;
		z-index: 2;
		padding-left: calc($spacing-default * 2.5 + 1em);
		@include bp(xl) {
			padding-left: calc($spacing-default * 2.5 + 0.5em);
		}
		@include bp(xxl) {
			padding-left: $spacing-default * 2.5;
		}
	}

	&__item-teaser {
		padding: $spacing-default $spacing-default * 2.5;
		padding-right: 0;
		min-height: $spacing-default * 2;
		color: inherit;
		line-height: $line-height-paragraph;

		@include bp(lg) {
			margin-top: $height-medium;
		}
		@include bp(xl) {
			margin-top: $height-medium * 0.95;
		}
	}
	// mobile slider
	&__swiper {
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: $spacing-default * 2 $spacing-medium !important;
	}
	&__swiper-slide {
		text-align: center;
		font-size: $font-size-base;
		display: flex;
		align-items: flex-start !important;

		justify-content: center;

		align-items: center;
	}
	&__swiper-img {
		height: $height-large/2;
		width: 100%;

		div {
			height: 100%;
		}
	}
	&__swiper-box {
		position: absolute;
		left: $spacing-default;
		right: $spacing-default;
		padding: $spacing-default;
		text-align: left;
		top: $height-large * 0.4;
		height: $spacing-large * 3;
		overflow: hidden;
		box-shadow: $box-shadow-slider;
		background-color: $color-white;
	}
	&__swiper-title {
		@include ellipsis-text($height: auto, $lineClamp: 2);
	}
	&__swiper-teaser {
		color: inherit;
		display: block;
		@include ellipsis-text($height: auto, $lineClamp: 2);
	}

	&__swiper-pagination {
		top: auto !important;
		bottom: 30px !important;
	}
	&__swiper-scrollbar {
		top: auto !important;
		bottom: 35px !important;
	}
}
