.media-links {
	margin-bottom: $spacing-medium;
	position: relative;
	@include bp(lg) {
		margin-bottom: $spacing-default * 3;
	}
	@include bp(xl) {
		margin-bottom: $spacing-default * 5;
	}
	&__inner {
		position: relative;
		width: 100%;
		height: $height-medium;

		.umbraco-image-module {
			div {
				height: 100%;
			}
		}

		.umbraco-image-module,
		.videoplayer {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.videoplayer__wrapper,
		.videoplayer__wrapper--local {
			height: 100%;
		}

		@include bp(lg) {
			height: $height-medium;
		}
	}

	img {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background-position: center center;
		background-size: cover;
	}
	video {
		object-fit: cover;
	}
	video,
	iframe {
		width: 100%;
		height: 100%;
	}
	&__title {
		word-break: break-word;
	}
	&__content {
		padding: $spacing-medium $spacing-medium $spacing-default * 2;
		position: absolute;
		width: calc(100% - $spacing-medium);
		//background-color: $color-light-blue;
		bottom: -$spacing-medium;
		left: 0;
		z-index: 9;
		&.right-align {
			right: 0;
			left: auto;
		}

		@include bp(lg) {
			padding: $spacing-default * 2.5;
			width: calc(50% + ($spacing-medium * 2));
			bottom: -$spacing-default * 3;
			left: -$spacing-default * 3;
			&.right-align {
				right: -100% / $grid-columns;
			}
		}
		@include bp(xl) {
			padding: $spacing-default * 2.5;
			width: calc(50% + ($spacing-large * 2));
			bottom: -$spacing-default * 5;
			left: -100% / $grid-columns;
		}

		&-text {
			position: relative;
		}
	}
	&__description {
		margin: $spacing-default 0;
	}
	&__cta {
		margin-top: $spacing-medium;
	}

	&__cta-btn {
		margin-bottom: $spacing-small * 1.5;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
