.header {
	overflow: hidden;
	display: none;
	position: fixed;
	width: 100vw;
	top: 0;
	z-index: 11;
	transform: translate3d(0, 0, 0);
	transition: transform $animation-duration-default $animation-easing-default;
	&.header--hide:not(.header--show-panel):not(.header--show-search) {
		transform: translate3d(0, -100%, 0);
	}

	@include bp(lg) {
		width: 100%;
		display: block;
		position: static;
		.container-medium {
			padding: 0;
		}
		.container-medium {
			display: flex;
			justify-content: space-between;
		}
		.header__search-icon-magnifier {
			cursor: pointer;
			svg {
				transition: fill $animation-duration-default
					$animation-easing-default;
				fill: $color-black;
			}
		}
		.header__search-wrap {
			top: 47px;
		}

		&__inner {
			position: relative;
			display: flex;
		}
		&__links-container {
			display: flex;
			justify-content: space-between;
			height: $spacing-default * 6.5;
			align-items: self-start;
			margin-top: $spacing-default * 0.75;

			padding: $spacing-default * 1.5 0;
		}
		&__navigation {
			display: flex;
		}

		&__service-links {
			display: block;
			ul {
				display: flex;
				margin: 0;
				padding: 0;
				float: right;
			}
			a {
				display: block;
				text-decoration: none;
				margin-left: $spacing-default * 0.75;
				font-size: $font-size-base;

				&:hover,
				&.focus-visible {
					opacity: 0.8;
					text-decoration: underline;
				}
			}
			+ .header__service-link {
				margin-left: $spacing-default;
			}
		}
	}
	@include bp(xl) {
		&__links-container {
			display: flex;
			justify-content: space-between;
			height: $spacing-default * 5;
			align-items: self-start;
			margin-top: $spacing-default * 2.5;

			padding: $spacing-default * 1.5 0;
		}
	}
	&__logo {
		position: relative;

		@include bp(lg) {
			position: absolute;
			left: -30px;
			width: 12.5%;
		}

		&--link {
			@include bp(lg) {
				display: flex;
				justify-content: center;
				margin-top: $spacing-medium;
				height: $spacing-default * 6;

				img {
					filter: var(--overlay-image-filter);
				}
			}

			@include bp(xl) {
				margin-top: 17px;
				height: 133px;
			}
		}

		&--img {
			width: 100%;
			object-fit: unset;

			@include bp(lg) {
				min-height: 50px;
				max-height: 50px;
				min-width: 50px;
				max-width: 50px;
			}

			@include bp(xl) {
				min-height: 85px;
				max-height: 85px;
				min-width: 85px;
				max-width: 85px;
			}
		}

		&::before {
			position: absolute;
			content: "";
			height: 100%;
			right: -100px;
			position: absolute;
			top: 0;
			width: 100vw;
			background: inherit;
			z-index: -1;
		}
	}
	&__search-wrap {
		@include bp(lg) {
			position: absolute;
			right: -66px;
			width: 12.5%;
		}
		@media (min-width: 1500px) {
			right: -106px;
		}
	}
	&__inner {
		&.inview--was-visible {
			transition: opacity 200ms linear,
				transform 200ms $animation-easing-default;
		}
	}

	@include bp(xl) {
		&__links-container {
			padding: 0;
		}
	}
	&--navigation {
		display: none;
		@include bp(lg) {
			display: block;
		}
		.header {
			&__burger,
			&__close {
				cursor: pointer;
				margin-right: $spacing-default * 0.75;
				transition: transform 0.125s ease-in-out;
				&:hover {
					transform: scale(1.1);
					opacity: 0.8;
				}
			}
			&__panel {
				display: flex;
				align-items: center;
				&.active-panel {
					.header__panel--trigger {
						display: block;
					}
				}
				&--trigger {
					display: none;
				}
			}
			&__burger {
				display: block;
			}
			&__close {
				display: none;
				.icon {
					transform: scale(1.2);
				}
			}
		}
		&.header--show-panel {
			transition: border-bottom $animation-duration-default
				$animation-easing-default;

			.header {
				a {
					color: inherit;
				}
				&__inherit {
					background-color: transparent !important;
					position: relative;
					z-index: 1000;
				}
				&__burger {
					display: none;
				}
				&__close {
					display: block;
				}
				&__panel {
					&.active-panel {
						svg {
							fill: inherit;
						}
					}
				}
				&__service-links {
					a {
						color: inherit;
					}
				}
				&__search {
					svg {
						fill: inherit;
					}
				}
			}
		}
	}
}

.frontpage-servicemenu {
	@include bp(lg) {
		padding-right: $spacing-default * 2;
	}
}
