.h1,
h1 {
	font-size: $font-size-h1--small;
	font-weight: $font-weight-bold;
	line-height: $line-height-default;
	margin-bottom: $spacing-small * 3;
}
.h2,
h2 {
	font-size: $font-size-h2--small;
	font-weight: $font-weight-bold;
	line-height: $line-height-default;
	margin-bottom: $spacing-small * 2;
}
.h3,
h3 {
	font-size: $font-size-h3--small;
	font-weight: $font-weight-bold;
	line-height: $line-height-default;
}
.h4,
h4 {
	font-size: $font-size-h4--small;
	font-weight: $font-weight-bold;
	line-height: $line-height-default;
}
.h5,
h5 {
	font-size: $font-size-h5--small;
	font-weight: $font-weight-bold;
	line-height: $line-height-default;
	margin-bottom: $spacing-small;
}
.h6,
h6 {
	font-size: $font-size-h6--small;
	font-weight: $font-weight-bold;
	line-height: $line-height-default;
}

@include bp(md) {
	.h1,
	h1 {
		font-size: $font-size-h1;
	}
	.h2,
	h2 {
		font-size: $font-size-h2;
	}

	.h3,
	h3 {
		font-size: $font-size-h3;
	}
	.h4,
	h4 {
		font-size: $font-size-h4;
	}
	.h5,
	h5 {
		font-size: $font-size-h5;
	}
	.h6,
	h6 {
		font-size: $font-size-h6;
	}
}

.link {
	color: $color-dark-blue;

	&:hover,
	&:focus,
	&:active {
		transition: color $animation-easing-default $animation-easing-default;
		color: $color-black;
		text-decoration: underline;
	}
}

.body-text {
	font-size: $font-size-base;
	line-height: $line-height-default;
	font-weight: $font-weight-regular;
}

.quote {
	@extend .body-text;
}

.bold {
	font-weight: $font-weight-bold;
}

.label {
	@extend .body-text;
	color: rgba($color-black, 0.6);
}
.label--white {
	@extend .body-text;
	color: rgba($color-white, 0.6);
}

.date {
	font-size: $font-size-xs;
	text-transform: uppercase;
}

.manchet {
	font-size: 1.3rem;
	font-weight: $font-weight-regular;
}
