.intro-box {
	padding-left: 1rem;
	padding-right: 1rem;
	margin-left: auto;
	margin-right: auto;

	@include bp(lg) {
		padding-left: $spacing-small * 1.5;
		padding-right: $spacing-small * 1.5;
		max-width: 640px;
	}

	@include bp(xl) {
		max-width: 760px;
	}
	@include bp(xxl) {
		max-width: 880px;
	}

	&.intro-box--has-info {
		margin-bottom: $spacing-large;
	}

	&--info {
		padding-left: 0;
		padding-right: 0;
		margin-left: -$grid-gutter-width / 2;
		margin-right: -$grid-gutter-width / 2;

		@include bp(lg) {
			padding-left: $spacing-small * 1.5;
			padding-right: $spacing-small * 1.5;
			margin-left: auto;
			margin-right: auto;

			max-width: 740px;
		}

		@include bp(xl) {
			max-width: 860px;
		}
		@include bp(xxl) {
			max-width: 980px;
		}
	}

	&__btn-wrapper {
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		justify-content: center;
		height: 0;
	}
	&__btn {
		min-height: 60px;
		min-width: 160px;
		box-sizing: border-box;
		transform: translateY(-50%);

		&:active {
			transform: translateY(-50%);
		}
	}
	&--expand {
		position: relative;
		padding-bottom: 0.1px;

		.intro-box__content {
			overflow: hidden;
			transition: height 0.3s ease-in-out;
			margin-bottom: 60px;

			//roughly 1 line of heading + 1 line of manchet + 6 lines of text before gradient starts
			height: $font-size-h1--small + $font-size-h4 + 12rem + 8 * 0.25rem;
			@include bp(md) {
				height: $font-size-h1 + $font-size-h4 + 12rem + 8 * 0.25rem;
			}
		}

		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			pointer-events: none;
			height: 60%;
			background: linear-gradient(
				0deg,
				rgba($color-gray-light, 1) 50px,
				rgba($color-gray-light, 0)
			);
			transition: opacity 0.2s ease-out;
		}
	}
	&--expanded {
		&::after {
			opacity: 0;
		}
	}

	.nested-richtext,
	.nested-image-and-video {
		margin-top: $spacing-small * 2;
	}
}

.intro-box-wrapper {
	padding: 0;
	@include bp(lg) {
		padding-left: $grid-gutter-width / 2;
		padding-right: $grid-gutter-width / 2;
	}

	&__row {
		margin: 0;
		@include bp(lg) {
			margin-left: -$grid-gutter-width / 2;
			margin-right: -$grid-gutter-width / 2;
		}
	}
}
