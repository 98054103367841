.search-field {
	&__radio-group {
		display: flex;
		padding-left: $spacing-medium - 5px;
		margin-top: $spacing-small * 1.5;
		gap: $spacing-small/2 $spacing-default * 2;
		flex-flow: row wrap;
		color: var(--overlay-color, #{$color-black});

		input[type="radio"] ~ label {
			margin-bottom: 0;

			&::before {
				box-shadow: 0 0.4rem 0.5rem rgba($black, 0.15);
			}
		}
	}
}
