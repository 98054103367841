﻿.carousel {
	background-color: $color-gray-light;
	padding-top: $spacing-default * 1.25;
	padding-bottom: $spacing-default * 0.75;
	@include bp(lg) {
		padding-top: $spacing-default * 2.5;
		padding-bottom: $spacing-default * 2.5;
	}

	&--themeNoColor {
		background-color: transparent;
		padding: 0;

		.carousel__swiper {
			background-color: transparent !important;
			margin-bottom: -30px;

			@include bp(lg) {
				margin-bottom: 0;
			}
		}
	}

	&__text {
		padding: 0 15px;
		min-height: $spacing-default;

		@include bp(md) {
			padding: 0;
			margin-left: -15px;
			margin-right: -15px;
		}

		@include bp(lg) {
			margin-left: 0;
			margin-right: 0;
		}

		> :last-child {
			margin-bottom: $spacing-default;

			@include bp(lg) {
				margin-bottom: $spacing-default * 2;
			}
		}
	}

	&__container {
		@include bp(md) {
			margin-left: -30px;
		}
		@include bp(lg) {
			margin-left: 0px;
		}
	}

	&__slide-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: calc(100% + 1px); // subpixel issue
		color: $color-black;
		background: $color-light-blue;
		opacity: 0;
		z-index: -1;
		transition: all 0.5s ease-in-out;
		padding: $spacing-default * 1.5 $spacing-default * 1.5 $spacing-default;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&:hover,
		&:active,
		&:focus-visible {
			opacity: 1;
			z-index: 2;
			color: $color-black;
		}
	}
	&__link {
		position: relative;

		&:after {
			position: absolute;
			top: 50%;
			right: -1.45rem;
			transform: translate3d(0, -50%, 0);
			font-family: $font-family-default;
			content: "→";
			font-weight: $font-weight-regular;
			padding-left: $spacer;
			padding-right: $spacer;
			transition: right $animation-duration-default * 3
				$animation-easing-default;
			font-size: $font-size-h3;
		}
	}
	&__inner {
		position: relative;
	}

	&__swiper-slide {
		background: $color-white;
		height: auto !important;
		box-shadow: $box-shadow-base;
		cursor: pointer;
		&:hover {
			color: $color-white;
			.carousel__slide-overlay {
				opacity: 1;
				z-index: 2;
			}
		}
	}
	&__slide-image {
		height: $height-medium * 0.39;

		div {
			height: 100%;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		@include bp(lg) {
			height: $spacing-large * 5;
		}
	}
	&__slide-title {
		padding: $spacing-default $spacing-default * 1.5;
		position: relative;

		&:after {
			position: absolute;
			top: 31px;
			right: $spacer/2;
			transform: translate3d(0, -50%, 0);
			font-family: $font-family-default;
			content: "→";
			font-weight: $font-weight-regular;
			padding-left: $spacer;
			padding-right: $spacer;
			transition: right $animation-duration-default * 3
				$animation-easing-default;
			font-size: $font-size-h3;
		}
	}
	&__slide-title-inner {
		display: inline-block;
		margin-right: 15px;
		max-width: 100%;
		word-break: break-word;
	}
	&__description {
		line-height: $line-height-paragraph;
		@include ellipsis-text($height: auto, $lineClamp: 6);
	}

	//override swiper style
	&__swiper {
		width: 100%;
		height: 100%;
		position: relative !important;
		padding: $spacing-default/2 $spacing-default * 1.5 80px !important;

		@include bp(lg) {
			position: unset !important;
			padding: 0 15px !important;
		}
	}

	&__swiper-arrows {
		display: flex;
		justify-content: space-between;
		margin-top: $spacing-default * 3;

		&:has(.swiper-button-disabled + .swiper-button-disabled) {
			height: 0;
			overflow: hidden;
			margin-top: 20px;
		}
	}

	&__swiper-button-prev,
	&__swiper-button-next {
		background: $color-black;
		color: $color-white !important;
		border-radius: 50%;
		padding: $spacing-default * 1.1;
		position: static;
		&::after {
			font-size: $font-size-xs !important;
			line-height: 0 !important;
			font-weight: bold;
		}

		&.swiper-button-disabled {
			opacity: 1;
			background: #c0c4c7;
		}
	}

	&__swiper-scrollbar {
		top: auto !important;
		bottom: 35px !important;

		&.swiper-scrollbar {
			@include bp(lg) {
				overflow: hidden;
				width: 40% !important;
				left: 50% !important;
				transform: translateX(-50%);
				bottom: 22px !important;
				background-color: rgba(0, 0, 0, 0.1) !important;

				.swiper-scrollbar-drag::after {
					display: none;
				}
			}
		}
	}
	&__swiper-pagination {
		top: auto !important;
		bottom: 30px !important;
	}
}
