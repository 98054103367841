﻿$block: "info-box";

.#{$block} {
	position: relative;
	//background-color: $color-yellow;
	padding: $spacing-small * 5 $spacing-small * 4.5 $spacing-small * 4.5
		$spacing-small * 4.5;

	@include bp(lg) {
		padding: $spacing-small * 5.5 $spacing-small * 30 $spacing-small * 1.5
			$spacing-small * 5;
	}

	&__content {
		display: flex;
		flex-wrap: wrap;
		margin-left: -$grid-gutter-width;
		margin-right: -$grid-gutter-width;

		p {
			margin: 0;
		}

		a {
			text-decoration: underline;
		}
	}

	&__btn-wrap {
		margin-top: $spacing-small * 4.5;
		margin-right: -$grid-gutter-width / 2;
		display: flex;
		justify-content: flex-end;

		@include bp(lg) {
			position: absolute;
			top: 0;
			right: 0;
			transform: translate3d(50px, 45px, 0);
			margin-top: 0;
			margin-right: 0;
		}
	}

	&__item {
		padding: 0 $spacing-small * 1.5;
		margin-bottom: $spacing-small * 4;
		flex: 1 0 50%;
		min-width: 120px;

		@include bp(lg) {
			padding: 0 $grid-gutter-width;
			flex: 1 0 33.333%;
			max-width: 33.333%;
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__richtext {
		padding: 0 $spacing-small * 1.5;
		flex: 1 1 100%;

		@include bp(lg) {
			padding: 0 $grid-gutter-width;
		}
	}

	&__heading {
		text-transform: uppercase;
		margin: 0;
		@include bp(lg) {
			margin: 0 0 1.1rem;
		}
	}

	&__xl-text {
		font-size: $font-size-medium;
		font-weight: $font-weight-bold;
		line-height: $line-height-default;

		@include bp(lg) {
			font-size: $font-size-large;
		}
	}

	&--education-page {
		@include bp(lg) {
			padding: $spacing-small * 4 $spacing-small * 24 $spacing-small * 5
				$spacing-small * 5;
		}

		&.#{$block} {
			&--col-3 {
				padding-bottom: 0;

				@include bp(lg) {
					padding-bottom: $spacing-small * 5;
				}
			}
		}
	}

	&--education-subpage {
		@include bp(lg) {
			padding: $spacing-small * 4 $spacing-small * 24 0 $spacing-small * 5;
			margin-bottom: $spacing-medium + $spacing-large;
		}

		.#{$block} {
			&__btn-wrap {
				margin-top: 0;
			}
		}

		&.#{$block} {
			&--col-3 {
				padding-bottom: 0;
			}
		}
	}

	&--col-3 {
		@include bp(lg) {
			padding-right: $spacing-small * 5;
		}

		.#{$block} {
			&__item {
				@include bp(lg) {
					flex: 1 0 25%;
					max-width: 25%;
				}
			}
		}
	}

	&--no-bg {
		background-color: transparent;
	}

	&--no-padding {
		padding: 0;
	}

	&--module-page,
	&--event-page {
		@include bp(lg) {
			padding-right: $spacing-small * 5;
		}

		.#{$block} {
			&__item {
				@include bp(lg) {
					flex: 1 0 25%;
					max-width: 25%;

					&:nth-child(3) {
						// special case for the 3rd item as we need to make room for the button that's overlapping the infobox
						flex: 1 0 50%;
						max-width: 50%;
						padding-right: 25%;
					}
				}
			}
		}
	}
}
