/**
 * @license
 * MyFonts Webfont Build ID 3745335, 2019-04-10T05:07:22-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Mont-Regular by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/mont/regular/
 * 
 * Webfont: Mont-SemiBold by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/mont/semi-bold/
 * 
 * Webfont: Mont-Heavy by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/mont/heavy/
 * 
 * Webfont: Mont-Bold by Fontfabric
 * URL: https://www.myfonts.com/fonts/font-fabric/mont/bold/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3745335
 * Licensed pageviews: 100,000
 * Webfonts copyright: Modern and elegant sans serif font family.
 * 
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
/* NOTE: UCN-581 - This import was moved out of the bundled ucn.css file, as Firefox completely discards the entire file if the import fails. We don't want our site to be down because myfonts has server issues */
/* @import url("//hello.myfonts.net/count/392637"); */

/* regular */
@font-face {
	font-family: "Mont";
	font-weight: 400;
	font-style: normal;
	font-display: swap;
	src: url("webfonts/392637_0_0.eot");
	src: url("webfonts/392637_0_0.eot?#iefix") format("embedded-opentype"),
		url("webfonts/392637_0_0.woff2") format("woff2"),
		url("webfonts/392637_0_0.woff") format("woff"),
		url("webfonts/392637_0_0.ttf") format("truetype");
}

/* semi bold */
/*@font-face {*/
/*	font-family: "Mont";*/
/*	font-weight: 600;*/
/*	src: url("webfonts/392637_1_0.eot");*/
/*	src: url("webfonts/392637_1_0.eot?#iefix") format("embedded-opentype"),*/
/*		url("webfonts/392637_1_0.woff2") format("woff2"),*/
/*		url("webfonts/392637_1_0.woff") format("woff"),*/
/*		url("webfonts/392637_1_0.ttf") format("truetype");*/
/*}*/

/* heavy/black */
/*@font-face {*/
/*	font-family: "Mont";*/
/*	font-weight: 900;*/
/*	src: url("webfonts/392637_2_0.eot");*/
/*	src: url("webfonts/392637_2_0.eot?#iefix") format("embedded-opentype"),*/
/*		url("webfonts/392637_2_0.woff2") format("woff2"),*/
/*		url("webfonts/392637_2_0.woff") format("woff"),*/
/*		url("webfonts/392637_2_0.ttf") format("truetype");*/
/*}*/

/* bold */
@font-face {
	font-family: "Mont";
	font-weight: 700;
	font-style: normal;
	font-display: swap;
	src: url("webfonts/392637_3_0.eot");
	src: url("webfonts/392637_3_0.eot?#iefix") format("embedded-opentype"),
		url("webfonts/392637_3_0.woff2") format("woff2"),
		url("webfonts/392637_3_0.woff") format("woff"),
		url("webfonts/392637_3_0.ttf") format("truetype");
}
