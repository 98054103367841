.manual-news {
	padding-left: 0;
	padding-right: 0;

	&__inner {
		@include bp(lg) {
			padding: $component-padding-lg;
		}
		@include bp(xl) {
			padding: $component-padding-xl;
		}
		@include bp(xxl) {
			padding: $component-padding-xxl;
		}
	}
	&__columns {
		justify-content: space-between;
		width: 100%;

		&--two-col {
			display: none;
			@include bp(lg) {
				display: flex;
				margin: 0;
			}
		}
	}
	&__preheadline {
		padding: $spacing-medium;
		padding-top: $spacing-default * 1.25;
		padding-bottom: 0;
		@include bp(lg) {
			padding: 0;
		}
	}
	&__preheadline + .manual-news__headline {
		margin-top: -$spacing-default * 1.5;
		@include bp(lg) {
			margin-top: 0;
		}
	}
	&__headline {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		padding: $spacing-medium;
		padding-top: $spacing-default * 1.25;
		padding-bottom: 0;
		@include bp(lg) {
			padding: 0;
			padding-bottom: $spacing-default * 2.5;
		}
		> h2 {
			margin-bottom: 0;
		}
	}
	&__see-all {
		font-weight: bold;
		text-decoration: underline;
		&:hover {
			opacity: 0.8;
			text-decoration: underline;
		}
		@include bp(lg) {
			font-size: $font-size-h4--small;
		}
	}
	&__items {
		padding: calc($spacing-default + $spacer);
		padding-top: $spacing-default;
		padding-bottom: $spacing-default;
		flex-wrap: nowrap;
		@include bp(lg) {
			overflow-x: unset;
			flex-wrap: wrap;
		}
		&--first-column,
		&--second-column {
			@include bp(lg) {
				padding: 0;
				border-bottom: $border-small solid;
				&.col-lg-6 {
					max-width: 48%; //override bootstrap col width
				}
			}
		}
		&--first-column {
			@include bp(lg) {
				&.col-lg-8 {
					max-width: 64%; //override bootstrap col width
				}
			}
		}
		&--second-column {
			@include bp(lg) {
				&.col-lg-4 {
					max-width: 32%; //override bootstrap col width
				}
			}
		}
	}
	&__item {
		padding: 0;
		@include bp(lg) {
			margin: 0;
			margin-bottom: $spacing-default * 1.7;
			border-bottom: $border-small solid;
			&:last-of-type {
				border-bottom: none;
			}
		}
	}
	&__item-headline {
		font-weight: $font-weight-bold;
		line-height: $line-height-default;

		&--mobile {
			font-size: 1.46rem;
		}
		&--full {
			font-size: 1.33rem;
		}
		&--halfEven {
			font-size: 1.66rem;
		}
		&--halfUnevenBig {
			font-size: 2rem;
		}
		&--halfUnevenSmall {
			font-size: 1.33rem;
		}
	}

	//for full width module
	&__columns {
		&--full-width {
			.manual-news {
				&__item {
					padding: 0;

					&--fake {
						// ugly hack to make proper column flow (left aligned) work with the space-between rule down below
						opacity: 0;
						pointer-events: none;
						height: 0;
						min-height: 0;
						margin: 0 !important;
						border: 0 !important;
						padding: 0;
					}

					&:nth-child(n + 4) {
						// make spacing same as 2 column layout
						margin-top: $spacing-default * 1.7;
					}
				}
			}
			@include bp(lg) {
				.manual-news {
					&__items {
						border: none;
						margin: 0;
						padding: 0;
						justify-content: space-between;
					}
					&__item {
						border-bottom: $border-small solid;
						max-width: 31%; //override bootstrap col width
						margin-bottom: $spacing-default/2;
					}
				}
			}
			@include bp(xxl) {
				.manual-news__item {
					max-width: 31.5%;
				}
			}
		}
	}
	&__item-inner {
		@include bp(lg) {
			width: auto;
		}
	}
	&__item-labels {
		display: flex;
		font-size: 0.8em;
		margin-bottom: $spacing-default/4;

		.news-item-label {
			text-transform: uppercase;
			+ .news-item-label {
				position: relative;
				display: inline-block;
				padding-left: $spacing-default * 0.75;

				&::before {
					content: "";
					position: absolute;
					width: 1px;
					height: $spacing-default/2;
					left: $spacing-default * 0.35;
					bottom: 50%;
					transform: translateY(50%);
					background-color: $color-black;
				}
			}
		}
	}
	&__item-content {
		display: block;
		margin-bottom: $spacing-default * 2;
		@include bp(lg) {
			width: 100%;
			margin-bottom: $spacing-default * 1.7;
		}

		&:hover,
		&:focus {
			.manual-news__item-headline {
				text-decoration: underline;
			}
			.manual-news__item-img {
				img {
					transform: scale(1.03);
				}
			}
		}
	}
	&__item-img {
		margin-bottom: $spacing-default;
		box-shadow: $box-shadow-slider;
		height: $image-height-small;
		overflow: hidden;

		div {
			height: 100%;
		}
		img {
			transition: transform 250ms $animation-easing-default;
		}

		&--hide {
			display: none;
		}

		@include bp(lg) {
			height: auto;
			box-shadow: $box-shadow-base;
		}
	}

	&__item-teaser {
		color: inherit;
		display: block;
		height: auto;
	}

	// mobile slider
	&__swiper {
		width: 100%;
		height: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: $spacing-default * 1.5 !important;

		.manual-news__swiper-pagination {
			top: auto !important;
			bottom: 30px !important;
		}
		.manual-news__swiper-scrollbar {
			bottom: 35px;
			top: auto !important;
		}
	}

	&__swiper-slide {
		height: auto;

		.manual-news__item-inner,
		.manual-news__item-content {
			height: 100%;
		}
	}

	&__swiper-img {
		height: $height-large/2;
		box-shadow: $box-shadow-slider;
		> img {
			background-size: cover;
			width: 100%;
			height: 100%;
		}
	}
}
