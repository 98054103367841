@import "./functions";

//Rows
$row-max-width: 1310px;
$row-large-max-width: 1640px;
$row-medium-max-width: 1500px;

// fonts
$font-weight-regular: 400;
$font-weight-bold: 700;

$font-family-default: Mont;

//border-radius
$border-radius-primary: 5px;
$border-radius-circle: 50%;
$border-radius-large: 35px;
$border-radius-base: 2em;

//border
$border-large: 10px;
$border-medium: 6px;
$border-small: 2px;

$dount-space: 250px;
$row-default-padding: 0.5rem;

//Brand colors - DONT CHANGE THIS
$color-dark-blue: #004250;
$color-blue: #00646e;
$color-light-blue: #bed6db;
$color-orange: #ffc87d;
$color-yellow: #ffe673;
$color-gray-light: #e7ebed;
$color-background: #f1f6f9;
$color-pink: #ffa0d2;
$color-red: #ffa591;
$color-turquoise: #0096a0;
$color-turquoise-light: #a5dccd;

// generic colors
$color-white: #fff;
$color-black: #000;
$color-grey: #f6f6f6;
$color-text-grey: #6d7377;
$color-play-red: #fa3746;
$color-border-grey: #e3e3e3;
$color-error: #b82929;
$color-menu-highlight: #d63600;

$color-scrollbar-track-light: #e8ecee;
$color-scrollbar-track-dark: #cfd3d5;

$color-primary: $color-dark-blue;

$header-border-color: #ececec;
$overlayColor: rgba(#2f3234, 0);

$body-background: $color-background;

$color-primary-rgb: rgba($color-primary, 0.9999);

$umbraco-image-bg: #f6f6f6;

//spacing
$spacing-small: 10px;
$spacing-default: 20px;
$spacing-medium: 30px;
$spacing-large: 50px;
$spacing-form: 1em;

//components
$component-padding-lg: $spacing-default * 3 $spacing-default * 3.25;
$component-padding-xl: $spacing-default * 3 $spacing-default * 4;
$component-padding-xxl: $spacing-default * 3 $spacing-default * 4.75;

// $header-height: 210px;
$header-height: 150px;
$height-medium: 580px;
$content-text-max-width: 950px;
$heroImageMax: 720px;

//image
$image-height-small: 225px;

$height-large: 700px;
$response-space: 36px; // ($row-default-padding * 2) + $spacing-default

$box-shadow-base: rgba(0, 0, 0, 0.1) 0 0 $spacing-small;
$box-shadow-tilt: rgba(0, 0, 0, 0.1) 2px 4px $spacing-small;
$box-shadow-slider: rgb(0 0 0 / 60%) 0 0 $spacing-small;
$box-shadow-slider: rgb(0 0 0 / 60%) 0 0 $spacing-small;

$ratio-16x9: 56.25%;
$ratio-9x16: 177.77%;
$ratio-100x63: 63%;

// Typography
$font-base: 15px;
$font-ios-base: 16px; //prevent zoom-in on ios
$font-size-base: 1rem;
$font-size-manchet: $font-base + 2px;
$line-height-default: calc(0.25rem + 1em);
$line-height-base: 1.2; // bootstrap is using this and can't handle so we are making a default for calc(0.25rem + 1em)
$line-height-large: 1.8;
$line-height-paragraph: calc(0.6rem + 1em);

$font-size-h1: 3.1rem;
$font-size-h2: 2.1rem;
$font-size-h3: 2.1rem;
$font-size-h4: 1.13rem;
$font-size-h5: 1.13rem;
$font-size-h6: 1rem;

$font-size-h1--small: 2rem;
$font-size-h2--small: 1.6rem;
$font-size-h3--small: 1.6rem;
$font-size-h4--small: 1.13rem;
$font-size-h5--small: 1.13rem;
$font-size-h6--small: 1rem;

$font-size-large: 2.8rem;
$font-size-medium: 1.67rem;
$font-size-small: 1.333rem;
$font-size-xs: 0.86rem;

$font-size-icon: 1.4rem;

$line-height: $line-height-default;
$line-height-large: 40px;
$line-height-medium: 30px;

//icon
$icon-space: 8px;

//z-index
$z-index-overlay: 90;
$z-index-dialog: 91;
$zindex-sticky: 50;

//Animations
$animation-easing-default: cubic-bezier(0.02, 0.98, 1, 1.005);
$animation-duration-default: 150ms;

$sizes: (
	25: 25%,
	50: 50%,
	75: 75%,
);

$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge(
	(
		0: 0,
		1: (
			$spacer * 0.25,
		),
		2: (
			$spacer * 0.5,
		),
		3: $spacer,
		4: (
			$spacer * 1.5,
		),
		5: (
			$spacer * 3,
		),
		6: (
			$spacer * 3.25,
		),
		7: (
			$spacer * 3.5,
		),
		8: (
			$spacer * 4,
		),
		9: (
			$spacer * 5,
		),
		10: (
			$spacer * 6,
		),
	),
	$spacers
);
$gl-mq-width: "min-width";
$grid-gutter-width: $spacer !default;
