.timeline-accordion {
	&__title {
		margin-bottom: $spacing-medium;
		@include bp(md) {
			margin-bottom: $spacing-default * 2;
		}
	}
	&__icon-collapsed {
		transition: transform $animation-duration-default
				$animation-duration-default,
			max-height $animation-duration-default $animation-duration-default;
		transform-origin: 50% 50%;
		@include expand-collapse($color-black);
		&::before,
		&::after {
			left: -5px !important;
		}
	}
	@mixin accordionExpanded() {
		.timeline-accordion__header {
			color: $color-black;
		}
		.timeline-accordion__icon {
			background-color: $color-white;
			box-shadow: 0 0 0 $spacing-small/2 $color-white;
			border-color: $color-black;
			svg {
				fill: $color-black;
			}
		}

		.timeline-accordion__icon-collapsed {
			@include expand-collapse($color-black);
			&::before,
			&::after {
				transform: rotate(0deg);
			}
		}
		.timeline-accordion__content {
			margin-bottom: $spacing-medium;
			opacity: 1;
		}
	}

	&__item {
		transition: background-color 0.2s ease;
		&.timeline-accordion__item--expanded {
			@include accordionExpanded();
		}
		.timeline-accordion__content {
			p:last-child {
				margin: 0;
			}
		}
		.timeline-accordion__content-inner {
			padding-left: $spacing-default/2;
			padding-bottom: $spacing-default * 2;

			@include bp(md) {
				padding-left: $spacing-default * 1.25;
				padding-bottom: $spacing-default * 3;
			}
		}

		&--no-step {
			.timeline-accordion__trigger {
				flex-wrap: nowrap;
			}
			.timeline-accordion__step-num {
				display: none;

				@include bp(md) {
					display: block;
				}
			}
			.timeline-accordion__header {
				margin-left: $spacing-medium/3;

				@include bp(md) {
					margin-left: 0;
				}
			}
		}

		&.no-content {
			pointer-events: none;
			.timeline-accordion__header {
				color: $color-black;
			}
			.timeline-accordion__step-num {
				color: $color-black;
			}
		}

		// make sure the timeline-bar doesn't pertrude below the last circle
		&:last-child {
			background-image: linear-gradient(
				to bottom,
				transparent 50px,
				$body-background 51px
			);
			@include bp(md) {
				background-image: linear-gradient(
					to bottom,
					transparent 50%,
					$body-background 50%
				);
			}

			&.timeline-accordion__item--expanded {
				background-image: none;
			}
		}
	}
	&__trigger {
		color: $color-dark-blue;
		cursor: pointer;
		padding: $spacing-default * 0.75;
		margin: 0;
		width: calc(100% - ($spacing-medium));
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@include bp(md) {
			padding: $spacing-default * 1.5 $spacing-default * 0.75
				$spacing-default;
			flex-wrap: nowrap;
			width: 100%;
		}
	}
	&__header {
		padding-bottom: 0;
		padding-left: 0;
		margin: 0 $spacing-small 0 $spacing-default * 2.25;
		font-size: $font-size-h5--small;
		width: 100%;

		@include bp(md) {
			padding-left: $spacing-default;
			padding-right: $spacing-default * 2;
			font-size: $font-size-small;
			margin: 0;
			width: auto;
		}
	}
	&__content {
		height: 0;
		opacity: 0;
		overflow: hidden;
		transition: height $animation-duration-default * 3 ease,
			margin $animation-duration-default * 2 ease, opacity 100ms ease;
		&:not(.hidden) {
			display: block;
		}
	}
	&__step {
		display: flex;
		align-items: center;
		@include bp(md) {
			padding-left: $spacing-default;
		}
	}
	&__step-num {
		width: $spacing-large;
		font-size: $font-size-xs;
		line-height: $line-height-default;
		font-weight: bold;
		text-transform: uppercase;
		color: $color-dark-blue;
		margin-left: $spacing-medium/3;
		@include bp(md) {
			margin-left: 0;
			margin-right: 23px;
			color: $color-black;
		}
	}
	&__icon {
		transition: background-color $animation-duration-default ease;
		background-color: $color-white;
		box-shadow: 0 0 0 $spacing-small/2 $body-background;
		order: -1;
		font-size: 19px;
		border-color: $color-white;

		svg {
			fill: $color-black;
		}
		@include bp(md) {
			order: 1;
		}
	}

	&__media {
		+ .rte {
			margin-top: $spacing-default;
		}
	}

	img {
		max-width: 100%;
		height: auto;
		margin-bottom: $spacing-default;
		width: 100%;
		@include bp(md) {
			margin-bottom: $spacing-small * 2.5;
		}
	}
	// style shared item
	.umbraco-image {
		background-color: transparent;
	}

	figure {
		margin: 0;
	}
	&__inner {
		position: relative;
	}

	&__item {
		border-radius: $border-radius-large;

		&:not(:last-child) {
			margin-bottom: $spacing-default;
		}

		&--expanded {
			background-color: $color-white;
			box-shadow: $box-shadow-base;
			.timeline-accordion__trigger {
				color: $color-black;
				.cta-btn-circle {
					background-color: $color-dark-blue;
					svg {
						fill: $color-white;
					}
				}
			}
			.timeline-accordion__step-num {
				color: $color-black;
			}

			.timeline-accordion__content {
				transition: height $animation-duration-default * 3 ease,
					margin $animation-duration-default * 2 ease,
					opacity $animation-duration-default * 2 ease;
			}
		}
	}
	&__timeline-bar {
		position: absolute;
		$width-small: 3px;
		left: 32px;
		width: $width-small;
		background-color: $color-white;
		top: $spacing-default * 2.5;
		bottom: $spacing-default * 2.5;
		z-index: -1;
		@include bp(md) {
			left: 123px;
		}
	}
	&__toggle {
		@extend .timeline-accordion__item;
		border-top: 0;
		.timeline-accordion__toggle-header {
			padding: 0 $spacing-medium/2 0 0;
		}
		.timeline-accordion__toggle--close {
			display: none;
		}
		&.timeline-accordion__toggle--expanded {
			@include accordionExpanded();

			.timeline-accordion__toggle--close {
				display: inline;
			}
			.timeline-accordion__toggle--open {
				display: none;
			}
		}
	}
}
