$block: "direction-list";

.#{$block} {
	background-color: $color-gray-light;
	@include bp(lg) {
		background-color: transparent;
	}

	&__headline {
		margin-bottom: -20px;
		margin-top: 25px;
		@include bp(lg) {
			margin-bottom: -10px;
			margin-top: 60px;
		}
	}

	&__container {
		padding: $spacing-default * 2.5 0 $spacing-default * 1.5;

		@include bp(md) {
			padding: $spacing-default * 3.5 0 $spacing-default * 2.5;
		}
	}

	&__bg {
		background-color: $color-gray-light;
	}

	&__entry-wrap {
		margin-bottom: $spacing-default;
	}

	&__inner {
		@include bp(lg) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
