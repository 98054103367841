$block: "more-links";

@mixin more-links-theme($bg-color, $color) {
	.#{$block} {
		&__background {
			background-color: $bg-color;
			color: $color;
			@include content-space-padding();

			&:before {
				background-color: $bg-color;
			}
		}
	}
}

.#{$block} {
	// BEGIN: match RTE styles
	&__background {
		@include left-background-overflow();
	}

	&__col {
		@include bp(lg) {
			@include col-with-parent-size(8, 10);
		}
	}

	&__content {
		padding-left: $spacing-medium/2;
		padding-right: $spacing-medium/2;
		@include bp(lg) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	&__title {
		font-size: 1.67rem;
		margin-bottom: $spacing-default;
	}
	// END: match RTE styles

	&__list {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-flow: row wrap;
		margin-bottom: -$spacing-default; // negate margin-bottom on last row of .more-links__link

		li {
			@include bp-max(md) {
				width: 100%;
			}
		}
	}

	&__link {
		margin-bottom: $spacing-default;
	}

	&--themeBlueLight {
		@include more-links-theme($color-light-blue, $color-black);
	}
	&--themeBlueDark {
		@include more-links-theme($color-dark-blue, $color-white);
	}
	&--themeGrayLight {
		@include more-links-theme($color-gray-light, $color-black);
	}
	&--themeWhite {
		@include more-links-theme($color-white, $color-black);
	}
	&--themeYellow {
		@include more-links-theme($color-yellow, $color-black);
	}
	&--themeOrange {
		@include more-links-theme($color-orange, $color-black);
	}
	&--themeBlue {
		@include more-links-theme($color-blue, $color-white);
	}
	&--themePink {
		@include more-links-theme($color-pink, $color-black);
	}
	&--themeRed {
		@include more-links-theme($color-red, $color-black);
	}
	&--themeTurquoise {
		@include more-links-theme($color-turquoise, $color-black);
	}
	&--themeTurquoiseLight {
		@include more-links-theme($color-turquoise-light, $color-black);
	}
}
