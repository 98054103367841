$block: "search-input";

.#{$block} {
	position: relative;

	&__btn-wrap {
		position: absolute;
		top: 50%;
		left: $spacing-default;
		transform: translate3d(0, -50%, 0);
	}

	&__btn {
		&:hover {
			color: $color-dark-blue;
		}
	}

	.input {
		&.focus-visible {
			// the react component falsly triggers a :focus-visible state instead of just a :focus state on focus
			outline: none;
		}
		&:focus {
			// same as umbraco forms
			box-shadow: 0 0 0 2px rgba($color-dark-blue, 0.25);
			outline: 3px solid $color-dark-blue;
		}
	}

	&__loader {
		position: absolute;
		top: 50%;
		right: $spacing-default;
		transform: translate3d(0, -50%, 0);
	}

	&-wrapper {
		padding-left: $spacing-small * 1.5;
		padding-right: $spacing-small * 1.5;

		@include bp(lg) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.search-field {
	border: none;
	padding: 0 $spacing-small * 1.5;

	&:focus {
		outline-color: $color-dark-blue;
	}

	&--round {
		border-radius: 1.75em;
	}

	&--lg {
		width: 100%;
		$font-size-xs: 0.86rem;

		@include bp(md) {
			font-size: $font-size-small;
		}
	}

	&--icon-left {
		padding-left: 4.5em;
	}

	&--icon-right {
		padding-right: 4.5em;
	}

	&--box-shadow {
		box-shadow: $box-shadow;
	}
}
