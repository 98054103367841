.navigation,
.sub-navigation {
	display: none;
	@include bp(lg) {
		display: block;

		&__wrapper {
			> ul {
				//all <ul> tags inside 'navigation + sub-navigation'
				display: flex;
				align-items: center;
				margin: 0;
				padding: 0;

				> li > a {
					display: block;
					text-decoration: none;
					font-family: $font-family-default;
					font-weight: $font-weight-bold;
					margin-right: $spacing-default;
					font-size: 16px;
					font-weight: $font-weight-bold;
					margin-right: $spacing-default * 1.75;
					&:hover,
					&.focus-visible {
						opacity: 0.8;
						text-decoration: underline;
					}
					.icon {
						display: none;
					}
				}
			}
		}
		a {
			//all <a> tags inside 'navigation + sub-navigation'
			&.focus-visible {
				outline: 2px dashed currentColor !important;
				outline-offset: -2px !important;
			}
		}
		&__levels {
			display: flex;
		}
	}
	@include bp(xl) {
		&__wrapper {
			> ul {
				//all <ul> tags inside 'navigation + sub-navigation'
				> li > a {
					font-size: 16px;
				}
			}
		}
	}

	&--active-level {
		.sub-navigation__level-item {
			display: none;
			&--active {
				display: block;

				.sub-navigation__level-item {
					display: block;
				}
			}

			.sub-navigation__page-link {
				display: none;
				--animation-time: 200ms;
				position: relative;
				overflow: hidden;

				&::after {
					content: "";
					display: block;
					bottom: 0;
					left: 0;
					width: calc(100% + 5px);
					height: 6px;
					position: absolute;
					background-color: var(--underline-color, currentColor);
					transition: transform ease var(--animation-time, 200ms),
						opacity ease 0ms 200ms;
					transform: translateX(100%);
				}
			}

			&.active-level {
				.sub-navigation {
					&__page-link {
						display: block;
						font-size: 16px;
					}
					&__level-item {
						padding-right: $spacing-default;
						&:hover {
							opacity: 0.8;
						}
						.sub-navigation__page-link {
							display: block;
							&-title {
								padding-bottom: $spacing-default * 1.6;
							}
						}
					}
					&__page-link--active {
						display: flex;
						align-items: center;
					}
					&__levels {
						display: flex;
						position: absolute;
						top: $spacing-default * 2.55;
						left: $spacing-default * 0.75;
						width: 800px; //col-10 width
						overflow: hidden;
						@include bp(xl) {
							width: 1100px; //col-10 width
						}
						.sub-navigation__page-link--active {
							display: flex;
							align-items: center;
							&::after {
								transform: translateX(0);
								transition-delay: 0ms, 0ms;
								opacity: 1;
							}
						}
					}
				}

				> .sub-navigation__page-link--active {
					text-decoration: none;
				}
			}
		}
	}
	&--small-panel {
		.navigation {
			&__wrapper ul {
				flex-direction: column;
				align-items: flex-start;
			}
			&__item {
				padding: $spacing-default 0;
				&-link {
					font-family: $font-family-default;
					font-weight: $font-weight-normal;
					min-height: 30px;
					display: flex;
					align-items: center;
					line-height: 1;

					&:hover {
						text-decoration: none;
					}
					@include bp(lg) {
						font-size: 3.4rem;
						margin-bottom: $spacing-small;
					}
					@include bp(xl) {
						font-size: 3.68rem;
					}
				}
				&:not(.navigation__item--active) {
					.navigation__item-link {
						transition: transform 0.2s $animation-easing-default;
						&:hover {
							transform: translateX(5px);
						}
					}
				}
				&--active {
					.navigation__item-link {
						&:hover {
							.icon {
								transform: translateX(10px);
							}
						}

						font-family: $font-family-default;
						font-weight: $font-weight-bold;
						.icon {
							transition: transform 0.2s $animation-easing-default;
							display: inline !important;
							margin-right: $spacing-default;
							width: auto;
							svg {
								fill: currentColor;
								width: 35px;
								height: 30px;
								transform: translateY(-3px);
							}

							.safari & {
								line-height: 0;
							}
						}
					}
				}
			}
		}
	}
}
