.chart {
	&__box {
		position: relative;
		margin: 0 auto;
		height: auto;
		width: 100%;
		@include bp(lg) {
			padding-right: $spacing-default;
		}
	}
	&__col {
		@include bp(lg) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	&__legend {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		padding-left: $spacing-default;
		padding-right: $spacing-default;

		@include bp(lg) {
			padding-left: 0;
			padding-right: 0;
			display: block;
			padding-top: 10%;
		}
		&-item {
			@include bp(lg) {
				font-size: 1.3rem;
			}
			display: flex;
			align-items: flex-start;
			&:not(:last-child) {
				margin-bottom: $spacing-small * 1.5;
				margin-right: $spacing-small;
				margin-left: $spacing-small;

				@include bp(lg) {
					margin-left: 0;
					margin-right: 0;
				}
			}
		}
		&-item-color {
			width: 20px;
			min-width: 20px;
			height: 20px;
			border-radius: 50%;
			margin-right: $spacing-small;
			border: $border-small solid $color-white;

			@include bp(lg) {
				width: 28px;
				min-width: 28px;
				height: 28px;
				transform: translateY(-0.1em);
			}
		}
		&-item-label {
			font-weight: bold;
			margin: 0;
			line-height: $line-height-base;
		}
	}
}
