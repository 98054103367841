body::before {
	content: "xsmall";
	display: none;
	/* Prevent from displaying. */
}
.sm-show,
.md-show,
.lg-show {
	display: none;
	visibility: hidden;
}
@include bp(sm) {
	body::before {
		content: "small";
	}
	.sm-show {
		display: block;
	}
}
@include bp(smx) {
	body::before {
		content: "smallx";
	}
	.sm-show {
		display: block;
	}
}
@include bp(md) {
	body::before {
		content: "medium";
	}
	.md-show {
		display: block;
	}
}
@include bp(mdx) {
	body::before {
		content: "mediumx";
	}
	.md-show {
		display: block;
	}
}
@include bp(lg) {
	body::before {
		content: "large";
	}
	.lg-show {
		display: block;
	}
}

[class*="grid-"][class*="-equalHeight"] > [class*="col-"] {
	flex-direction: column;
	display: flex;
	> * {
		height: auto !important;
		@include bp(smx) {
			height: 100% !important;
		}
	}
}
