.header {
	&__navigation {
		&--header-bg {
			display: none;
		}

		@keyframes navigation-enter {
			0% {
				transform: translateY(-150vh);
				opacity: 0;
			}
			100% {
				transform: translateY(0);
				opacity: 1;
			}
		}

		&--small-panel {
			z-index: 999;
			width: 100vw;
			height: 100vh;
			visibility: hidden;
			display: none;
			position: relative;
			animation: navigation-enter 0.45s ease-in-out;
			&::before,
			&::after {
				position: absolute;
				content: "";
				top: -250px;
				height: calc(100% + 250px);
				width: 10000px;
				background: inherit;
				z-index: -1;
			}
			&::before {
				left: -5000px;
			}
			&::after {
				right: -5000px;
			}
		}
	}
}
